const Employee_Id = [
  { name: "Contains" },
  { name: "Less Than" },
  { name: "Greater Than" },
  { name: "Less Than Or Exactly" },
  { name: "Greater Than Or Exactly" },
];
const Employee_Name = [
  { name: "Includes" },
  { name: "Order By (A to Z)" },
  { name: "Order By (Z to A)" },
];
const Designation = [{ name: "Contains" }];
const Appointed_Section = [{ name: "Contains" }];
const Joining_Date = [
  { name: "By Date" },
  { name: "By Month And Year" },
  { name: "By Year" },
];
const Seniority_Level = [
  { name: "Senior" },
  { name: "Junior" },
  { name: "Intern" },
];

//patient
const Patient_Id = [
  { name: "Contains" },
  { name: "Less Than" },
  { name: "Greater Than" },
  { name: "Less Than Or Exactly" },
  { name: "Greater Than Or Exactly" },
];
const Patient_Name = [
  { name: "Includes" },
  { name: "Order By (A to Z)" },
  { name: "Order By (Z to A)" },
];
const Admission_Discharge_Date = [
  { name: "By Date" },
  { name: "By Month And Year" },
  { name: "By Year" },
];
const Assigned_Room = [{ name: "Contains" }];
const Allocated_Section = [{ name: "Includes" }];
const Needy_Profile = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
//Req Time off
const Status = [
  { name: "Accepted" },
  { name: "Declined" },
  { name: "Pending" },
];
const Section = [{ name: "" }]; // Directly enter value for RTO Section
const Month = [{ name: "" }]; // Directly enter value for RTO Month
const Year = [{ name: "" }]; // Directly enter value for RTO Year
const Leave_Date = [
  { name: "By Date" },
  { name: "By Month And Year" },
  { name: "By Year" },
];
// const Leaves_To = [{name:""}]
//Leaves

const Leaves_From_To = [{ name: "Between" }];
const Leave_Type = [
  { name: "ACLS" },
  { name: "AL" },
  { name: "BLS" },
  { name: "CL" },
  { name: "DO" },
  { name: "EL" },
  { name: "ML" },
  { name: "OC" },
  { name: "OR" },
  { name: "OT" },
  { name: "PL" },
  { name: "PH" },
  { name: "RO" },
  { name: "SL" },
  { name: "SD" },
  { name: "SS" },
  { name: "TL" },
  { name: "UL" },
];
const Employee_Section = [{ name: "" }]; //Directly enter value for
const Leave_Status = [
  { name: "Accepted" },
  { name: "Declined" },
  { name: "Pending" },
]; //

export const FILTER_SELECT = {
  //patient
  PAT_ID: Patient_Id,
  PAT_NAME: Patient_Name,
  PAT_ADD_DIS_DATE: Admission_Discharge_Date,
  PAT_ALLOCATED_SECTION: Allocated_Section,
  PAT_ASSIGNED_ROOM: Assigned_Room,
  PAT_NEEDY_PROFILE: Needy_Profile,
  //employees
  EMP_ID: Employee_Id,
  EMP_NAME: Employee_Name,
  EMP_DESIGNATION: Designation,
  EMP_JOINING_DATE: Joining_Date,
  EMP_APPOINTED_SECTION: Appointed_Section,
  EMP_SENIORITY_LEVEL: Seniority_Level,
  //Req Time off
  EMP_RTO_LEAVE_STATUS: Status,
  EMP_RTO_SECTION: Section,
  EMP_RTO_MONTH: Month,
  EMP_RTO_YEAR: Year,
  EMP_RTO_LEAVES: Leave_Date,
  // EMP_LEAVES_FROM:Leaves_From,
  //Leaves
  EMP_LEAVE_TYPE: Leave_Type,
  EMP_LE_EMPLOYEE_SECTION: Employee_Section,
  EMP_LE_EMPLOYEE_LEAVE_STATUS: Leave_Status,
  EMP_LEAVES_FROM_TO: Leaves_From_To,
};
