import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Input } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";
import { GenericInfo } from "@heathmont/moon-icons";

import AllocateRules from "./AllocateRules";
import AllocatePreferences from "./AllocatePreferences";
import { getPreferencesAndRules } from "../../../services/nurse-manager-services/nursemanager.service";
import { IPreferencesAndRules } from "../../../types/nurse-manager-interfaces/IPreferencesAndRules.interface";
import { store } from "../../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import { Rules } from "../../../types/branch-admin-interfaces/IRulesForm.interface";
import { Preferences } from "../../../types/branch-admin-interfaces/ISectionPreference.interface";
import ReactDatePicker from "react-datepicker";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../types/IDateDropDown.interface";
import moment, { months } from "moment";
import {
  IMonthYear,
  MonthSelected,
  YearSelected,
} from "../../../types/nurse-manager-interfaces/IMonthYear.interface";

interface Props {
  isOpen: boolean;
  setIsOpen: (check: boolean) => void;
  makeAutoSchedule: (id?: string, monthYear?: IMonthYear) => void;
  scheduleId: string;
  month: MonthSelected;
  setMonth: (month: MonthSelected) => void;
  year: YearSelected;
  setYear: (year: YearSelected) => void;
}

const RegenerateScheduleModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  makeAutoSchedule,
  scheduleId,
  month,
  setMonth,
  year,
  setYear,
}) => {
  const [formSelect, setFormSelect] = useState<string>("preferences");
  const [calendarSelect, setCalendarSelect] = useState<string>("byMonth");
  const [dateSelect, setDateSelect] = useState<Date>();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [prefAndRulesData, setPrefAndRulesData] =
    useState<IPreferencesAndRules>();
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  const handleFormSelect = (event: number) => {
    if (event === 0) {
      setFormSelect("preferences");
    } else if (event === 1) {
      setFormSelect("rules");
    }
  };
  const handleCalendarSelect = (event: number) => {
    if (event === 0) {
      setCalendarSelect("byMonth");
    } else if (event === 1) {
      // setStartDate(new Date());
      // setEndDate(new Date());
      setStartDate(
        moment()
          .month(month?.label - 1)
          .year(+year?.name)
          .toDate()
      );
      setEndDate(
        moment()
          .month(month?.label - 1)
          .year(+year?.name)
          .toDate()
      );
      setCalendarSelect("byDate");
    }
  };
  const fetchPreferencesAndRules = async () => {
    const res = (await getPreferencesAndRules()) as IPreferencesAndRules;
    if (res?.preferences || res?.rules) {
      setPrefAndRulesData({ ...res });
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Couldn't fetch current Preferences and Rules")
      );
    }
  };
  // const date = moment().month(month?.name).year(+year?.name).toDate();
  const handleDateChange = (dates: [Date, Date] | Date) => {
    if (Array.isArray(dates)) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(dates);
    }
  };
  const handleDateSet = () => {
    const month = {
      name: moment(startDate).format("MMMM"),
      monthName: moment(startDate).format("MMMM"),
      label: moment(startDate).month() + 1,
    };
    const year = {
      name: moment(startDate).format("YYYY"),
      year: moment(startDate).format("YYYY"),
      label: "year",
    };

    const dayStart =
      calendarSelect === "byMonth"
        ? moment(startDate).startOf("month").format("DD")
        : moment(startDate).format("DD");

    const dayEnd =
      calendarSelect === "byMonth"
        ? moment(startDate).endOf("month").format("DD")
        : moment(endDate).format("DD");
    setMonth({ ...month });
    setYear({ ...year });
    return {
      monthSelected: month,
      yearSelected: year,
      dayStart: +dayStart,
      dayEnd: +dayEnd,
    };
  };
  useEffect(() => {
    if (!isOpen) return;
    fetchPreferencesAndRules();
    setFormSelect("preferences");
    setCalendarSelect("byMonth");
    setStartDate(
      moment()
        // .day(new Date().getDate())
        .month(month?.label - 1)
        .year(+year?.name)
        .toDate()
    );
    setDateSelect(
      moment()
        .month(month.label - 1)
        .year(+year.year)
        .toDate()
    );
  }, [isOpen]);

  const minDate = moment()
    .month(month.label - 1)
    .year(+year.year)
    .startOf("month")
    .toDate();

  const maxDate = moment()
    .month(month.label - 1)
    .year(+year.year)
    .endOf("month")
    .toDate();

  return (
    <>
      <Modal open={isOpen} onClose={closeModal}>
        <Modal.Backdrop />
        <Modal.Panel className="AutoPrefrences max-w-[900px]">
          <div className="p-4 border-b-2 border-beerus flex justify-between items-center">
            <h3 className="text-moon-18 text-bulma font-semibold">
              Regenerate Schedule
            </h3>
            <Button className="bg-transparent" onClick={closeModal}>
              <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
            </Button>
          </div>
          <div className="p-4">
            <div className="flex justify-between items-center mb-6">
              <Tabs
                onChange={(e) => {
                  handleFormSelect(e);
                }}
              >
                <Tabs.Segment size="sm">
                  <Tabs.Pill>Preferences</Tabs.Pill>
                  <Tabs.Pill>Rules</Tabs.Pill>
                </Tabs.Segment>
              </Tabs>
              {/* <p className="flex items-center text-[12px] text-[#8697A2]">
                <GenericInfo className="me-3 w-6 h-6" />
                Select month and year to create schedule
              </p> */}
              <div className="flex max-w-[401px] gap-3 w-full ">
                {/* <Input type="date" aria-label="Date" placeholder="Calendar" /> */}
                {/* {calendarSelect === "byMonth" ? ( */}
                <div className="monthCalendar">
                  <ReactDatePicker
                    className="border-2 rounded-md mx-1 justify-end"
                    dateFormat={
                      calendarSelect === "byMonth" ? "MMMM yyyy" : "dd/MMM/yyyy"
                    }
                    showIcon
                    showMonthYearPicker={calendarSelect === "byMonth"}
                    selectsRange={calendarSelect === "byDate"}
                    startDate={startDate}
                    endDate={endDate}
                    selected={startDate}
                    onChange={handleDateChange}
                    minDate={moment()
                      .startOf("month")
                      .month(month?.label - 1)
                      .toDate()}
                      maxDate={moment()
                        .endOf("month")
                        .month(month?.label - 1)
                        .toDate()}
                    disabled={calendarSelect === "byMonth"}
                  />
                </div>
                {/* ) : (
                  <ReactDatePicker
                    className="border-2 rounded-md mx-1 justify-end"
                    dateFormat="dd/MM/yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                    showIcon
                    selected={dateSelect}
                    onChange={handleDateChange}
                  />
                )} */}
                <Tabs
                  onChange={(e) => {
                    handleCalendarSelect(e);
                  }}
                >
                  <Tabs.Segment size="sm">
                    <Tabs.Pill>By Month</Tabs.Pill>
                    <Tabs.Pill>By Date</Tabs.Pill>
                  </Tabs.Segment>
                </Tabs>
              </div>
            </div>
            {formSelect === "preferences" ? (
              <AllocatePreferences
                preferences={prefAndRulesData?.preferences as Preferences}
              />
            ) : (
              <AllocateRules rules={prefAndRulesData?.rules as Rules} />
            )}
          </div>
          <div className="p-4 bg-[#f5f5f5] border-t-2 border-beerus flex items-center justify-end gap-3">
            <Button
              onClick={closeModal}
              className="bg-transparent text-black border-[#8697A2] border rounded-lg text-sm font-semibold"
            >
              Cancel
            </Button>
            <Button
              className="bg-[#249CBA] text-white rounded-lg text-sm font-semibold"
              onClick={() => {
                const monthYear = handleDateSet();
                makeAutoSchedule(scheduleId, monthYear);
                closeModal();
              }}
            >
              Create Schedule
            </Button>
          </div>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default RegenerateScheduleModal;
