import { useState } from "react";
import { Search } from "@heathmont/moon-core-tw";
import { IManualSchedulingRes } from "../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import { GenericSearch } from "@heathmont/moon-icons-tw";

interface Listing {
  avatarImageUrl: string;
  chipText: string;
  name: string;
  daysLeft: {
    current: number;
    total: number;
  };
  hoursLeft: {
    current: number;
    total: number;
  };
  preference: string;
}

interface Props {
  employeesData?: IManualSchedulingRes;
}

const SearchBox: React.FC<Props> = ({ employeesData }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  console.log("selected day res: ", employeesData);

  return (
    <div className="w-full">
      <Search
        onChangeSearch={setSearch}
        onChangeOpen={setOpen}
        search={search}
        isOpen={open}
      >
        <Search.Input className="relative">
          <Search.Input.Icon />
          <Search.Input.Input
            className="text-moon-14 text-trunks"
          />
        </Search.Input>
        <div className="emloyeserch">
          <GenericSearch className="w-6 h-6" />
        </div>
        <Search.Transition>
          <Search.Result>{search /* Search Results */}</Search.Result>
        </Search.Transition>
      </Search>
    </div>
  );
};

export default SearchBox;
