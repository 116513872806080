import React, { useState } from "react";
import PageTitle from "../../../Components/branch-admin/ui/PageTitle";
import { MenuItem } from "@heathmont/moon-core-tw";
import HeaderSetting from "../../../Components/branch-admin/ui/HeaderSetting";
import SearchField from "../../../Components/branch-admin/ui/SearchField";
import UserListing from "./widgets/UserListing";
import ModalAddRole from "./widgets/ModalAddRole";

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);

  const handleModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="bg-slate-100 min-h-full">
        {/* <PageTitle title='Settings' content='Add hospitals and its related branches' /> */}
        <div className="flex flex-wrap">
          <div className="w-1/5">
            <div className="h-[calc(100vh-186px)] border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)] px-[30px] py-[30px] ">
              <div className="hospitlitmsbtn">
                <MenuItem cls as="a" href="#">
                  Hospitals
                </MenuItem>
                <MenuItem as="a" href="#">
                  Users
                </MenuItem>
              </div>
            </div>
          </div>
          <div className="w-4/5">
            <HeaderSetting
              title="Manage Users"
              leadText="Manage user roles and their access permissions respectively"
              buttonText="Add User Role"
              handleModal={handleModal}
            />
            <div className="px-8 pb-4">
              <SearchField />
            </div>
            <div className="px-8">
              <UserListing />
            </div>
          </div>
        </div>
      </div>
      <ModalAddRole isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export default Settings;
