import React, { useState } from "react";
import {
  EmployeeCardData,
  IManualSchedulingRes,
} from "../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface Props {
  employeesData: IManualSchedulingRes;
  filteredEmployees: EmployeeCardData[];
  checkedItems: string[];
  inAutoAllocate?: boolean;
}
export const RequiredEmployees: React.FC<Props> = ({
  employeesData,
  filteredEmployees,
  checkedItems,
  inAutoAllocate,
}) => {
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const shift = selectedShift === "sun" ? "day" : "night";
  const initialCounts = { s_nurse: 0, nurse: 0, hca: 0 };
  // const [emp, setEmp] = useState(initialCounts);
  const empCounts = filteredEmployees?.reduce((counts, employee) => {
    if (checkedItems.includes(employee._id)) {
      switch (employee.designation) {
        case "senior nurse":
          counts.s_nurse += 1;
          break;
        case "nurse":
          counts.nurse += 1;
          break;
        case "HCA":
          counts.hca += 1;
          break;
        default:
          break;
      }
    }
    return counts;
  }, initialCounts);

  const handleEmpNeeded = (emp: string) => {
    if (emp === "nurse") {
      if (shift === "day") {
        return employeesData?.nursesNeededDayShift;
      } else return employeesData?.nursesNeededNightShift;
    } else if (emp === "hca") {
      if (shift === "day") return employeesData?.hcaNeededDayShift;
      else return employeesData?.hcaNeededNightShift;
    } else return 0;
  };
  // Now, empCounts contains the counts for each designation
  console.log("emp counts: ", empCounts);
  return (
    <div className={`${inAutoAllocate ? "w-full" : "px-[25px]"} `}>
      <div className="grid grid-cols-4 gap-2 my-3 border  border-gray-300 rounded-[20px] px-2 py-1">
        <div className="flex items-center">
          <p className="text-moon-12 text-bulma">Required</p>
        </div>
        <div className="flex items-center gap-2 ">
          <span className="text-moon-12 text-white bg-pink-500 rounded-full w-[22px] flex justify-center text-center p-[2px]">
            {employeesData?.seniorNursesNeeded + "/" + empCounts?.s_nurse}
          </span>
          <p className="text-moon-12 text-bulma">S.Nurse</p>
        </div>
        <div className="flex items-center gap-2 ">
          <span className="text-moon-12 text-white bg-blue-500 rounded-full w-[22px] flex justify-center text-center p-[2px]">
            {handleEmpNeeded("nurse") + "/" + empCounts?.nurse}
          </span>
          <p className="text-moon-12 text-bulma">Nurse</p>
        </div>
        <div className="flex items-center gap-2 ">
          <span className="text-moon-12 text-white bg-krillin rounded-full w-[22px] flex justify-center text-center p-[2px]">
            {handleEmpNeeded("hca") + "/" + empCounts?.hca}
          </span>
          <p className="text-moon-12 text-bulma">HCA</p>
        </div>
      </div>
    </div>
  );
};
