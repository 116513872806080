import React from "react";
import { Link } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
import moment from "moment";

const daysInWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function WeekDaysTab() {
  const currentDay = moment().day();
  console.log("day: ", currentDay);

  return (
    <>
      <div className=" bg-sky1">
        <ul className="flex flex-row items-center">
          {daysInWeek.map((day, index) => (
            <li
              key={index}
              className={`basis-1/7 py-4 border border-beerus text-center `}
            >
              <Link
                className={`text-sm  font-semibold  text-grey600`}
                index={0}
              >
                {day}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
