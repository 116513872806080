import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RequestState {
  reqStatus: string;
}

const initialState: RequestState = {
  reqStatus: "All",
};

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setReqState: (state, action: PayloadAction<string>) => {
      console.log(
        "action.payload:",
        action.payload,
      );
      state.reqStatus = action.payload;
    },
  },
});

export const { setReqState } = requestSlice.actions;

export default requestSlice.reducer;
