import {
    MenuItem,
    Search, searchFilterItems,
    searchGetItemIndex,
} from '@heathmont/moon-core-tw'
import React, { useState } from 'react'
interface Item {
    children?: React.ReactNode;
    href?: string;
    id: string;
}

interface Items {
    items: Item[];
    heading?: string;
    id: string;
}
const SearchField = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState('');

    const filteredItems = searchFilterItems(
        [
            {
                heading: 'Results',
                id: 'results',
                items: [
                    {
                        id: 'home',
                        children: 'Home',
                    },
                    {
                        id: 'settings',
                        children: 'Settings',
                    },
                    {
                        id: 'projects',
                        children: 'Projects',
                        closeOnSelect: false,
                        onClick: () => {
                            alert('projects');
                        },
                    },
                ],
            },

        ],
        search
    );



    return (
        <Search
            onChangeSearch={setSearch}
            onChangeOpen={setOpen}
            search={search}
            isOpen={open}
        >
            <div className='relative'>
                <Search.Input>
                    <Search.Input.Icon />
                    <Search.Input.Input />
                    <Search.Input.ButtonClear className='absolute right-[15px] left-auto'>Clear</Search.Input.ButtonClear>
                </Search.Input>
            </div>
            <Search.Transition>
                <Search.Result>
                    {filteredItems.length ? (
                        filteredItems.map((list: Items) => (
                            <ul className="space-y-1" key={list.id}>
                                <li>
                                    <Search.ResultHeading>{list.heading}</Search.ResultHeading>
                                    {list.items.map(({ id, children, href, ...rest }: Item) => (
                                        <Search.ResultItem
                                            key={id}
                                            index={searchGetItemIndex(filteredItems, id)}
                                            closeOnSelect={true}
                                            {...rest}
                                        >
                                            {href ? (
                                                <a href={href}>
                                                    <MenuItem>
                                                        <MenuItem.Title>{children}</MenuItem.Title>
                                                        <span className="text-moon-12 text-trunks">
                                                            {href}
                                                        </span>
                                                    </MenuItem>
                                                </a>
                                            ) : (
                                                <MenuItem>
                                                    <MenuItem.Title>{children}</MenuItem.Title>

                                                </MenuItem>
                                            )}
                                        </Search.ResultItem>
                                    ))}
                                </li>
                            </ul>
                        ))
                    ) : (
                        <Search.NoResults />
                    )}
                </Search.Result>
            </Search.Transition>
        </Search>
    )
}

export default SearchField