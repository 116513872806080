import { Avatar } from "@heathmont/moon-core-tw";
import { IconButton } from "@heathmont/moon-core-tw";
import { GenericEdit } from "@heathmont/moon-icons-tw";
import React from "react";

interface EmployeeCardProps {
  avatarImageUrl: string;
  avatarSize: "xs" | "sm" | "md" | "lg";
  scheduledBy: string;
  day: string;
  date: number;
  month: string;
  year: number;
}

const DateCard = ({
  avatarImageUrl,
  avatarSize,
  scheduledBy,
  day,
  date,
  month,
  year,
}: EmployeeCardProps) => {
  return (
    <div className="flex items-center">
      <div className="text-center px-[14px] py-[7px] bg-slate-100 rounded-lg">
        <h3 className="text-moon-18 font-semibold">{day}</h3>
        <div className="text-moon-32  font-semibold leading-none">{date}</div>
      </div>
      <div className="flex-1 px-[22px]">
        <h3 className="text-moon-18 font-semibold mb-[8px]">
          {month} {year}
        </h3>
        <div className="flex">
          <span className="text-gray-400">Scheduled by </span>
          <div className="flex flex-1 ml-[7px]">
            <div className="flex flex-wrap items-center justify-around gap-2 w-full">
              <Avatar
                imageUrl={avatarImageUrl}
                size={avatarSize}
                className="rounded-full overflow-hidden"
              />
              <span className="flex-1 font-bold">{scheduledBy}</span>
            </div>
          </div>
        </div>
      </div>
      {/* <IconButton
        className="border-goku border shadow-none hover:shadow-none"
        variant="secondary"
        icon={<GenericEdit />}
      /> */}
    </div>
  );
};

export default DateCard;
