import { Button, Input, Label } from "@heathmont/moon-core-tw";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { authLogin } from "../../services/auth.service";
interface FormValues {
  // Define your form fields here
  email: string;
  password: string;
}
const emailRegex = /^(?!.*\s)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
// /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /.{8,32}$/;
const loginErrorMessage = "Incorrect email or password";
function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setLoginError(false);

    // if (formSubmitted && !emailValue?.match(emailRegex)) {
    //   setEmailError("Please enter a valid email address.");
    // } else {
    //   setEmailError("");
    // }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    setLoginError(false);

    // if (formSubmitted && !passwordValue?.match(passwordRegex)) {
    //   setPasswordError("Password should be at least 8 characters long.");
    // } else {
    //   setPasswordError("");
    // }
  };
  const onSubmit = async (data: FormValues) => {
    const res = await authLogin(data);

    if (res?.accessToken) {
      navigate("/");
      setEmail("");
      setPassword("");
      setEmailError("");
      setPasswordError("");
    } else {
      setLoginError(true);
    }
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (emailError || passwordError) {
      return;
    }

    // Your API call to perform login goes here
    // You can use the 'email' and 'password' states to send the data to your backend
    const loginCredentials: FormValues = { email: email, password: password };
    onSubmit(loginCredentials);
    // Clear the form after submission
  };

  const handleBlur = (name: string, value: string) => {
    const regex = name === "email" ? emailRegex : passwordRegex;

    if (!value.match(regex)) {
      if (name === "email") {
        setEmailError("Please enter a valid email address.");
      } else if (name === "password") {
        setPasswordError(`Password should be at least 8 characters long.`);
      }
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center">
      <div className="flex text-center flex-1  flex-col justify-center ">
        <div className="mx-auto max-w-1/2 pt-12 w-full">
          <img
            className="mx-auto mb-20"
            src={"images/login-weblogo.png"}
            alt="logo"
          />
          <h3 className="text-3xll mb-5">Sign in to your account</h3>
          <p className="text-base text-[#8697A2] mb-10">
            Welcome to Healthcare Management Portal. Enter your credentials to
            Sign In.
          </p>
          <form
            className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl"
            onSubmit={(event) => {
              handleSubmit(event);
            }}
          >
            <div className="mb-3">
              <Label htmlFor="email" className="text-start">
                Email Address
              </Label>
              <Input
                type="text" //can be set as email too
                value={email}
                placeholder="Email Address"
                onChange={(event) => {
                  handleEmailChange(event);
                }}
                autoFocus
                onBlur={() => handleBlur("email", email)}
                onFocus={() => {
                  setEmailError("");
                  setLoginError(false);
                }}
              />
              {emailError && (
                <p className="text-dodoria text-left text-xs">{emailError}</p>
              )}
            </div>
            <div className="mb-4">
              <Label htmlFor="password" className="text-start">
                Password
              </Label>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  handlePasswordChange(event);
                }}
                onBlur={() => handleBlur("password", password)}
                onFocus={() => {
                  setPasswordError("");
                  setLoginError(false);
                }}
              />
              {passwordError && (
                <p className="text-dodoria text-left text-xs">
                  {passwordError}
                </p>
              )}
              {loginError && (
                <p className="text-dodoria">{loginErrorMessage}</p>
              )}
            </div>

            <Button
              className="w-full h-10 mb-5 shadow-btnshadow "
              type="submit"
              variant="tertiary"
            >
              Login
            </Button>
            <Link
              to="/forgot-password"
              className="text-base text-[#8697A2] cursor-pointer"
            >
              Forgot your password?
            </Link>
          </form>
        </div>
      </div>
      <hr />
      <div className="footer px-24 py-6">
        <div className="flex justify-between items-center">
          <p className="text-base text-[#8697A2]">
            Copyright ©
            <span className="text-[#249CBA] mx-1">
              Healthcare Management Portal |
            </span>
            All Rights Reserved
          </p>

          <img src={"images/healthlogo.svg"} alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default LoginForm;

// {
//   /* <>
//       <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center">
//         <div className="flex text-center flex-1  flex-col justify-center ">
//           <div className="mx-auto max-w-1/2 pt-12">
//             <img
//               className="mx-auto mb-20"
//               src={"images/login-weblogo.png"}
//               alt="logo"
//             />
//             <h3 className="text-3xll mb-5">Sign in to your account</h3>
//             <p className="text-base mb-10">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//               eiusmod tempor incididunt ut labore et dolore.
//             </p>
//             <Form
//               className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl"
//               onKeyDown={(event) => {
//                 if (event.key === "Enter") {
//                   event.preventDefault();
//                   handleFormSubmit();
//                   // handleSubmit(handleFormSubmit)();
//                 }
//               }}
//               // onSubmit={handleSubmit(handleFormSubmit)}
//             >
//               <Form.Item className="mb-3">
//                 <Label htmlFor="email" className="text-start">
//                   Email Address
//                 </Label>
//                 <Input
//                   type="text"
//                   placeholder="Email"
//                   {...register("email", {
//                     pattern: {
//                       value:
//                         /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
//                       message: "Please enter a valid email address",
//                     },
//                     required: true,
//                   })}
//                   autoFocus={true}
//                   // onKeyDown={(event) => {
//                   //   if (event.key === "Enter") {
//                   //     event.preventDefault();
//                   //     handleFormSubmit();
//                   //   }
//                   // }}
//                   onChange={(e) => {
//                     clearErrors("email");
//                     setResponseError(false);
//                     const value = e.target.value;
//                     setCredentials({ ...credentials, email: value });
//                   }}
//                 />
//                 {errors.email && (
//                   <ErrorMessage
//                     errors={errors}
//                     name="email"
//                     render={({ message }: any) => {
//                       return message ? (
//                         <p style={{ color: "red" }}>{message}</p>
//                       ) : (
//                         <p>{}</p>
//                       );
//                     }}
//                   />
//                 )}
//               </Form.Item>
//               <Form.Item className="mb-4">
//                 <Label htmlFor="email" className="text-start">
//                   Password
//                 </Label>
//                 <Input
//                   type="password"
//                   placeholder="Password"
//                   {...register("password", {
//                     pattern: {
//                       value: /.{8,32}$/,
//                       message: "Password should be at least 8 characters long",
//                     },
//                     required: true,
//                   })}
//                   // onKeyDown={(event) => {
//                   //   if (event.key === "Enter") {
//                   //     event.preventDefault();
//                   //     handleFormSubmit();
//                   //   }
//                   // }}
//                   onChange={(e) => {
//                     clearErrors("password");
//                     setResponseError(false);
//                     const value = e.target.value;
//                     setCredentials({ ...credentials, password: value });
//                   }}
//                 />
//                 {errors.password && (
//                   <ErrorMessage
//                     errors={errors}
//                     name="password"
//                     render={({ message }: any) => {
//                       return message ? (
//                         <p style={{ color: "red" }}>{message}</p>
//                       ) : null;
//                     }}
//                   />
//                 )}

//                 {responseError ? (
//                   <p style={{ color: "red" }}>Invalid Email or Password</p>
//                 ) : null}
//               </Form.Item>
//               <Button
//                 className="w-full h-10 mb-5 shadow-btnshadow "
//                 type="submit"
//                 form="login"
//                 value="Submit"
//                 variant="tertiary"
//                 onClick={handleSubmit(onSubmit)}
//               >
//                 Log in
//               </Button>
//               <Link
//                 to="/forgot-password"
//                 className="text-base text-[#8697A2] cursor-pointer"
//               >
//                 Forgot your password?
//               </Link>
//             </Form>
//           </div>
//         </div>
//         <div className="footer px-24 py-6">
//           <div className="flex justify-between items-center">
//             <p className="text-base text-[#8697A2]">
//               Copyright ©
//               <span className="text-[#249CBA]">
//                 Healthcare Management Portal |
//               </span>
//               All Rights Reserved
//             </p>
//             <img src={"images/healthlogo.svg"} alt="logo" />
//           </div>
//         </div>
//       </div>
//     </> */
// }
