import React from "react";
import { Avatar, Chip, IconButton } from "@heathmont/moon-core-tw";
import { DevicesPhone, MailEmailStats } from "@heathmont/moon-icons-tw";
import { IEmployee } from "../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { getBorderColor } from "../../../Colors/colorSetting";

interface Props {
  employee: IEmployee;
}

const EmployeContact: React.FC<Props> = ({ employee }) => {
  return (
    <div className="flex py-5 px-5 items-center">
      <Avatar
        className={`rounded-full border-2   ${getBorderColor(
          employee?.designation
        )} overflow-hidden`}
        imageUrl="./images/jennifer-aniston-img.png"
        size="xl"
      />
      <div className="flex-1 px-[16px]">
        <h4 className="text-moon-20">{employee?.employeeName}</h4>
        <Chip
          size="sm"
          className="bg-cell-60 text-moon-9 uppercase text-primary px-2 rounded-[4px]"
        >
          {employee?.designation}
        </Chip>
      </div>
      <ul className="flex gap-2">
        <li>
          <IconButton
            className="border-goku border shadow-none hover:shadow-none"
            variant="secondary"
            icon={<DevicesPhone />}
          />
        </li>
        <li>
          <IconButton
            className="border-goku border shadow-none hover:shadow-none"
            variant="secondary"
            icon={<MailEmailStats />}
          />
        </li>
      </ul>
    </div>
  );
};

export default EmployeContact;
