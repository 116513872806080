import React from "react";
import SimpleBar from "simplebar-react";

export default function MonthCalenderEmpty() {
  return (
    <>
      <SimpleBar
        className=" overflow-y-auto"
        style={{ height: "calc(100vh - 440px)" }}
      >
        <div className="flex flex-wrap">
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">26 Aug</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">27</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">28</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">29</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">30</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">31</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">1 sep</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">2</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">3</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">4</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">5</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">6</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">7</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">8</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">9</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">10</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">11</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">12</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">13</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">14</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">15</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">16</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">17</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">18</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">19</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">20</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">21</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">22</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">23</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">24</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">25</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">26</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">27</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">28</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">29</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">30</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-600 font-bold font-custom">31</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">1 Oct</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">2</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">3</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">4</h3>
              </div>
            </div>
          </div>
          <div className="basis-1/7 p-[12px] border border-beerus h-[100px] bg-white">
            <div className="flex flex-col justify-between h-full">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-300 font-bold font-custom">5</h3>
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </>
  );
}
