import React from "react";
import TrackingTabsAccordion from "./TrackingTabsAccordion";

interface Props {
  title: string;
  setSelectedFilter: (designation: string) => void;
}
const TrackingHeaderAccordion: React.FC<Props> = ({
  title,
  setSelectedFilter,
}) => {
  return (
    <div className="bg-goku p-[8px] rounded-[8px] flex items-center">
      <h3 className="text-moon-14 font-semibold">{title}</h3>

      <div className="ml-auto">
        <TrackingTabsAccordion setSelectedFilter={setSelectedFilter} />
      </div>
    </div>
  );
};

export default TrackingHeaderAccordion;
