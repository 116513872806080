import React from "react";
import { Icon } from "@iconify/react";
import { Chip } from "@heathmont/moon-core-tw";

interface ShiftDataItem {
  shift: string;
  status: string;
  capacity: number;
  patients: number;
  utilization: string;
  criticalPatients: number;
}

interface DetailShiftProps {
  shiftData: ShiftDataItem;
}

const DetailShift: React.FC<ShiftDataItem> = ({
  shift,
  status,
  capacity,
  patients,
  utilization,
  criticalPatients,
}) => {
  const getStatusChipClasses = () => {
    if (status === "Balanced") {
      return "bg-green-100 text-green-600";
    } else if (status === "Not Balanced") {
      return "bg-red-100 text-red-600";
    } else if (status === "Over Utilization") {
      return "bg-krillin-10 text-krillin";
    } else {
      return "";
    }
  };
  console.log("balanced: ", status);
  return (
    <div className="flex flex-wrap -mx-3">
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Shift:</div>
          <div className="flex items-center text-moon-16">
            <i
              className={`text-moon-24 ${
                shift === "Day Shift"
                  ? "text-krillin"
                  : shift === "Night Shift"
                  ? "text-gray-500"
                  : ""
              }`}
            >
              <Icon
                icon={
                  shift === "Day Shift"
                    ? "uil:sun"
                    : shift === "Night Shift"
                    ? "uil:moon"
                    : ""
                }
              />
            </i>
            <span className="ml-[5px]">{shift}</span>
          </div>
        </div>
      </div>
      <div className="px-3 w-1/2  mb-[4px] ">
        <div className="flex items-center justify-items-center min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Status:</div>
          <div className="flex items-center text-moon-16">
            <Chip
              size="sm"
              className={`z-0 overflow-hidden flex flex-row items-center text-moon-12 relative rounded-moon-i-sm cursor-pointer transition duration-200 space-between py-2 px-3 ${getStatusChipClasses()}`}
              iconLeft={<Icon className="mr-1" icon="octicon:dot-fill-16" />}
            >
              {status}
            </Chip>
          </div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Capacity:</div>
          <div className="flex items-center text-moon-16">{capacity}</div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">
            Number of Patients:
          </div>
          <div className="flex items-center text-moon-16">{patients}</div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">Utilization:</div>
          <div className="flex items-center text-moon-16">{utilization}</div>
        </div>
      </div>
      <div className="px-3 w-1/2 mb-[4px]">
        <div className="flex items-center justify-items-center   min-h-[32px]">
          <div className="flex-1 text-moon-16 text-gray-400">
            Critical Patients:
          </div>
          <div className="flex items-center text-moon-16">
            {criticalPatients}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailShift;
