import React, { useEffect, useState } from "react";
import DropDown from "../../../UI/DropDown";
import FilterShifts from "../../../UI/FilterShifts";
import { Button, Snackbar } from "@heathmont/moon-core-tw";
import AutoAllocateDialog from "../../../Dialogs/AutoAllocateDialog";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../../store/store";
import {
  createAutoSchedule,
  submitSchedule,
} from "../../../../../services/nurse-manager-services/nursemanager.service";
import {
  deleteDraft,
  getDraft,
  getDraftsOfSchedules,
} from "../../../../../services/nurse-manager-services/draft.service";
import { IScheduleData } from "../../../../../types/nurse-manager-interfaces/IAutoScheduleRes.interface";
import { GenericLoyalty, SoftwarePlate } from "@heathmont/moon-icons-tw";
import ViewDraftsModal from "../../../Widgets/modal/ViewDraftsModal";
import { Drafts } from "@mui/icons-material";
import { IDraft } from "../../../../../types/nurse-manager-interfaces/IDraft.interface";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../../features/ToastSlice/toastSlice";
import ConfirmationModal from "../../../../confirmation-modal/confirmationModal";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/IDateDropDown.interface";
import { months } from "../../../../../utils/months";
import { years } from "../../../../../utils/years";
import { setSelectedShift } from "../../../../../features/nurse-manager/Time Slice/timeSlice";
import PreferencesRulesModal from "../../../autoPreferences/PreferencesRulesModal";
import {
  ISchedule,
  ScheduleDetails,
} from "../../../../../types/nurse-manager-interfaces/IMonthCalendar.interface";
import ReactDatePicker from "react-datepicker";
import { YearSelected } from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";
import {
  IMonthYear,
  MonthSelected,
} from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";
import RegenerateScheduleModal from "../../../autoPreferences/RegenerateScheduleModal";
import { useNavigate } from "react-router-dom";
interface Month {
  name: string;
}

export interface DraftData {
  _id: string;
  status: string;
  scheduleVersion: number;
  month: number;
  year: number;
  createdAt: string;
}

export interface Drafts {
  drafts: DraftData[];
}
// const month: Month[] = [{ name: "September 2023" }];
// const data: Data[] = [{ name: "Orthopedics" }];
// const currentYear: number = Number(moment().format("YYYY"));

// const months: any = Array.from({ length: 12 }, (_, index) => ({
//   name: `${moment().month(index).format("MMMM")}`,
//   monthName: moment().month(index).format("MMMM"),
//   label: index + 1,
// }));

// const years: any = Array.from({ length: 15 }, (_, index) => ({
//   name: currentYear - index,
//   year: currentYear - index,
//   label: "year",
// }));
interface Props {
  scheduleSubmitClick: boolean;
  setScheduleSubmitClick: (click: boolean) => void;
  setDraftData: (data: ISchedule) => void;
  isEditingDraft: boolean;
  setIsEditingDraft: (check: boolean) => void;
  loadEditDraft: boolean;
  setLoadEditDraft: (check: boolean) => void;
  submitDraftSchedule: () => void;
  setModalToExpand: (modal: string[]) => void;
  month: MonthSelected;
  year: YearSelected;
  setMonth: (month: MonthSelected) => void;
  setYear: (year: YearSelected) => void;
  fetchEmployeesForManualScheduling: (scheduleId: string) => void;
  scheduleData: ISchedule;
  // setScheduleData: (scheduleData: ISchedule) => void;
  getSchedules: (scheduleId: string) => void;
  shouldGetSchedule: boolean;
  setShouldGetSchedule: (check: boolean) => void;
}
const TopbarMonth: React.FC<Props> = ({
  scheduleSubmitClick,
  setScheduleSubmitClick,
  setDraftData,
  isEditingDraft,
  setIsEditingDraft,
  loadEditDraft,
  setLoadEditDraft,
  submitDraftSchedule,
  setModalToExpand,
  month,
  year,
  setMonth,
  setYear,
  fetchEmployeesForManualScheduling,
  scheduleData,
  // setScheduleData,
  getSchedules,
  shouldGetSchedule,
  setShouldGetSchedule,
}) => {
  const scheduleDate = moment()
    .month(+month?.label - 1)
    .year(+year?.year)
    .toDate();
  const scheduleInitialDate = moment(scheduleDate).startOf("month").toDate();
  const [selectedDate, setSelectedDate] = useState<Date>(scheduleInitialDate);

  const [createAutoScheduleRes, setCreateAutoScheduleRes] =
    useState<IScheduleData>();
  const [draftsOfSchedules, setDraftsOfSchedules] = useState<Drafts>();
  const [isViewDraftsOpen, setIsViewDraftsOpen] = useState<boolean>(false);
  // const [isEditDraft, setIsEditDraft] = useState<boolean>(false);
  const [draftInfo, setDraftInfo] = useState<DraftData>();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
  const [isRegenerateOpen, setIsRegenerateOpen] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  const navigate = useNavigate()
  // const [snackbar, setSnackbar] = useState("");
  // const [toastMessage, setToastMessage] = useState("");
  // const [autoScheduleId, setAutoScheduleId] = useState<string>("");
  // const { date } = useSelector((state: RootState) => state.time);
  const currentYear: number = Number(moment().format("YYYY"));
  const [dateSelect, setDateSelect] = useState<Date>(
    moment().month(month?.name).year(+year?.name).toDate()
  );
  console.log("date select check: ", dateSelect);
  const makeAutoSchedule = async (
    scheduleId?: string,
    monthYear?: IMonthYear
  ) => {
    const monthSelected = monthYear?.monthSelected?.label ?? month?.label;
    const yearSelected = monthYear?.yearSelected?.year ?? year?.year;
    const day = monthYear?.dayStart ?? 1;
    const dayEnd =
      monthYear?.dayEnd ??
      moment()
        .month(monthSelected - 1)
        .daysInMonth();

    // console.log("month check: ", monthSelected);
    // console.log("year check: ", yearSelected);
    // console.log("month year both check: ", monthYear);
    // console.log("scheduleID check: ", scheduleId);
    const res: any = await createAutoSchedule(
      day as number,
      dayEnd as number,
      monthSelected as number,
      +yearSelected,
      scheduleId
    );
    if (res?.res) {
      const scheduleDate = moment()
        .month(+monthSelected - 1)
        .year(+yearSelected)
        .toDate();        
      setDateSelect(scheduleDate);
      console.log("res of auto schedule: ", res.res);
      setCreateAutoScheduleRes(res?.res as IScheduleData);
      getSchedules(res?.res?._id as string);
      // navigate('/', { state: { 
      //   _id: res?.res?._id,
      //   createdAt: res?.res?.createdAt,
      //   month: res?.res?.month,
      //   scheduleVersion: res?.res?.scheduleVersion,
      //   scheduledBy: res?.res?.scheduledBy,
      //   status: res?.res?.status,
      //   updatedAt: res?.res?.updatedAt,
      //   year: res?.res.year
      // } });
      // openModal();
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Preferences of this section are not found")
      );
    }
    console.log("error: ", res?.err);
  };
  const fetchDraftsOfSchedules = async () => {
    const res = await getDraftsOfSchedules();
    if (res?.res) {
      // console.log("res of getDraftOfSchedule: ", res);
      setDraftsOfSchedules(res?.res as Drafts);
    }
  };
  const fetchDraftToEdit = async () => {
    const res: any = await getDraft(draftInfo?._id as string);
    if (res?.res) {
      const date = moment()
        .month((res?.res?.month as number) - 1)
        .year(+res?.res?.year)
        .toDate();
      handleDateChange(date);
      console.log("res of getDraft: ", res);
      // setDraftsOfSchedules(res?.res as Drafts);
      fetchEmployeesForManualScheduling(draftInfo?._id as string);
      const temp = { schedule: res?.res };
      setDraftData(temp as ISchedule);
    }
  };

  const removeDraft = async (draftId: string) => {
    const res = await deleteDraft(draftId);
    if (res?.res) {
      console.log("res of delete draft: ", res);
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Draft deleted successfully"));
      fetchDraftsOfSchedules();
      // setDraftsOfSchedules(res?.res as Drafts);
      // const temp = { schedule: res?.res };
      // setDraftData(temp as IDraft);
    }
  };

  const submitForSchedule = async (draftId?: string) => {
    const res = await submitSchedule(scheduleData?.schedule?._id, draftId);
    if (res?.res) {
      setScheduleSubmitClick(!scheduleSubmitClick); //may be not needed here
      console.log("Submit schedule res: ", res?.res);
      // setSnackbar("success");
      // setToastMessage("Schedule submitted successfully");
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Schedule submitted successfully"));
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Schedule already exists for the selected month")
      );
      // setSnackbar("error");
      // setToastMessage(res?.err as string);
    }
  };
  // useEffect(() => {
  //   console.log("auto response in state: ", createAutoScheduleRes);
  //   setAutoScheduleId(createAutoScheduleRes?._id as string)
  // }, [createAutoScheduleRes]);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  const closePreferencesModal = () => setIsPreferencesOpen(false);
  const openPreferencesModal = () => setIsPreferencesOpen(true);

  const handleDateChange = (date: Date, scheduleChange?: boolean) => {
    const month = {
      name: moment(date).format("MMMM"),
      monthName: moment(date).format("MMMM"),
      label: moment(date).month() + 1,
    };
    const year = {
      name: moment(date).format("YYYY"),
      year: moment(date).format("YYYY"),
      label: "year",
    };
    setMonth({ ...month });
    setYear({ ...year });
    setDateSelect(date);
    setCreateAutoScheduleRes(undefined);
    if (scheduleChange) setShouldGetSchedule(!shouldGetSchedule);
  };
  useEffect(() => {
    if (!draftInfo) return;
    setIsEditingDraft(true);
    fetchDraftToEdit();
  }, [loadEditDraft]);
  // useEffect(() => {
  //   store?.dispatch(setSelectedShift("sun"));
  // }, []);
  useEffect(() => {
    setSelectedDate(scheduleInitialDate);
  }, [month, year]);
  return (
    <>
      {/* <ConfirmationModal
        isOpenConfirmation={isOpenConfirmation}
        setIsOpenConfirmation={setIsOpenConfirmation}
        type="regenerate schedule"
        actionFunction={makeAutoSchedule}
        id={scheduleData?.schedule?._id}
      /> */}
      <div className="bg-white shadow-md py-4 px-5">
        <div className="flex items-center flex-wrap -mx-2">
          <div className="w-3/5 px-2">
            <div className="flex flex-wrap gap-3 monthCalendar">
              {!isEditingDraft && (
                <>
                  <ReactDatePicker
                    className="border-2 rounded-md mx-1 justify-end items-center"
                    dateFormat="MMMM yyyy"
                    showIcon
                    // icon={
                    //   <svg
                    //     height="24"
                    //     version="1.1"
                    //     width="24"
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     // xmlns:cc="http://creativecommons.org/ns#"
                    //     // xmlns:dc="http://purl.org/dc/elements/1.1/"
                    //     // xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    //   >
                    //     <g transform="translate(0 -1028.4)">
                    //       <path
                    //         d="m5 1032.4c-1.1046 0-2 0.9-2 2v14c0 1.1 0.8954 2 2 2h6 2 6c1.105 0 2-0.9 2-2v-14c0-1.1-0.895-2-2-2h-6-2-6z"
                    //         fill="#bdc3c7"
                    //       />
                    //       <path
                    //         d="m5 3c-1.1046 0-2 0.8954-2 2v14c0 1.105 0.8954 2 2 2h6 2 6c1.105 0 2-0.895 2-2v-14c0-1.1046-0.895-2-2-2h-6-2-6z"
                    //         fill="#ecf0f1"
                    //         transform="translate(0 1028.4)"
                    //       />
                    //       <path
                    //         d="m5 3c-1.1046 0-2 0.8954-2 2v3 1h18v-1-3c0-1.1046-0.895-2-2-2h-6-2-6z"
                    //         fill="#e74c3c"
                    //         transform="translate(0 1028.4)"
                    //       />
                    //       <path
                    //         d="m7 5.5a1.5 1.5 0 1 1 -3 0 1.5 1.5 0 1 1 3 0z"
                    //         fill="#c0392b"
                    //         transform="translate(.5 1028.4)"
                    //       />
                    //       <path
                    //         d="m6 1c-0.5523 0-1 0.4477-1 1v3c0 0.5523 0.4477 1 1 1s1-0.4477 1-1v-3c0-0.5523-0.4477-1-1-1z"
                    //         fill="#bdc3c7"
                    //         transform="translate(0 1028.4)"
                    //       />
                    //       <path
                    //         d="m7 5.5a1.5 1.5 0 1 1 -3 0 1.5 1.5 0 1 1 3 0z"
                    //         fill="#c0392b"
                    //         transform="translate(12.5 1028.4)"
                    //       />
                    //       <g fill="#bdc3c7">
                    //         <path d="m18 1029.4c-0.552 0-1 0.4-1 1v3c0 0.5 0.448 1 1 1s1-0.5 1-1v-3c0-0.6-0.448-1-1-1z" />
                    //         <path d="m5 1039.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
                    //         <path d="m5 1042.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
                    //         <path d="m5 1045.4v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2zm3 0v2h2v-2h-2z" />
                    //       </g>
                    //     </g>
                    //   </svg>
                    // }
                    showMonthYearPicker
                    selected={dateSelect}
                    onChange={(date) => handleDateChange(date as Date, true)}
                  />
                  {/* {" "}
                  <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"monthName"}
                      value={month}
                      options={months}
                      setOptions={setMonth}
                    />
                  </div>
                  <div className="max-w-[173px] w-full">
                    <DropDown
                      field={"name"}
                      value={year}
                      options={years}
                      setOptions={setYear}
                    />
                  </div> */}
                </>
              )}
              {/* <div className="flex-[0_0_170px]">
                <DropDown option='Orthopedics' data={data} />
              </div> */}
              <div>
                <FilterShifts />
              </div>
              <div className="flex justify-end items-center gap-2">
                {isEditingDraft && (
                  <Button
                    className="min-h-[42px]"
                    variant="ghost"
                    onClick={() => {
                      handleDateChange(moment()?.toDate());
                      setScheduleSubmitClick(!scheduleSubmitClick);
                    }}
                  >
                    Back to Schedule
                  </Button>
                )}

                <div className="flex gap-2">
                  <Button
                    className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                    onClick={() => {
                      setIsRegenerateOpen(true);
                      // setIsOpenConfirmation(true);
                      // makeAutoSchedule(scheduleData?.schedule?._id);
                    }}
                  >
                    Regenerate Schedule
                  </Button>
                  {(isEditingDraft ||
                    scheduleData?.schedule?.status === "draft") && (
                    <Button
                      className="min-h-[42px] bg-[rgb(36,156,186,0.15)] text-hit"
                      onClick={() => {
                        submitForSchedule(
                          isEditingDraft
                            ? (draftInfo?._id as string)
                            : undefined
                        );
                      }}
                    >
                      Submit Draft Schedule
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ViewDraftsModal
            isOpen={isViewDraftsOpen}
            setIsOpen={setIsViewDraftsOpen}
            draftsOfSchedules={draftsOfSchedules as Drafts}
            removeDraft={removeDraft}
            draftId={draftInfo?._id as string}
            setDraftInfo={setDraftInfo}
            loadEditDraft={loadEditDraft}
            setLoadEditDraft={setLoadEditDraft}
            submitForSchedule={submitForSchedule}
            setModalToExpand={setModalToExpand}
          />
          <RegenerateScheduleModal
            isOpen={isRegenerateOpen}
            setIsOpen={setIsRegenerateOpen}
            makeAutoSchedule={makeAutoSchedule}
            scheduleId={
              isEditingDraft
                ? (draftInfo?._id as string)
                : scheduleData?.schedule?._id
            }
            month={
              isEditingDraft
                ? {
                    label: draftInfo?.month as number,
                    monthName: moment()
                      .month((draftInfo?.month as number) - 1)
                      .format("MMMM"),
                    name: moment()
                      .month((draftInfo?.month as number) - 1)
                      .format("MMMM"),
                  }
                : month
            }
            // month={month}
            setMonth={setMonth}
            year={
              isEditingDraft
                ? {
                    label: "year",
                    year: (draftInfo?.year as number)?.toString(),
                    name: (draftInfo?.year as number)?.toString(),
                  }
                : year
            }
            // year={year}
            setYear={setYear}
          />
          {!isEditingDraft && (
            <div className="flex w-2/5 justify-end">
              <div className="flex flex-arap items-center gap-3 ">
                {(createAutoScheduleRes || scheduleData?.schedule._id) && (
                  <div className="text-moon-14 gap-2">
                    <span className=" min-h-[42px]  text-[#8697A2]">
                      Status:{" "}
                    </span>
                    <span>{scheduleData?.schedule?.status?.toUpperCase()}</span>
                  </div>
                )}
                <Button
                  variant="ghost"
                  className="min-h-[42px]"
                  onClick={() => {
                    setIsViewDraftsOpen(true);
                    fetchDraftsOfSchedules();
                  }}
                >
                  View Drafts
                </Button>
                <Button
                  variant="tertiary"
                  className="text-hit bg-[rgb(36,156,186,0.15)] min-h-[42px]"
                  onClick={openPreferencesModal}
                >
                  Auto Allocate
                </Button>
                <PreferencesRulesModal
                  isOpen={isPreferencesOpen}
                  setIsOpen={setIsPreferencesOpen}
                  makeAutoSchedule={makeAutoSchedule}
                  month={month}
                  setMonth={setMonth}
                  year={year}
                  setYear={setYear}
                />
                {/* <Button
                  variant="tertiary"
                  className="text-hit bg-[rgb(36,156,186,0.15)] min-h-[42px]"
                  iconLeft={<SoftwarePlate />}
                  onClick={() => {
                    setSelectedDate(scheduleInitialDate);
                    store?.dispatch(setSelectedShift("sun"));
                    // openModal();
                    makeAutoSchedule();
                    setCreateAutoScheduleRes(undefined);
                  }}
                >
                  Auto Allocate
                </Button> */}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <AutoAllocateDialog
        isOpen={isOpen}
        onClose={closeModal}
        createAutoScheduleRes={createAutoScheduleRes as IScheduleData}
        submitForSchedule={submitForSchedule}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        scheduleInitialDate={scheduleInitialDate}
        // scheduleSubmitClick={scheduleSubmitClick as boolean}
        // setScheduleSubmitClick={
        //   setScheduleSubmitClick as (click: boolean) => void
        // }
        makeAutoSchedule={makeAutoSchedule}
      /> */}
    </>
  );
};

export default TopbarMonth;
