import React from "react";
import ReactDOM from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RootState, store } from "./store/store";
import { Provider, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
// import Toast from "./Components/Toast/Toast";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// const { snackbar, toastMessage } = useSelector(
//   (state: RootState) => state.toast
// );
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <Toaster
      toastOptions={{
        style: {
          maxWidth: "800px",
        },
      }}
    /> */}
    {/* <Toast
      snackbar={snackbar}
      // setSnackbar={setSnackbar}
      toastMessage={toastMessage}
      // setToastMessage={setToastMessage}
    /> */}
    <App />
  </Provider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
