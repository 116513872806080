import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Notification {
  updateData: boolean;
  actedScheduleId: string;
}

const initialState: Notification = {
  updateData: false,
  actedScheduleId: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<boolean>) => {
      state.updateData = action?.payload;
    },
    setRespondedScheduleId: (state, action: PayloadAction<string>) => {
      state.actedScheduleId = action?.payload;
    },
  },
});

export const { setNotifications, setRespondedScheduleId } =
  notificationSlice.actions;

export default notificationSlice.reducer;
