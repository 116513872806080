import toast from "react-hot-toast";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

export const success = (message: string) =>
  toast(message, {
    position: "bottom-left",
    className: "toastSuccess",
    icon: <CheckCircleRoundedIcon />,
  });

export const error = (message: string) =>
  toast(message, {
    position: "top-right",
    className: "toastError",
    icon: <CancelRoundedIcon />,
  });
