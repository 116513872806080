import { OtherMoon } from "@heathmont/moon-icons";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

interface AllocationDayProps {
  isCurrentMonth: boolean;
  date: any;
  shiftType: string;
  seniorNurseCount: number;
  nurseCount: number;
  hcaCount: number;
  isAllocationFilled: boolean;
  isULVisible: boolean;
  onToggleDetails: () => void; // Callback to toggle details visibility
  // emptySchedule: boolean;
  isOverUtilized: boolean;
}

const AllocationDay: React.FC<AllocationDayProps> = ({
  date,
  shiftType,
  seniorNurseCount,
  nurseCount,
  hcaCount,
  isAllocationFilled,
  isULVisible,
  isCurrentMonth,
  onToggleDetails,
  // emptySchedule,
  isOverUtilized,
}) => {
  // const [isULVisible, setIsULVisible] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  // console.log("is Current  Month", isCurrentMonth);
  useEffect(() => {
    const value = seniorNurseCount + nurseCount + hcaCount;
    setTotalValue(value);
  }, []);
  // }, [date, totalValue]);  changed in above line
  useEffect(() => {
    // Close details when another AllocationDay is clicked
    //change condition !isULVisible
    if (isULVisible) {
      onToggleDetails();
    }
  }, []);

  // console.log(
  //   "check: HCA:",
  //   hcaCount,
  //   "nurse: ",
  //   nurseCount,
  //   "senior: ",
  //   seniorNurseCount
  // );
  return (
    <div
      className="flex flex-col justify-between h-full"
      onClick={onToggleDetails}
    >
      <div className="flex items-center justify-between">
        <h3
          className={` font-bold font-custom ${
            isCurrentMonth ? "text-gray-600" : "text-gray-300"
          }`}
        >
          {date}
        </h3>

        {shiftType === "sun" ? (
          <span className="inline-block text-krillin  py-[8px] px-[5px] bg-krillin-10 rounded-md">
            <Icon icon="uil:sun" />
          </span>
        ) : (
          <span className="inline-block py-[8px] px-[5px] bg-beerus rounded-md">
            <OtherMoon />
          </span>
        )}
      </div>
      <div className="flex items-center justify-between gap-[5px]">
        {nurseCount || hcaCount || seniorNurseCount ? (
          <div>
            {isULVisible ? (
              <ul className="flex items-center gap-[4px] leading-none mr-[auto]">
                {/* {seniorNurseCount ? ( */}
                <li>
                  <span
                    className={`inline-flex items-center justify-center w-[26px] h-[26px] rounded-full bg-chichi-10 border-chichi border border-dotted text-chichi ${
                      seniorNurseCount ? "" : "opacity-70"
                    }`}
                  >
                    {seniorNurseCount ? seniorNurseCount : "0"}
                  </span>
                </li>
                {/* ) : null} */}
                {/* {nurseCount ? ( */}
                <li>
                  <span
                    className={`inline-flex items-center justify-center w-[26px] h-[26px] rounded-full bg-whis-10 border-whis border border-dotted text-whis ${
                      nurseCount ? "" : "opacity-70"
                    }`}
                  >
                    {nurseCount ? nurseCount : "0"}
                  </span>
                </li>
                {/* // ) : null} */}
                {/* {hcaCount ? ( */}
                <li>
                  <span className="inline-flex items-center justify-center w-[26px] h-[26px] rounded-full bg-krillin-10 border-krillin border border-dotted text-krillin">
                    {hcaCount ? hcaCount : "0"}
                  </span>
                </li>
                {/* ) : null} */}
              </ul>
            ) : (
              totalValue !== 0 && (
                <span className="inline-flex text-xs py-[4px] px-[8px] items-center justify-center bg-gray-100 rounded-md">
                  {nurseCount + hcaCount + seniorNurseCount}
                </span>
              )
            )}
          </div>
        ) : (
          ""
        )}
        {/* && !isempty */}

        {isCurrentMonth ? (
          <span
            className={`inline-flex ml-auto w-[15px] h-[15px] rounded-full ${
              isOverUtilized
                ? "bg-krillin"
                : isAllocationFilled
                ? "bg-roshi"
                : "bg-dodoria"
            }`}
          ></span>
        ) : null}
      </div>
    </div>
  );
};

export default AllocationDay;
