import { createSlice, current } from "@reduxjs/toolkit";
import { IUser } from "../../types/IUser.interface";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const initialState: IUser = {
  User: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    hospital: "",
    hospitalName: "",
    employeeName: "",
    branch: "",
    branchName: "",
    sectionName: "",
    contactNo: "",
    joiningDate: "",
    seniorityLevel: "",
    designation: "",
    assignedSection: "",
    department: "",
    employeeId: "",
    address: "",
    isActive: true,
    temporaryRole: "",
    iat: 0,
    exp: 0,
  },
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.User = action.payload;

      state.isLoggedIn = true;
    },
    removeUser: (state) => {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("rtToken");
      sessionStorage.removeItem("acToken");
      state.User = null;
      state.isLoggedIn = false;
    },
    loadUser: (state) => {
      const user = JSON.parse(sessionStorage.getItem("user")!);
      if (user) {
        state.User = user;
        state.isLoggedIn = true;
      }
    },
  },
});

export const { setAuthUser, removeUser, loadUser } = authSlice.actions;

export default authSlice.reducer;
