import React, { useEffect, useState } from "react";
import { ControlsPlus, GenericSearch } from "@heathmont/moon-icons-tw";
import { Button } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
import Notification, {
  NotificationsList,
} from "../../../../notification/Notification";
import ProfileButton from "../../../../super-admin/layout/ScheduleHeader/widget/profile-header/ProfileButton";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { getHospitalData } from "../../../../../services/super-admin-services/hospital.service";
export default function ScheduleHeader({ isNeedRoute, isLoginRoute }: any) {
  const { User } = useSelector((state: RootState) => state.auth);
  const [hospital, setHospital] = useState<any>();
  const location = useLocation().pathname;
  const user = {
    value: User?.firstName + " " + User?.lastName,
    role: User?.designation,
    imageUrl: User?.imgURL ?? "/images/Avatar.png",
    roles: [User?.designation],
  };

  const fetchHospitalData = async () => {
    try {
      const response: any = await getHospitalData(User?.hospital as string);
      if (response) {
        setHospital(response.res.hospitalDB);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (User?.hospital) fetchHospitalData();
  }, [User]);
  const roles = ["nurse"];

  // useEffect(()=>{

  // })
  console.log("location: ", location);

  return (
    <>
      <header
        className={`py-4 px-7  ${
          isNeedRoute ? "bg-[#FEE6E9]" : "bg-primary"
        }    ${isLoginRoute ? "hidden" : "block"}`}
      >
        <nav className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-4">
            <Link to="/" className="cursor-pointer">
              <img src={"images/weblogo.svg"} alt="logo" />
            </Link>
            <div className="flex items-center rounded-xl  px-2 py-1">
              <img
                className="me-2 w-[37px] h-[37px] rounded-full"
                src={hospital ? hospital.logo : ""}
                alt=""
              />
              <div>
                <p className="text-white text-moon-14">
                  {hospital ? hospital.name : "Hospital name"}
                </p>
                <p className=" text-white text-moon-9">{User?.branchName}</p>
              </div>
            </div>
          </div>

          <ul
            className={`flex items-center space-x-9 text-xs cursor-pointer  ${
              isNeedRoute ? "text-[#272D36]" : "text-whitelight"
            }`}
          >
            <li
              className={`hover:text-white ${
                location === "/" ? "text-white underline" : ""
              }`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`hover:text-white ${
                location === "/scheduler" ? "text-white underline" : ""
              }`}
            >
              <Link to="/scheduler">Scheduler</Link>
            </li>
            {User?.temporaryRole === "branch admin" && (
              <>
                {" "}
                <li
                  className={`hover:text-white ${
                    location === "/home" ? "text-white underline" : ""
                  }`}
                >
                  <Link to="/home">View</Link>
                </li>
                <li
                  className={`hover:text-white ${
                    location === "/preferences" ? "text-white underline" : ""
                  }`}
                >
                  <Link to="/preferences">Preferences</Link>
                </li>
              </>
            )}
            {(User?.designation === "nurse manager" ||
              User?.temporaryRole === "nurse manager") && (
              <>
                <li
                  className={`hover:text-white ${
                    location === "/request" ? "text-white underline" : ""
                  }`}
                >
                  <Link to="/request">Requests</Link>
                </li>
                <li
                  className={`hover:text-white ${
                    location === "/employees" ? "text-white underline" : ""
                  }`}
                >
                  <Link to="/employees">Employees</Link>
                </li>
              </>
            )}
            {/* <li className={`hover:text-white${
                location === "Need" ? "text-white underline" : ""
              }`}
              onClick={() => setActive("Need")}>
              <Link to="/need">Need</Link>
            </li> */}
            <li
              className={`flex items-center  ${
                isNeedRoute ? "text-[#272D36]" : "text-white"
              }`}
            >
              <ControlsPlus className="text-moon-24 bg-greencustom text-primary me-2 rounded-full " />{" "}
              Contact Support
            </li>
          </ul>
          <ul className="flex items-center space-x-2 text-xs">
            {isNeedRoute ? (
              ""
            ) : (
              <>
                {/* <li className="flex">
                  <Button className="flex items-center w-auto bg-white px-2.5 py-1.5 text-blackcst  rounded-md">
                    September 2022
                    <ControlsChevronDownSmall className="text-moon-24 ms-2 text-primary" />
                  </Button>
                </li> */}
                {User?.designation === "nurse manager" ||
                  (User?.temporaryRole === "nurse manager" && (
                    <li className="flex">
                      {location !== "/scheduler" && (
                        <Link to="/scheduler">
                          <Button
                            // as="a"
                            // href="/"
                            className="flex items-center w-auto h-auto rounded-lg bg-blackcst  py-1.5 px-3 text-white"
                            index={0}
                            // onClick={()=>}
                            // disabled={location === "/scheduler"}
                          >
                            <ControlsPlus className="text-moon-24 me-2 " />
                            Create Schedule
                          </Button>
                        </Link>
                      )}
                    </li>
                  ))}
              </>
            )}
            {/* <li className="flex">
              <Button
                className={`inline-flex items-center justify-center w-[30px] h-[30px] rounded-full text-moon-24  ${
                  isNeedRoute
                    ? "bg-white bg-opacity-25 text-[#272D36] "
                    : "text-white bg-primarydarkl"
                } p-0 `}
                index={0}
              >
                <GenericSearch />
              </Button>
            </li> */}
            <li className="flex">
              <Notification isNeedRoute={isNeedRoute} />
            </li>
            <li className="flex">
              <ProfileButton
                value={user?.value}
                role={user?.role as string}
                imageUrl={user?.imageUrl}
              />{" "}
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
