import React from "react";
import NeedStats from "../Widgets/cards/stats/NeedStats";

interface Option {
  name: string;
  value: number;
}

interface DataItem {
  title: string;
  value: {
    name: string;
  };
  statsNumber: number;
  options: Option[];
}

const NeedHeader = () => {
  const data: DataItem[] = [
    {
      title: "Allocation required",
      value: { name: "Aug" },
      statsNumber: 1,
      options: [
        { name: "Sep", value: 1 },
        { name: "Oct", value: 2 },
        { name: "Dec", value: 3 },
      ],
    },
    {
      title: "Provided",
      value: { name: "Sep" },
      statsNumber: 6,
      options: [
        { name: "Sep", value: 1 },
        { name: "Oct", value: 2 },
        { name: "Dec", value: 3 },
      ],
    },
    {
      title: "Total Needs",
      value: { name: "Week" },
      statsNumber: 21,
      options: [
        { name: "Week 1", value: 1 },
        { name: "Week 2", value: 2 },
        { name: "Week 3", value: 3 },
      ],
    },
  ];

  return (
    <div className="px-7 bg-[#FEE6E9]">
      <div className="flex items-center py-[23px]  -mx-[16px]">
        <div className="w-1/4 px-[16px]">
          <div>
            <h3 className="text-[32px] font-semibold -tracking-[0.5px] text-blackcst  mb-8px">
              Need
            </h3>
            <p className="text-blackcst  text-[16px] ">
              All need based information here
            </p>
          </div>
        </div>
        <div className="w-3/4  px-[16px]">
          <div className="grid grid-cols-3 gap-[15px]">
            {data.map((item, index) => (
              <div>
                <NeedStats
                  key={index}
                  // title={item.title}
                  // value={item.value}
                  // statsNumber={item.statsNumber}
                  // options={item.options}
                  title={item.title}
                  value={item.value}
                  statsNumber={item.statsNumber}
                  options={item?.options}
                  bgColor="bg-white"
                  textColor=""
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedHeader;
