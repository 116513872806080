import { api } from "../../utils/axios";
export const postEmployee = async (employee: any) => {
  try {
    const { data, ok } = await api.post(`/employee/createEmployee`, employee);
    if (ok) {
      return data;
    }
    return{ err: data}
  } catch (error) {
    return { err: error };
  }
};
export const updateEmployee = async (employee: any) => {
  try {
    const body={
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      employeeName: employee.employeeName,
      contactNo: employee.contactNo,
      joiningDate: employee.joiningDate,
      seniorityLevel: employee.seniorityLevel,
      gender: employee.gender,
      address: employee.address,
      isActive: employee.isActive
    }
    
    const { data, ok } = await api.patch(`/employee/updateEmployeeInfo/${employee?._id}`, body);
    if (ok) {
      return data;
    }
    return{ err: data}
  } catch (error) {
    return { err: error };
  }
};

export const deleteEmployee = async (id:string) => {
  try {
    const { data, ok } = await api.delete(`/employee/deleteEmployee/${id}`);
    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};