import React, { useEffect, useState } from "react";
import {
  activateEmpSvc,
  deActivateEmpSvc,
  getEmployeesView,
  getSectionsView,
} from "../../../services/branch-admin-services/views.service";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import Table from "../../../Components/branch-admin/ViewTable/table";
import { IEmployees } from "../../../types/branch-admin-interfaces/IEmployees.interface";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { Button, IconButton, Switch } from "@heathmont/moon-core-tw";
import { addSectionBranchAdmin, editSectionBranchAdmin, getSections, getSectionsBranchAdmin } from "../../../services/branch-admin-services/section.service";
import {
  ISectionsTable,
  SectionsWithRule,
} from "../../../types/branch-admin-interfaces/ISectionsView.interface";
import SectionsTable from "../../../Components/branch-admin/ViewTable/sectionsTable";
import ModalEditSection from "../../super-admin/settings/hospital/widgets/ModalEditSection";
import { setSnackBar, setToastMessage } from "../../../features/ToastSlice/toastSlice";
import { RootState, store } from "../../../store/store";
import AddSection from "../../super-admin/settings/hospital/widgets/AddSection";
import { set } from 'react-hook-form';
import { useSelector } from "react-redux";
import { error } from '../../../utils/toast';
import { GenericEdit } from "@heathmont/moon-icons-tw";
interface Props {
  dataImported: boolean;
  searchQuery: string;
  filtersArray: IFilterParameters[];
  isApplyFilter: boolean;
}

const SectionsView: React.FC<Props> = ({
  dataImported,
  searchQuery,
  filtersArray,
  isApplyFilter,
}) => {
  const columnHelper = createColumnHelper<SectionsWithRule>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [editSection,setEditSection] = useState(false)
  const [addSection,setAddSection] = useState(false)
  const [sectionInfo,setSectionInfo] = useState('')
  const [sections,setSections]= useState<string[]>([])
  const branchData = useSelector((state:RootState)=>state.auth.User)
  const isEditSectionOpen =()=>{
    setEditSection(true)
  }
  const isEditSectionClose =()=>{
    setEditSection(false)
  }

  const isAddSectionOpen =()=>{
    getSections()
    setAddSection(true)
  }
  const isAddSectionClose =()=>{
    setSections([])
    setAddSection(false)
  }

  const columns = [
    columnHelper.accessor("sectionName", {
      enableSorting: true,
      header: () => "Section Name",
      cell: (info) => (
        <span className="truncate max-w-[200px]">{info.getValue()}</span>
      ),
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);
        // we add for case in-sensitive sorting.
        const stringA = valueA ? String(valueA).toLowerCase() : '';
        const stringB = valueB ? String(valueB).toLowerCase() : '';
  
        return stringA.localeCompare(stringB);
      },
    }),
    columnHelper.accessor("rules.numberOfBeds", {
      enableSorting: true,
      header: () => "Number of Beds",
      cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
    }),
    columnHelper.accessor("rules.numberOfRooms", {
      enableSorting: true,
      header: () => "Number of Rooms",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("rules.numberOfBedsPerHCADayShift", {
      enableSorting: true,
      header: () => "Patients per HCA (Day)",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("rules.numberOfBedsPerHCANightShift", {
      enableSorting: true,
      header: () => "Patients per HCA (Night)",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("rules.numberOfBedsPerNursesDayShift", {
      enableSorting: true,
      header: "Patients per Nurse (Day)",
      cell: (info) => (
        <span className="text-gray-400 first-line:truncate max-w-[300px]">
          {info.renderValue()}
        </span>
      ),
    }),
    columnHelper.accessor("rules.numberOfBedsPerNursesNightShift", {
      enableSorting: true,
      header: "Patients per Nurse (Night)",
      cell: (info) => (
        <span className="text-gray-400 first-line:truncate max-w-[300px]">
          {info.renderValue()}
        </span>
      ),
    }),
    columnHelper.accessor("rules.numberOfBedsPerSeniorNurses", {
      enableSorting: true,
      header: "Patients per Senior Nurse",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      enableSorting: false,
      header: "Action",
      cell: (info) => (
        <IconButton
        variant="ghost"
        icon={<GenericEdit />}
        onClick={() => {
            editHandler(info.row.original);
        }}
      />
      ),
    }),
  ];

  const editHandler=(row:any)=>{
    setSectionInfo({_id: row.sectionId,...row})
    isEditSectionOpen()
  }
  const [data, setData] = useState<SectionsWithRule[]>(() => []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let timeoutId: NodeJS.Timeout | null = null;
  const [sectionsViewRes, setSectionsViewRes] = useState<ISectionsTable>();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const getEmployeesData = async (searchQuery?: string) => {
    // const params = {
    //   pageChange: pageChange,
    //   limit: 50,
    // search: searchQuery,
    //   filterParams: filtersArray,
    // };
    setIsLoading(true);
    const res: any = await getSectionsView(searchQuery);
    setIsLoading(false);
    setSectionsViewRes(res as ISectionsTable);
    setData(res?.sectionsWithRules as SectionsWithRule[]);
  };

  const getSections = async()=>{
    try{
      const res:any = await getSectionsBranchAdmin()
      setSections(res?.sections)
    }catch(error){
      console.log('error', error)
    }
    
  }

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getEmployeesData(searchQuery);
      }, 1000);
    } else {
      getEmployeesData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dataImported, searchQuery]);

  const handleEditSubmit=async(branchId?:string,sectionId?:string,sectionName?:any)=>{
    try{
      const res:any = await editSectionBranchAdmin({
        _id: sectionId, sectionName:sectionName
      })
      if(!res.err){
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Section Updated Successfully"));
        getEmployeesData()
      }
    }catch(error){
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  }

  const handleAddSection = async(branchId?: string, section?: any)=>{
    try{
      const res:any = await addSectionBranchAdmin(section)
      if(res.response){
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage(res.response));
        getEmployeesData()
      }
      
    }catch(error){
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  }
  return (
    <div>
        <div className="w-full px-[16px] text-right">
        <div className="flex items-center justify-end gap-2 py-1">
          <Button
            variant="tertiary"
            className="ml-aut text-white"
            onClick={isAddSectionOpen}
          >
            Add Section
          </Button>
        </div>
      </div>
      {!isLoading && (
        <SectionsTable
          table={table}
          tableData={sectionsViewRes as ISectionsTable}
        />
      )}
      <ModalEditSection isOpen={editSection} closeModal={isEditSectionClose} sectionData={sectionInfo} branchId={''} editHospitalSection={handleEditSubmit }/>
      <AddSection isOpenModalSection={addSection} closeModalSection={isAddSectionClose} branchData={branchData} getAllSectionsList={sections} sectionsList={sections} addSectionsList={handleAddSection}/>
    </div>
  );
};

export default SectionsView;
