import React from "react";
import { Preferences } from "../../../types/branch-admin-interfaces/ISectionPreference.interface";
import SimpleBar from "simplebar-react";

interface Props {
  preferences: Preferences;
}
const AllocatePreferences: React.FC<Props> = ({ preferences }) => {
  return (
    <>
      <div className="bg-[#f5f5f5] py-5 rounded-lg">
        <h3 className="text-lg text-[#272D36] mb-6 font-semibold px-4">
          Preferences
        </h3>
        <SimpleBar className="h-[284px] overflow-y-auto px-5">
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">Working hours</p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.workingHours || 0}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">Weekends allowed to work</p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.weekendsAllowedToWork || 0}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">Request Time Off</p>
            <p className="text-sm text-[#272D36] font-semibold flex items-center gap-3">
              <span className="text-sm text-[#8697A2]">Week Days</span>
              {preferences?.maxROThatCanBeApplied || 0}
              <span className="text-sm text-[#8697A2]">Weekends</span>
              {preferences?.maxROThatCanBeAppliedWeekends || 0}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">
              Override Time-off requests{" "}
            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.overrideTimeOfRequests || 0}%
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">
              Number of consecutive shifts{" "}
            </p>
            <p className="text-sm text-[#272D36] font-semibold flex items-center gap-3">
              <span className="text-sm text-[#8697A2]">Day shift</span>
              {preferences?.consecutiveDayShifts || 0}
              <span className="text-sm text-[#8697A2]">Night shift</span>
              {preferences?.consecutiveNightShifts || 0}
              <span className="text-sm text-[#8697A2]">Overall shift</span>
              {preferences?.consecutiveShifts || 0}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">Shift duration </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.shiftDuration || 0}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">
              Allowed to allocate consecutive shifts
            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.setToAllocateConsecutiveShifts
                ? "Allowed"
                : "Not Allowed"}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">
              Forecast patient utilization
            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {preferences?.patientForecastUtilization || 0}%
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between items-center">
            <p className="text-base text-[#272D36]">
              Shifts Distribution{" "}
            </p>
            <p className="text-sm text-[#272D36] font-semibold flex items-center gap-3">
              <span className="text-sm text-[#8697A2]">Day shift</span>
              {preferences?.shiftDistributionDayShifts || 0}%
              <span className="text-sm text-[#8697A2]">Night shift</span>
              {preferences?.shiftDistributionNightShifts || 0}%
            </p>
          </div>
        </SimpleBar>
      </div>
    </>
  );
};

export default AllocatePreferences;
