import React, { useEffect, useState } from "react";
import { Button, MenuItem } from "@heathmont/moon-core-tw";
import { getSections } from "../../../../services/branch-admin-services/section.service";
interface Props {
  formData: (data: any) => void;
  branchName?: string;
}
const PreferencesSidebar: React.FC<Props> = ({ formData, branchName }) => {
  const [sections, setSections] = useState("");
  const [selectedSection, setSelectedSection] = useState(""); // Track the selected section

  let sectionName: any;
  const sectionSelect = (data: any) => {
    setSelectedSection(data._id); // Update the selected section
    formData(data);
  };

  const getSectionsData = async () => {
    const response: any = await getSections();
    if (!response.err) {
      setSections(response?.sections);
      setSelectedSection(response?.sections[0]?._id); // Set the initial selected section
      sectionName = response?.sections[0];
      formData(sectionName);
    }
  };

  useEffect(() => {
    getSectionsData();
  }, []);

  return (
    <div>
      <div className="bg-whitept-[30px] p-[30px]">
        <h3 className="text-moon-20 font-semibold capitalize">{branchName}</h3>
      </div>
      <div className="prefrencesbox p-[0_30px]">
        <div className="w-full bg-gohan flex flex-col gap-2 ">
          {sections &&
            Object.values(sections).map((section: any) => (
              <MenuItem
                onClick={() => sectionSelect(section)}
                className={
                  selectedSection === section._id
                    ? "bg-primary text-white"
                    : "hover:bg-gray-100 hover:text-primary"
                }
              >
                {section?.sectionName}
              {!section.isActive &&
              <Button
                size="sm"
          className={ selectedSection === section._id ? "bg-[#F8F8F8] text-black ml-2":
           "bg-[rgba(36,156,186,0.15)] text-gray-500 ml-2"}
                >
                Not Active
              </Button>}
              </MenuItem>
            ))}
          {/* <MenuItem
            as='a'
            onClick={() => sectionSelect("Medical selected")}
            className='hover:bg-gray-100 hover:text-primary'>
            Medical
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            ICU
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            Personnel
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            X-ray
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            Physical Medicine
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            Paramedical
          </MenuItem>
          <MenuItem as='a' className='hover:bg-gray-100 hover:text-primary'>
            Rehabilitation
          </MenuItem>
          <MenuItem
            as='a'
            href='/'
            className='hover:bg-gray-100 hover:text-primary'>
            OT
          </MenuItem>
          <MenuItem
            as='a'
            href='/'
            className='hover:bg-gray-100 hover:text-primary'>
            OPD
          </MenuItem> */}
        </div>
      </div>
    </div>
  );
};

export default PreferencesSidebar;
