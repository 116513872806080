import {
  Button,
  IconButton,
  Input,
  Label,
  Modal,
} from "@heathmont/moon-core-tw";
import { ControlsClose } from "@heathmont/moon-icons";
import { useEffect, useState } from "react";
import { IBranchData } from "../../../../../types/super-admin-interfaces/IBranchData.interface";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../../features/ToastSlice/toastSlice";
import { store } from "../../../../../store/store";
interface ModalAddRoleProps {
  isOpenModal: boolean;
  closeModalBranch: () => void;
  postBranchData?: (data: IBranchData) => void;
  editBranchData?: any;
  editHospitalBranch?: (data: any, id: any) => void;
}

const AddBranch = ({
  isOpenModal,
  closeModalBranch,
  postBranchData,
  editBranchData,
  editHospitalBranch,
}: ModalAddRoleProps) => {
  const [formData, setFormData] = useState<IBranchData>({} as IBranchData);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({
    branchName: '',
    phoneNumber: '',
    contact: '',
    address: ''
});

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    setFormErrors((prev) => ({ ...prev, [event.target.name]: "" }));
  };

  const inputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const errors: Record<string, string> = {
      ...formErrors,
      [name]: !value ? `Please enter the ${name}` : "",
      phoneNumber:
        name === "phoneNumber" && !/^[+\d]+$/.test(String(value))
          ? "Phone number should only contain digits"
          : formErrors.phoneNumber,
      branchName:
        name === "branchName" && /^\s/.test(String(value))
          ? "Branch name should not start with spaces"
          : formErrors.branchName,
      contact:
      name === "contact" && (/^\s/.test(String(value)) || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(value)))
      ? "Please Enter Valid Email"
          : formErrors.contact,
      address:
        name === "address" && /^\s/.test(String(value))
          ? "Address should not start with spaces"
          : formErrors.address,
    };

    setFormErrors(errors);
  };

  useEffect(() => {
    if (editBranchData && editBranchData !== null) {
      setFormData((prevData) => ({ ...prevData, ...editBranchData }));
    } else {
      setFormData({} as IBranchData);
    }
    setFormErrors({
      branchName: '',
      phoneNumber: '',
      contact: '',
      address: ''
  });
  }, [editBranchData, isOpenModal]);

  const isFormValid = () => {
    const errors: Record<string, string> = {
      branchName: !formData.branchName
        ? "Please enter the branch name"
        : !/^\S.+$/.test(formData.branchName)
        ? "Please Enter Valid branch name"
        : "",
      contact: !formData?.contact
        ? "Please enter the contact"
        : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(formData.contact))
        ? "Please Enter Valid Email"
        : "",
      address: !formData?.address
        ? "Please enter the address"
        : !/^\S.+$/.test(String(formData.address))
        ? "Address should not start with spaces"
        : "",
      phoneNumber: !formData?.phoneNumber
        ? "Please enter phone number"
        : !/^[+\d]+$/.test(String(formData.phoneNumber))
        ? "Phone number should only contain digits"
        : "",
    };

    setFormErrors({ ...errors });

    console.log("Errors:", errors);
    console.log(
      "isValid:",
      // Object.values(errors).every((error) => error === ""),
    );

    setFormErrors({ ...errors });

    return Object.values(errors).every((error) => error === "");
  };

  const isSubmitDisabled =
  !formData.branchName ||
  !/^\S.+$/.test(formData.branchName) || // Branch name must not start with spaces
  !formData.address ||
  !/^\S.+$/.test(formData.address) || // Address must not start with spaces
  !formData.contact ||
  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(formData.contact)) || // Validate email format
  !formData.phoneNumber ||
  !/^[+\d]+$/.test(formData.phoneNumber); // Phone number should only contain digits
  return (
    <Modal
      open={isOpenModal}
      onClose={() => {
        closeModalBranch();
      }}
    >
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[500px]">
        <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
          {editBranchData ? (
            <h3 className="text-moon-20 font-semibold">Edit Branch</h3>
          ) : (
            <h3 className="text-moon-20 font-semibold">Add Branch</h3>
          )}

          <IconButton
            onClick={closeModalBranch}
            variant="ghost"
            icon={<ControlsClose />}
          />
        </div>
        <div className="modal-content px-4 py-2  border-b border-beerus">
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="text-moon-16 text-[#000]">
              Branch Name
            </Label>
            <Input
              type="text"
              placeholder="Enter Branch Name"
              name="branchName"
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              value={formData?.branchName || ""}
            />
            <div className="text-red-500 text-xs mt-1">
              {formErrors.branchName}
            </div>
          </div>
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="text-moon-16 text-[#000]">
              Address
            </Label>
            <Input
              type="text"
              placeholder="Enter address"
              name="address"
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              value={formData?.address || ""}
            />
            <div className="text-red-500 text-xs mt-1">
              {formErrors?.address}
            </div>
          </div>
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="text-moon-16 text-[#000]">
              Contact
            </Label>
            <Input
              defaultValue={formData?.contact ? formData?.contact : ""}
              type="text"
              placeholder="Enter email"
              name="contact"
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              value={formData?.contact || ""}
            />
            <div className="text-red-500 text-xs mt-1">
              {formErrors?.contact}
            </div>
          </div>
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="text-moon-16 text-[#000]">
              Phone Number
            </Label>
            <Input
              defaultValue={formData?.phoneNumber ? formData?.phoneNumber : ""}
              type="phone"
              placeholder="Enter phone number"
              name="phoneNumber"
              onChange={inputChangeHandler}
              onBlur={inputBlurHandler}
              value={formData?.phoneNumber ? formData?.phoneNumber : ""}
            />
            <div className="text-red-500 text-xs mt-1">
              {formErrors?.phoneNumber}
            </div>
          </div>
        </div>
        <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
          <Button variant="secondary" onClick={closeModalBranch}>
            Discard
          </Button>
          {editBranchData ? (
            <Button
              variant="tertiary"
              onClick={() => {
                if (isFormValid()) {
                  editHospitalBranch &&
                    editHospitalBranch(editBranchData?._id, formData);
                  closeModalBranch();
                }
              }}
              disabled={isSubmitDisabled}

            >
              Save Branch
            </Button>
          ) : (
            <Button
              variant="tertiary"
              onClick={() => {
                if (isFormValid()) {
                  postBranchData && postBranchData(formData);
                  closeModalBranch();
                }
              }}
              disabled={isSubmitDisabled}
              >
              Add
            </Button>
          )}
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default AddBranch;
