import { Avatar, Checkbox, Chip, IconButton } from "@heathmont/moon-core-tw";
import { ArrowsUp } from "@heathmont/moon-icons-tw";
import React, { useState } from "react";
import DropdownIcon from "../UI/DropdownIcon";
import DrawerEmployee from "../Drawers/employee-detail/DrawerEmployee";
import { getEmployeeProfileData } from "../../../services/nurse-manager-services/employee.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IEmployee } from "../../../types/nurse-manager-interfaces/IEmployeeTableResponse.interface";
import { IEmployeeDetails } from "../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { getBorderColor, getTextColor } from "../../Colors/colorSetting";
interface CtaOption {
  name: string;
}

interface TableDatum {
  imageUrl: string;
  role: string;
  name: string;
  hoursLeft: number;
  daysLeft: number;
  shift: number;
  checked: boolean;
}

const ctaOptions: CtaOption[] = [{ name: "Edit" }, { name: "Delete" }];

// const tableData: TableDatum[] = [
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Wade Warren",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Leader",
//     name: "Annette Black",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Bessie Cooper",
//     hoursLeft: 1,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Robert Fox",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Jane Cooper",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Ronald Richards",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Chief Nurse",
//     name: "Jerome Bell",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Jenny Wilson",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
//   {
//     imageUrl: "images/Avatar.png",
//     role: "Nurse",
//     name: "Darrell Steward",
//     hoursLeft: 16,
//     daysLeft: 16,
//     shift: 16,
//     checked: false,
//   },
// ];
interface Props {
  tableData?: IEmployee[];
}
const EmployeesTable: React.FC<Props> = ({ tableData }) => {
  const [filteredData, setFilteredData] = useState(tableData);
  const [isAscending, setIsAscending] = useState(true);
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeDetails>(
    {} as IEmployeeDetails
  );

  const [isOpen, setIsOpen] = useState(false);
  const { date } = useSelector((state: RootState) => state.time);
  const handleFilter = () => {
    const sortedList = tableData?.sort((a: any, b: any) => {
      const nameA = a?.employeeName?.toLowerCase();
      const nameB = b?.employeeName?.toLowerCase();
      if (nameA < nameB) return isAscending ? -1 : 1;
      if (nameA > nameB) return isAscending ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedList);
    console.log("FIL", sortedList);
    setIsAscending(!isAscending);
  };

  const arrowRotation = isAscending ? "rotate-0" : "rotate-180";

  // const handleCheckboxChange = (index: number) => {
  //   const updatedData = [...filteredData];
  //   updatedData[index].checked = !updatedData[index].checked;
  //   setFilteredData(updatedData);
  // };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getEmployeeData = async (employeeID: string) => {
    const res: any = await getEmployeeProfileData(
      employeeID,
      date?.currentMonth.toString(),
      date?.year.toString()
    );
    if (res?.res) {
      setEmployeeInfo(res?.res);
      console.log("res,res", res?.res);
    }
  };

  return (
    <>
      <div>
        {tableData?.length !== 0 ? (
          <div className="bg-gray-100 rounded-[8px]">
            <div className="flex items-center flex-row p-[0px_8px] mb-[2px]">
              {/* HEADER */}
              {/* <div className="p-[8px] text-moon-16">
              <Checkbox bgColor="bg-cyan-600" />
            </div> */}
              <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                <span>Employee ID</span>
              </div>

              <div className="flex-1 flex justify-between items-center p-[8px]  text-moon-16">
                <span className="flex-1">Name</span>
                {/* <IconButton
                variant="ghost"
                className={`p-0 h-[24px] ${arrowRotation}`}
                icon={<ArrowsUp />}
                onClick={handleFilter}
              /> */}
              </div>
              <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                <span>Hours Left</span>
              </div>
              <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                <span>Shifts Left</span>
              </div>
              <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                <span>Status</span>
              </div>

              {/* <div className="flex-[0_0_58px]"></div> */}
            </div>
            {/* BODY */}
            {tableData?.map((row: any, index: any) => (
              <div
                key={index}
                className={`flex items-center flex-row p-[0px_8px] my-[2px] rounded-[8px] ${
                  row.checked ? "bg-cyan-50" : "bg-white"
                }`}
                onClick={() => {
                  getEmployeeData(row?._id);
                  handleClick();
                }}
              >
                {/* HEADER */}
                {/* <div className="p-[8px] text-moon-16">
                <Checkbox
                  bgColor="bg-cyan-600"
                  checked={row.checked}
                  onChange={() => handleCheckboxChange(index)}
                />
              </div> */}
                <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                  <h4 className="text-moon-16 font-semibold">
                    {row?.employeeId}
                  </h4>
                </div>

                <div className="flex-1  p-[8px]  text-moon-16 ">
                  <div className="flex justify-between items-center cursor-pointer">
                    <span className="inline-flex rounded-full shadow-md  mr-[15px]">
                      <Avatar
                        size="md"
                        className={`rounded-full border-2 ${getBorderColor(
                          row?.designation
                        )} overflow-hidden`}
                        imageUrl={row.imageUrl}
                      />
                    </span>
                    <div className="flex-1">
                      <Chip
                        size="sm"
                        className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${getTextColor(
                          row?.designation
                        )}`}
                      >
                        {row?.designation}
                      </Chip>
                      <h4 className="text-moon-16 font-semibold">
                        {row?.employeeName}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                  {/* <span className="block text-moon-14 text-gray-600 ">
                    Hours Left
                  </span> */}
                  <span className="text-moon-16 font-semibold">
                    {row?.hoursSpent}
                  </span>
                  <span className="text-moon-14 font-semibold text-gray-600">
                    /{row?.hoursAllocated}
                  </span>
                </div>
                <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                  {/* <span className="block text-moon-14 text-gray-600 ">
                    Shifts Left
                  </span> */}
                  <span className="text-moon-16 font-semibold">
                    {row?.shiftsSpent}
                  </span>
                  <span className="text-moon-14 font-semibold text-gray-600">
                    /{row?.shiftsAllocated}
                  </span>
                </div>
                <div className="flex-[0_0_217px] p-[8px]  text-moon-16">
                  {/* <span className="block text-moon-14 text-gray-600 ">Shift</span> */}{" "}
                  <h4 className="text-moon-16 font-semibold">
                    <span
                      className={`w-3 h-3 rounded-full inline-flex ${
                        row?.status === "Available" ? "bg-roshi" : "bg-dodoria"
                      }`}
                    ></span>{" "}
                    {row?.status}
                  </h4>
                </div>
                {/* <div className="flex-[0_0_58px]">
                <DropdownIcon cta={ctaOptions} />
              </div> */}
              </div>
            ))}
          </div>
        ) : (
          <div className="sectionsvg flex items-center justify-center text-center">
            <img
              src={"images/no-schedule-to-approve.svg"}
              alt="No schedule to approve"
            />
          </div>
        )}
      </div>

      <DrawerEmployee
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        employeeInfo={employeeInfo}
        // employeeData={employeeInfo}
      />
    </>
  );
};

export default EmployeesTable;
