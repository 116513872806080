import React, { useState } from "react";
import { Button } from "@heathmont/moon-core-tw";
import { TimeCalendarDate, GenericDelete } from "@heathmont/moon-icons-tw";
import OverlappedAvatar from "./OverlappedAvatar";
import { scheduleMonthYear } from "../../../Pages/nurse-manager/Nurse-Manager/add-schedule/index";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setDate } from "../../../features/nurse-manager/Time Slice/timeSlice";
import { ScheduleRecordCardData } from "../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import ConfirmationModal from "../../../Pages/super-admin/settings/hospital/widgets/ConfirmationModal";

interface Props {
  schedule: ScheduleRecordCardData;
  setSelectedRecord: (data: ScheduleRecordCardData) => void;
  setIsShowSchedule: (check: boolean) => void;
  setSelectedScheduleId: (scheduleId: string) => void;
  deleteSchedules: Function;
}
const ScheduleRecordCard: React.FC<Props> = ({
  schedule,
  setSelectedRecord,
  setIsShowSchedule,
  setSelectedScheduleId,
  deleteSchedules,
}) => {
  // const scheduleName = moment()?.format("MMMM")
  // console.log("scheduleName: ", moment().month(schedule?.month - 1).format("MMMM") );
  const dispatch = useDispatch();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const openConfirmationModal = (data: any) => {
    setConfirmationData(data);
    setIsOpenConfirm(true);
  };

  const scheduleName =
    moment()
      .month(schedule?.month - 1)
      .format("MMMM ") + moment().year(schedule?.year).format("YYYY");

  const createDate = moment(schedule?.createdAt);
  const createdOn = createDate?.format("DD MMM YYYY");

  const updateDate = moment(schedule?.updatedAt);
  const updatedOn = updateDate?.format("DD MMM YYYY");

  const handleOpenSchedule = () => {
    setSelectedRecord(schedule);
    setIsShowSchedule(true);
    const selectedSchedule = {
      month: schedule?.month,
      year: schedule?.year,
    };
    dispatch(
      setDate({
        month: moment()
          .month(schedule?.month - 1)
          .format("MMMM"),
        currentMonth: schedule?.month,
        year: schedule?.year,
      })
    );
    setSelectedScheduleId(schedule?._id);
  };

  const handleConfirmAction = () => {
    deleteSchedules(schedule._id);
  };
  return (
    <>
      <div
        className="bg-white p-4 rounded-xl cursor-pointer "
        onClick={() => {
          handleOpenSchedule();
        }}
      >
        <div className="flex justify-start items-start gap-2">
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center">
              <span className="rounded-full flex items-center justify-center bg-blue-50 p-2.5 w-[50px] h-[50px] me-4">
                <TimeCalendarDate className="text-moon-24 text-whis" />
              </span>
              <div>
                <p className="text-xs">Schedule</p>
                <h4 className="text-xl">{scheduleName}</h4>
              </div>
            </div>
            <div>
              {schedule.status !== "accepted" && (
                <Button
                  variant="ghost"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    openConfirmationModal({
                      title: "Confirm Delete Schedule",
                      actionFor: "Branch",
                      message: (
                        <p className="text-moon-18 mb-[28px]">
                          {" "}
                          The Schedule{" "}
                          <strong className="font-semibold ">
                            {scheduleName}
                          </strong>
                          <br />
                          will be deleted
                        </p>
                      ),
                      confirmAction: "Delete",
                      id: {
                        section: schedule._id,
                      },
                    });
                  }}
                >
                  <GenericDelete className="text-moon-24" />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="pt-3 capitalize">{/* <OverlappedAvatar /> */}</div>
        <div className="flex justify-between">
          <div>
            <div className="flex justify-between items-center mt-3">
              <p className="text-moon-12 text-trunks">
                Status:{" "}
                <span className="text-moon-12 text-black ms-1 capitalize ">
                  {schedule?.status}
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center mt-1">
              <p className="text-moon-12 text-trunks">
                Version:{" "}
                <span className="text-moon-12 text-black ms-1 capitalize ">
                  {schedule?.scheduleVersion}
                </span>
              </p>
            </div>
          </div>

          <div className="flex justify-between items-center mt-3">
            <div className="">
              <p className="text-moon-12 text-trunks mt-1">
                Created on
                <span className="text-moon-12 text-black ms-1">
                  {createdOn}
                </span>
              </p>
              <p className="text-moon-12 text-trunks mt-1">
                Modified on
                <span className="text-moon-12 text-black ms-1">
                  {updatedOn}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};
export default ScheduleRecordCard;
