import { Icon } from "@iconify/react";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import React from "react";
import GroupAvatar from "../../../UI/GroupAvatar";

interface Avatar {
  image: string;
  border: string;
}

interface RecentScheduleCardProps {
  month: any;
  day: number;
  dayType: string;
  date: string;
  specialty: string;
  // avatars: Avatar[];
  overflowCount: number;
}

const RecentScheduleCard: React.FC<RecentScheduleCardProps> = ({
  month,
  day,
  dayType,
  date,
  specialty,
  // avatars,
  overflowCount,
}) => {
  const renderIcon = () => {
    if (dayType === "day") {
      return <Icon icon="uil:sun" className="text-amber-600" />;
    } else {
      return <OtherMoon className="text-gray-600" />;
    }
  };

  return (
    <div className="flex bg-white rounded-md overflow-hidden border border-beerus">
      <div className="flex-[0_0_96px] p-3 inline-flex items-center border-r border-beerus flex-col justify-center">
        <div className="text-moon-20 font-semibold">{month}</div>
        <div className="text-moon-48 font-semibold">{day}</div>
      </div>
      <div className="flex-1 py-[11px] px-[24px]">
        <div className="inline-flex items-center mb-[6px]">
          {renderIcon()}
          <span className="text-moon-12 font-semibold ml-[9px]">
            {" "}
            {dayType.charAt(0).toUpperCase() + dayType.slice(1)}
          </span>
        </div>
        <div className="text-moon-14 font-semibold mb-[6px]">{date}</div>
        <p className="text-moon-12 text-gray-600">{specialty}</p>
        {/* <GroupAvatar avatars={avatars} overflowCount={overflowCount} /> */}
      </div>
    </div>
  );
};

export default RecentScheduleCard;
