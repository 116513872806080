import React from "react";
interface Props {
  employee?: any;
}

const EmployeeDetail: React.FC<Props> = ({ employee }) => {
  return (
    <div className="px-5 py-2">
      <div className="flex flex-wrap -mx-3 items-start my-4">
        <div className="w-1/4 px-3">
          <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
            Email:
          </h4>
        </div>
        <div className="w-3/4 px-3">
          <h4 className="text-moon-14">{employee?.email}</h4>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 items-start my-4">
        <div className="w-1/4 px-3">
          <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
            Phone:
          </h4>
        </div>
        <div className="w-3/4 px-3">
          <h4 className="text-moon-14">{employee?.contactNo}</h4>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 items-start my-4">
        <div className="w-1/4 px-3">
          <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
            Identity:
          </h4>
        </div>
        <div className="w-3/4 px-3">
          <h4 className="text-moon-14">{employee?.employeeId}</h4>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 items-start my-4">
        <div className="w-1/4 px-3">
          <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
            Joined:
          </h4>
        </div>
        <div className="w-3/4 px-3">
          <h4 className="text-moon-14">{employee?.joiningDate}</h4>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 items-start my-4">
        {/* <div className="w-1/4 px-3">
          <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
            Address:
          </h4>
        </div>
        <div className="w-3/4 px-3">
          <h4 className="text-moon-14">
            Suzy Queue. 4455 Landing Lange, APT 4. Louisville, KY 40018-1234.
          </h4>
        </div> */}
      </div>
    </div>
  );
};

export default EmployeeDetail;
