import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { ControlsChevronDown } from "@heathmont/moon-icons";

interface OptionItem {
  name: string; // Replace with actual type
}
interface Props {
  id: number;
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersArray: IFilterParameters[]) => void;
  isMonthChanged?: string;
  isMonthSelected?: boolean;
}
const FilterYear: React.FC<Props> = ({
  id,
  filtersArray,
  setFiltersArray,
  isMonthChanged, //to clear the value of year when months value is changed
  isMonthSelected, //to check wether it is for by month and year filter or only for by year filter
}) => {
  const [option, setOption] = useState<OptionItem>();

  const currentYear = new Date().getFullYear();
  const yearArray = Array.from(
    { length: 25 },
    (v, i) => currentYear + 1 - 25 + i + 1
  ).reverse();

  const year: OptionItem[] = yearArray?.map((year: number) => ({
    name: year.toString(),
  }));

  const handleOptionChange = (selectedItem: OptionItem) => {
    const tempArray = [...filtersArray];
    if (tempArray[id]) {
      if (isMonthSelected) {
        const lastSlashIndex = tempArray[id]?.value?.lastIndexOf("/") as number;
        console.log("last index: ", lastSlashIndex);
        // if (lastSlashIndex === -1) {
        //   tempArray[id].value += selectedItem?.name;
        // }
        tempArray[id].value =
          tempArray[id]?.value?.toString()?.substring(0, lastSlashIndex + 1) +
          selectedItem?.name;
      } else tempArray[id].value = selectedItem?.name;
      setFiltersArray(tempArray);
    }
  };

  useEffect(() => {
    console.log("is month changed so checking the val of option: ", option);
    setOption(undefined);

    const tempVal = filtersArray[id]?.value as string;
    if (
      filtersArray[id]?.value &&
      filtersArray[id]?.filter === "By Month And Year"
    ) {
      // const tempArray = [...filtersArray];
      // tempArray[id].value = undefined;
      // setFiltersArray(tempArray);
      setOption({ name: tempVal.split("/")[1] });
    } else if (
      filtersArray[id]?.value &&
      !filtersArray[id]?.value?.includes("/") &&
      filtersArray[id]?.filter === "By Year"
    ) {
      setOption({ name: tempVal });
    }
  }, [isMonthChanged, filtersArray[id]?.value]);

  return (
    <Dropdown
      value={option}
      onChange={setOption}
      //   disabled={
      //     filtersArray[id]?.option === "" || filtersArray[id]?.filter === ""
      //       ? true
      //       : false
      //   }
      className={`${
        isMonthSelected && filtersArray[id]?.value === "" ? " opacity-50" : ""
      }  `}
      disabled={isMonthSelected && filtersArray[id]?.value === ""}
    >
      <Dropdown.Trigger className=" w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]">
        {option?.name || "Select Year..."}
        <i>
          <ControlsChevronDown className="text-moon-18" />
        </i>
      </Dropdown.Trigger>

      <Dropdown.Options className="h-[200px] overflow-y-auto">
        {year?.map((year, index) => (
          <Dropdown.Option value={year} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                onClick={() => handleOptionChange(year)}
              >
                {year?.name}
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default FilterYear;
