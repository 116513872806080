import React, { useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { getFilterOption } from "../../../utils/branch-admin/getFilterOptions";
import { useEffect } from "react";
interface OptionItem {
  name: string; // Replace with actual type
}
interface DropDownProps {
  id: number;
  type: string;
  data: OptionItem[];
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersArray: IFilterParameters[]) => void;
  setSelectedFilter: (filt: string) => void;
  setSelectedOption: (Opt: string) => void;
  handleFilterValue: (
    id: number,
    value: string | Date | string[],
    isDate?: boolean,
    isRangeDate?: boolean
  ) => void;
  setStartDate?: (date: Date | undefined) => void;
}

const DropDownFilter: React.FC<DropDownProps> = (props) => {
  // const initialOption: string =
  //   props.type === "option" && props?.filtersArray[props?.id]?.option
  //     ? (props?.filtersArray[props?.id]?.option as string)
  //     : props.type === "filter" && props?.filtersArray[props?.id]?.filter
  //     ? (props?.filtersArray[props?.id]?.filter as string)
  //     : "Select an Option";
  const [option, setOption] = useState<string>("");
  const [data, setData] = useState<OptionItem[]>(props?.data);

  useEffect(() => {
    const filterOptions = getFilterOption(
      props?.filtersArray[props?.id]?.option as string
    );
    //to filter
    // const selectedFilters = props.filtersArray?.map((filter) => {
    //   return filter.filter;
    // });
    // filterOptions = filterOptions?.filter((opt) => {
    //   return !selectedFilters.includes(opt.name);
    // });
    console.log("option: ", option);

    if (props?.type === "option") {
      setOption(
        props.filtersArray[props.id]?.option
          ? (props.filtersArray[props.id]?.option as string)
          : "Select an Option"
      );
      setData(props?.data);
    } else if (props?.type === "filter") {
      setOption(
        props.filtersArray[props.id]?.filter
          ? (props.filtersArray[props.id]?.filter as string)
          : "Select an Option"
      );
      setData(filterOptions);
      //   handleOptionChange(filterOptions[0]);
    }
  }, [
    props?.type,
    props?.filtersArray[props?.id]?.option,
    props?.data,
    // props.filtersArray.length,
    // props.data,
  ]);

  const handleOptionChange = (selectedItem: OptionItem) => {
    // props.handleFilters(selectedItem.name)
    setOption(selectedItem?.name);
    const tempArray = [...props?.filtersArray];
    if (props?.type === "option" && tempArray[props?.id]) {
      props?.setSelectedOption(selectedItem?.name);

      const tempArrayData = [...props.filtersArray];
      tempArrayData[props.id].filter = "";
      props.setFiltersArray(tempArrayData);
      props.handleFilterValue(props.id, "");
      props?.setSelectedFilter("Select an Option");

      // props.setFilterOpt({...props.filterOpt,option:selectedItem?.name});
      tempArray[props?.id].option = selectedItem?.name;
    } else if (props?.type === "filter" && tempArray[props?.id]) {
      props?.setStartDate && props?.setStartDate(undefined);
      props?.handleFilterValue(props.id, "");
      props?.setSelectedFilter(selectedItem?.name);
      // props.setFilterOpt({...props.filterOpt,filter:selectedItem?.name});
      tempArray[props?.id].filter = selectedItem?.name;
    }
    props?.setFiltersArray(tempArray);
  };

  const isDisable =
    props?.type === "filter" && props?.filtersArray[props?.id]?.option === ""
      ? true
      : false;

  return (
    <Dropdown
      className={isDisable ? "disable-opacity" : ""}
      value={option}
      onChange={(item: OptionItem) => setOption(item?.name)}
      disabled={isDisable ? true : false}
    >
      <Dropdown.Trigger className=" w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]">
        {option}
        <i>
          <ControlsChevronDown className="text-moon-18" />
        </i>
      </Dropdown.Trigger>

      <Dropdown.Options className="max-h-[150px] overflow-y-auto min-h-[50px]">
        {data?.length > 0 ? (
          data?.map((item, index) => (
            <Dropdown.Option value={item} key={index}>
              {({ selected, active }) => (
                <MenuItem
                  isActive={active}
                  isSelected={selected}
                  onClick={() => handleOptionChange(item)}
                  className={`${
                    selected || active
                      ? "bg-goku text-cyan-500"
                      : "text-gray-900"
                  } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                >
                  {item.name}
                </MenuItem>
              )}
            </Dropdown.Option>
          ))
        ) : (
          <MenuItem className="flex justify-center text-center text-gray-400">
            No filter available
          </MenuItem>
        )}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default DropDownFilter;
