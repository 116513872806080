import { useEffect, useState } from "react";
import HeaderSetting from "../../../../Components/super-admin/ui/HeaderSetting";
import SearchField from "../../../../Components/super-admin/ui/SearchField";
import ModalAddRole from "./widgets/ModalAddRole";
import HospitalListing from "./widgets/HospitalListing";
import AddBranch from "./widgets/AddBranch";
import AddSectionDrawer from "./widgets/AddSectionDrawer";
import {
  activateHospital,
  deActivateHospital,
  editHospital,
  getAllHospitalsWithBranches,
} from "../../../../services/super-admin-services/hospital.service";
import {
  addBranchHospital,
  getHospitalBranches,
  getSections,
  editBranchHospital,
  getSectionsSelected,
  addSection,
  activateBranch,
  deActivateBranch,
  activateSection,
  deActivateSection,
  editSection,
} from "../../../../services/super-admin-services/branch.service";
import { IBranchData } from "../../../../types/super-admin-interfaces/IBranchData.interface";
import { IHospital } from "../../../../types/super-admin-interfaces/IHospital.interface";
import { IBranch } from "../../../../types/super-admin-interfaces/IBranch.interface";
import { Input } from "@heathmont/moon-core-tw";
import ConfirmationModal from "./widgets/ConfirmationModal";
import { GenericSearch } from "@heathmont/moon-icons-tw";
import * as toast from "../../../../utils/toast";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../features/ToastSlice/toastSlice";
import { store } from "../../../../store/store";
interface IHospitalData {
  hospitals: IHospital[];
}

const HospitalSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenModal, setIsOpenBranch] = useState(false);
  const [hospitalData, setHospitalData] = useState<IHospitalData>();
  const [hospitalId, setHospitalId] = useState<IHospital>();
  const [hospitalBranchesData, setHospitalBranchesData] = useState<IBranch[]>();
  const [editBranchData, setEditBranchData] = useState<IBranchData>();
  const [fetchData, setFetchdata] = useState<any>({ load: "" });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredHospitalData, setFilteredHospitalData] =
    useState<IHospitalData>();
  const [getSectionData, setSectionData] = useState<string[]>();
  const [editData, setEditData] = useState<any>();
  const [hospitalID, setHospitalID] = useState<string>();
  const [sectionList, setSectionList] = useState<any>();
  const [branchData, setBranchData] = useState<any>();

  const closeModal = () => setIsOpen(false);
  const onEditBranch = (data: IBranchData) => {
    console.log("Edit Dta", data);
    setEditBranchData({ ...data });
    console.log("Branch data", editBranchData);
  };
  const onAddHospital = () => {
    const data = fetchData.load;
    setFetchdata({ ...data });
  };
  const handleModal = (data?: any) => {
    console.log("Data", data);
    setEditData(data);
    setIsOpen(true);
  };
  const closeModalBranch = () => setIsOpenBranch(false);
  const openBranch = () => {
    setIsOpenBranch(true);
  };
  const handleClick = () => setIsOpenDrawer(true);
  const closeDrawerModal = () => {
    setIsOpenDrawer(false);
  };

  const getAllHospitalData = async () => {
    try {
      const res: any = await getAllHospitalsWithBranches();
      console.log("Data", res?.res);
      if (res?.res) {
        setHospitalData({} as IHospitalData);
        setHospitalData(res?.res);
      }
    } catch (error) {
      console.error("Error fetching hospital data:", error);
    }
  };

  const filterHospitalData = () => {
    if (searchQuery.trim() === "") {
      setFilteredHospitalData(hospitalData);
    } else {
      const filteredData = hospitalData?.hospitals.filter(
        (hospital: IHospital) =>
          hospital.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredHospitalData({ hospitals: filteredData as IHospital[] });
    }
  };

  const addHospitalBranch = async (params: any) => {
    console.log("Hospital", hospitalId);
    console.log("Params", params);
    if (hospitalId && params) {
      const response: any = await addBranchHospital(hospitalId._id, params);
      if (response?.res) {
        onAddHospital();
        getHospitalBranchData();
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Branch added sucessfully"));
      } else if(response.err.error) {
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage(response.err.error));     
       }
    }
  };

  const editHospitalBranch = async (branchId?: string, params?: any) => {
    const formData: any = {
      branchName: params?.branchName,
      contact: params?.contact,
      address: params.address,
      phoneNumber: params.phoneNumber,
    };
    const response: any = await editBranchHospital(branchId, formData);
    if (response?.res) {
      onAddHospital();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Data updated sucessfully"));
      getHospitalBranchData();
    } else if(response.err.error) {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage(response.err.error));     
     }
  };

  const getHospitalId = (data: IHospital) => {
    setHospitalId(data);
  };

  const getHospitalBranchData = async () => {
    if (hospitalId && hospitalId?.numberOfBranches !== 0) {
      setHospitalBranchesData(undefined);
      const res: any = await getHospitalBranches(hospitalId?._id);
      console.log("Res,res", res);
      if (res?.res) {
        setHospitalBranchesData(res?.res?.branches);
      }
    }
  };

  // const deleteHospitalBranch = async (branchId: string) => {
  //   const res = await deleteBranch(branchId);
  //   if (res?.res) {
  //     getHospitalBranchData();
  //   }
  // };
  const activateHospitalBranch = async (branchId: string) => {
    const res = await activateBranch(branchId);
    if (res?.res) {
      getHospitalBranchData();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Branch activated successfully"));
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  };

  const deActivateHospitalBranch = async (branchId: string) => {
    const res = await deActivateBranch(branchId);
    if (res?.res) {
      getHospitalBranchData();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Branch blocked successfully"));
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  };

  const deActivateHospitalData = async (hospitalId?: any) => {
    const res: any = await deActivateHospital(hospitalId);
    if (res?.res) {
      // onAddHospital();
      getAllHospitalData();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Hospital blocked successfully"));

      setIsOpenConfirm(false);
      return;
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  };

  const activateHospitalData = async (hospitalId?: any) => {
    const res: any = await activateHospital(hospitalId);
    if (res?.res) {
      getAllHospitalData();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Hospital activated successfully"));

      setIsOpenConfirm(false);
      return;
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Please try again"));
    }
  };

  const editHospitalData = async (params?: any) => {
    console.log("params", params);
    console.log("HospitalID", params, hospitalID);
    const urlPattern = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    const containsURL = urlPattern.test(params.logo);
    if (containsURL) {
      params = { name: params.name };
      const res: any = await editHospital(hospitalID, params);
      if (res?.res) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Data updated sucessfully"));
        getAllHospitalData();
        return;
      } else {
        toast.error("Error");
      }
    } else {
      const res: any = await editHospital(hospitalID, params);
      if (res?.res) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Data updated successfully"));
        getAllHospitalData();
        return;
      } else {
        toast.error("Error");
      }
    }
  };

  const getHospitalID = (data: string) => {
    setHospitalID(data);
  };

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeConfirmModal = () => setIsOpenConfirm(false);
  const deleteHospital = () => setIsOpenConfirm(true);

  const getAllSectionsList = async (branchId: string) => {
    const res: any = await getSections(branchId);
    console.log("REs", res);
    if (res?.res) {
      setSectionData(res?.res?.sections);
      getAllHospitalData();
    }
  };

  const addSectionsList = async (branchId?: string, section?: any) => {
    console.log("Branch ID", branchId);
    if (branchId) {
      const sections = {
        sections: section,
      };
      if (section.length !== 0) {
        const res: any = await addSection(
          hospitalId?._id as string,
          branchId,
          sections,
        );
        if (res?.res) {
          store.dispatch(setSnackBar("success"));
          store.dispatch(setToastMessage("Section added successfully"));
          getSectionsList(branchId);
          getHospitalBranchData();
        } else {
          store.dispatch(setSnackBar("error"));
          store.dispatch(setToastMessage("Please try again"));
        }
      } else {
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage("Please try again"));
      }
    }
  };

  const getSectionsList = async (branchId: string) => {
    setSectionList("");
    if (branchId) {
      const res: any = await getSectionsSelected(
        hospitalId?._id as string,
        branchId,
      );
      if (res?.res) {
        // setSectionList(res?.res?.sections);
        setSectionList([...res?.res?.sections]);
        console.log("Selection", sectionList);
      }
    }
  };

  // const deleteHospitalSection = async (
  //   branchId?: string,
  //   sectionId?: string,
  // ) => {
  //   const res = await deleteSection(
  //     hospitalId?._id as string,
  //     branchId,
  //     sectionId,
  //   );
  //   if (res?.res) {
  //     toast.success("Section Deleted Successfully");
  //     getHospitalBranchData();
  //     getSectionsList(branchId as string);
  //   }
  // };

  const activateHospitalSection = async (
    sectionId?: string,
    branchId?: string,
  ) => {
    const res: any = await activateSection(sectionId);
    // if (res?.res) {
    store.dispatch(setSnackBar("success"));
    store.dispatch(setToastMessage("Section activated sucessfully"));
    getHospitalBranchData();
    getSectionsList(branchId as string);
    // }
  };

  const deActivateHospitalSection = async (
    branchId?: string,
    sectionId?: string,
  ) => {
    const res = await deActivateSection(sectionId);
    if (res?.res) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Section blocked sucessfully"));
      getHospitalBranchData();
      getSectionsList(branchId as string);
    }
  };

  const editHospitalSection = async (
    branchId?: string,
    sectionId?: string,
    sectionName?: any,
  ) => {
    const res = await editSection(
      hospitalId?._id as string,
      branchId,
      sectionId,
      sectionName,
    );
    if (res?.res) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Section data updated sucessfully"));
      getHospitalBranchData();
      getSectionsList(branchId as string);
    }
  };
  useEffect(() => {
    getHospitalBranchData();
  }, [hospitalId]);

  useEffect(() => {
    filterHospitalData();
  }, [searchQuery, hospitalData]);

  useEffect(() => {
    getAllHospitalData();
  }, [fetchData]);

  // useEffect(() => {}, [getSectionData, branchId]);
  return (
    <>
      <div className="bg-slate-100 min-h-full  relative z-[0] w-4/5">
        <div className=" w-full">
          <HeaderSetting
            title="Manage Hospitals"
            leadText="Add hospitals and branches below"
            buttonText="Add Hospital"
            handleModal={handleModal}
          />
          <div className="px-8 pb-4">
            <div className="flex-1 relative">
              <Input
                className="ps-12"
                type="search"
                placeholder="Search Hospitals"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="searchicon">
                <GenericSearch className="w-6 h-6" />
              </div>
            </div>{" "}
          </div>
          <div className="px-8">
            <HospitalListing
              openBranch={openBranch}
              handleClick={handleClick}
              hospitalData={filteredHospitalData}
              getHospitalId={getHospitalId}
              handleModal={handleModal}
              deActivateHospitalData={deActivateHospitalData}
              getHospitalID={getHospitalID}
              activateHospitalData={activateHospitalData}
            />
          </div>
        </div>
      </div>
      <ModalAddRole
        editData={editData}
        isOpen={isOpen}
        closeModal={closeModal}
        fetchData={onAddHospital}
        editHospitalData={editHospitalData}
      />
      <AddBranch
        isOpenModal={isOpenModal}
        closeModalBranch={closeModalBranch}
        postBranchData={addHospitalBranch}
      />
      <AddSectionDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        closeDrawer={closeDrawerModal}
        hospitalData={hospitalId as IHospital}
        addHospitalBranch={addHospitalBranch}
        hospitalBranchesData={hospitalBranchesData}
        editBranchData={onEditBranch}
        editHospitalBranch={editHospitalBranch}
        activateHospitalBranch={activateHospitalBranch}
        deActivateHospitalBranch={deActivateHospitalBranch}
        setBranchData={setBranchData}
        getSectionsList={getSectionsList}
        sectionsList={sectionList as any}
        branchData={branchData}
        addSectionsList={addSectionsList}
        getAllSectionsList={getAllSectionsList}
        getSectionData={getSectionData as string[]}
        deActivateHospitalSection={deActivateHospitalSection}
        activateHospitalSection={activateHospitalSection}
        editHospitalSection={editHospitalSection}
      />
    </>
  );
};

export default HospitalSettings;
