import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface Props {
  month: number;
  year: string;
}
const MonthDays: React.FC<Props> = ({ month, year }) => {
  const { date } = useSelector((state: RootState) => state.time);
  const currentMonth = moment().month();
  console.log("month year check: ", month + "\nadsf: ", year);
  console.log("Current Month", currentMonth);


  const renderCalendarDates = () => {
    const selectedMonthIndex = moment()
      .year(+year)
      .month(month - 1)
      .startOf("month");
    const daysInMonth = moment(selectedMonthIndex).endOf("month").date();

    const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
    const calendarDates = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDay = selectedMonthIndex.clone().date(day); // Get the full moment date object for this day
      const weekDay = currentDay.day(); // 0 for Sunday, 6 for Saturday
      const isWeekend = weekDay === 0 || weekDay === 6; // Check if it's a weekend
      const weekName = weekDays[(selectedMonthIndex.day() + day - 1) % 7];

      const isCurrentDay =
        moment().date() === day && currentMonth === month - 1;

      // Apply specific styling for current day and weekend
      const dateClassName = isCurrentDay
        ? "bg-blackcst text-white"
        : isWeekend
        ? "bg-gray-400 text-white" // Custom style for weekends
        : "";

      calendarDates.push(
        <div
          key={day}
          className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center ${dateClassName}`}
        >
          <div className="text-moon-14 font-semibold transition-colors">
            {weekName}
          </div>
        </div>
      );
    }

    return calendarDates;
  };

  return <div className="flex flex-wrap w-full">{renderCalendarDates()}</div>;
};

export default MonthDays;
