import React, { useState } from "react";
import { Input } from "@heathmont/moon-core-tw";
import { GenericSearch } from "@heathmont/moon-icons-tw";
interface Section {
  name: string;
}
interface DateRangeState {
  startDate: any;
  endDate: any;
  placeholder: string;
}
interface Props {
  setSearchQuery: (data: string) => void;
  employeeCount: number;
}
const FilterEmployee: React.FC<Props> = ({ setSearchQuery, employeeCount }) => {
  const [value, setValue] = useState<DateRangeState>({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
    placeholder: "Select date range",
  });

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  const section: Section[] = [
    { name: "Orthopedics" },
    { name: "ICU" },
    { name: "Pedriatics" },
    { name: "CCU" },
    { name: "Gynachology" },
  ];

  return (
    <div className="p-[20px_30px]">
      <div className="flex flex-wrap gap-[15px] items-center">
        <div className="flex-1 flex items-center  gap-[15px]">
          <div className="relative xl:max-w-sm lg:max-w-lg flex-1">
            {/* <Datepicker
              value={value}
              primaryColor={"cyan"}
              onChange={handleValueChange}
            /> */}
            <Input
              type="text"
              className="text-moon-14 text-trunks ps-9 z-0"
              placeholder="Search Employee"
              // value={searchTerm}
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
            <div className="emloyeserchicon">
              <GenericSearch className="w-6 h-6" />
            </div>
          </div>
          <div className="xl:max-w-[272px] flex-1">
            {/* <DropDown option="Section" data={section} /> */}
          </div>
        </div>
        <div>
          <p className="text-moon-14 text-gray-600">
            <span>{employeeCount ?? 0} </span> Employees Available
          </p>
        </div>
      </div>
    </div>
  );
};

export default FilterEmployee;
