import { api } from "../../utils/axios";
import { IHospitalInfo } from "../../types/super-admin-interfaces/IHospitalInfo.interface";

export const setHospital = async (params: IHospitalInfo) => {
  try {
    console.log("Params", params);
    const { data, ok } = await api.post(`hospital/addHospital`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getAllHospitalsWithBranches = async () => {
  try {
    const { data, ok } = await api.get(`hospital/getAllHospitalsWithBranches`);
    console.log("data", data);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    console.log("Error", error);
    return { res: null, err: error };
  }
};

export const editHospital = async (hospitalId: any, param: IHospitalInfo) => {
  try {
    const { data, ok } = await api.post(
      `hospital/editHospital/${hospitalId}`,
      param,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { err: error };
  }
};

export const getHospitalData = async (hospitalId: string) => {
  try {
    // console.log(,hospitalId);
    const { data, ok } = await api.get(`hospital/getHospital/${hospitalId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { err: error };
  }
};

export const deActivateHospital = async (hospitalId: any) => {
  try {
    const { data, ok } = await api.post(
      `hospital/deActivateHospital/${hospitalId}`,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const activateHospital = async (hospitalId: any) => {
  try {
    const { data, ok } = await api.post(
      `hospital/activateHospital/${hospitalId}`,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// {{healthcareLocal}}/hospital/activateHospital/64b7c5864306689b3629cb33
// https://dev.api.healthcare.aquila360.com/hospital/editHospital/:hospitalId
