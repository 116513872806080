import React from "react";
import { Modal, Button, IconButton } from "@heathmont/moon-core-tw";
import { ControlsClose } from "@heathmont/moon-icons";

const ConfirmationModal = ({
  isOpenConfirm,
  closeConfirmModal,
  confirmationData,
  onConfirm,
}: any) => {
  const { title, message, confirmAction } = confirmationData || {};

  return (
    <Modal open={isOpenConfirm} onClose={closeConfirmModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[560px]">
        <div className="p-4 border-b-2 border-beerus flex items-center gap-4 justify-between">
          <h3 className="text-moon-18 text-bulma font-medium">{title}</h3>
          <IconButton
            onClick={closeConfirmModal}
            variant="ghost"
            icon={<ControlsClose />}
          />
        </div>
        {confirmationData && (
          <div className="p-4 py-6 text-center">
            {message}
            {/* <p className="text-moon-18 mb-[28px]">{message}</p> */}
            <p className="text-moon-18">{`Are you sure you want to ${confirmAction.toLowerCase()}?`}</p>
          </div>
        )}

        <div className="p-4 border-t-2 border-beerus flex items-center justify-end gap-2">
          <Button variant="secondary" onClick={closeConfirmModal}>
            No, Don’t
          </Button>
          {onConfirm && (
            <Button
              variant="tertiary"
              className="bg-hit"
              onClick={() => {
                onConfirm();
                closeConfirmModal();
              }}
            >
              {`Yes, ${confirmAction}`}
            </Button>
          )}
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default ConfirmationModal;
