import React, { useEffect, useState } from "react";
import { getPatientsView } from "../../../services/branch-admin-services/views.service";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IPatient } from "../../../types/branch-admin-interfaces/IPatients.interface";
const columnHelper = createColumnHelper<IPatient>();
const columns = [
  columnHelper.accessor("patientId", {
    header: () => <span>ID</span>,
    cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
  }),
  columnHelper.accessor((row) => row.patientName, {
    id: "patientName",
    header: () => <span>Patient Name</span>,
    cell: (info) => (
      <span className="truncate max-w-[200px]">{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor("admissionDate", {
    header: () => "Admission",
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("dischargeDate", {
    header: () => <span>Discharge</span>,
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("allocatedSection", {
    header: "allocatedSection",
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("assignedRoom", {
    header: "Assigned Room",
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("referredDoctor", {
    header: "Referred Doctor",
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("needyProfile", {
    header: "Needy Profile",
    cell: (info) => <span className="text-gray-400">{info.renderValue()}</span>,
  }),
  columnHelper.accessor("status", {
    header: "status",
    cell: (info) => (
      <span className="bg-[rgba(36,156,186,0.12)] text-[#249CBA] rounded-moon-i-sm px-3 py-1">
        {info.renderValue()}
      </span>
    ),
  }),
];
const SwapRequest = () => {
  const [data, setData] = React.useState(() => []);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const patientsData = async () => {
    // setIsLoading(true);
    // const res = await getEmployeesView(params);
    // setIsLoading(false);
    // setData(res?.records);
  };

  useEffect(() => {
    patientsData();
  }, []);

  return (
    <div>
      {/* {!isLoading && (
        <Table
          table={table}
          tableData={employeesViewRes as ITableDataResponse}
          handlePageChange={getEmployeesData}
        />
      )} */}

      <div className="sectionsvg flex items-center justify-center text-center">
        {/* You can render a loading indicator or an error message here */}
        {/* Loading or No Data Available */}
        <img
          src={"images/no-schedule-to-approve.svg"}
          alt="No schedule to approve"
        />
      </div>
    </div>
  );
};

export default SwapRequest;
