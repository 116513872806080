import { Avatar, Button } from "@heathmont/moon-core-tw";
import { NotificationsBellRinging } from "@heathmont/moon-icons-tw";
import React from "react";
import DropDown from "../../../UI/DropDown";

const department = [{ name: "ICU" }];

const RequirementCard = () => {
  return (
    <>
      <div className="p-4 border-b border-beerus">
        <h3 className="text-moon-18 text-bulma font-semibold">
          Need Requirement
        </h3>
      </div>
      <div className="p-[12px_24px]">
        <div className="py-[10px] flex items-center gap-[22px]">
          <span className="inline-flex items-center justify-center h-[64px] w-[64px] rounded-full bg-pink-50">
            <NotificationsBellRinging className="text-moon-48 text-pink-600" />
          </span>
          <div className="flex-1">
            <h3 className="text-pink-600 font-semibold">Need</h3>
            <p className="text-gray-400 text-moon-16">
              Fill requirements for quick response
            </p>
          </div>
        </div>
        <div className="py-[10px]">
          <h4 className="text-gray-400 text-moon-16 font-normal">Request</h4>
          <p className="text-bulma text-moon-16 ">
            We urgently require 4 nurses at ICU immediately
          </p>
        </div>
        <div className="grid grid-cols-2 gap-[15px]">
          <div className="py-[10px]">
            <h4 className="text-gray-400 text-moon-16 font-normal">Time</h4>
            <p className="text-bulma text-moon-16  font-semibold">10:30 AM</p>
          </div>
          <div className="py-[10px]">
            <h4 className="text-gray-400 text-moon-16 font-normal">
              Requested
            </h4>
            <p className="text-bulma text-moon-16 font-semibold">6</p>
          </div>
        </div>
        <div className="py-[10px]">
          <h4 className="text-gray-400 text-moon-16 font-normal">
            Requested by
          </h4>
          <div className="flex items-center gap-[10px] mt-[10px]">
            <span className="inline-flex rounded-full shadow-md ">
              <Avatar
                size="lg"
                className="rounded-full border-2 overflow-hidden"
                imageUrl="images/Avatar.png"
              ></Avatar>
            </span>
            <div className="flex-1">
              <h4 className="text-bulma text-moon-16 font-semibold">
                Vinese Chan
              </h4>
              <p className="text-gray-400 text-moon-14 font-normal">Nurse</p>
            </div>
          </div>
        </div>

        <div className="py-[10px]">
          {" "}
          <h4 className="text-gray-400 text-moon-16 font-normal">
            Hospital Section
          </h4>
          {/* <DropDown option="ICU" data={department} /> */}
        </div>
      </div>
      <div className="p-[12px_24px] flex items-center justify-end bg-goku gap-[8px]">
        <Button variant="secondary">Cancel</Button>
        <Button
          variant="ghost"
          className="bg-pink-600 text-white hover:text-white"
        >
          {" "}
          Submit
        </Button>
      </div>
    </>
  );
};

export default RequirementCard;
