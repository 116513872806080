import { configureStore } from "@reduxjs/toolkit";
import timeReducer from "../features/nurse-manager/Time Slice/timeSlice";
import sectionReducer from "../features/nurse-manager/Section Slice/sectionSlice";
import requestReducer from "../features/nurse-manager/Request Slice/requestSlice";
import authReducer from "../features/Auth Slice/authSlice";
import weekFilterReducer from "../features/nurse-manager/Week Filter/weekSlice";
import toastReducer from "../features/ToastSlice/toastSlice";
import notificationReducer from "../features/Notification Slice/notificationSlice";
export const store = configureStore({
  reducer: {
    time: timeReducer,
    section: sectionReducer,
    request: requestReducer,
    auth: authReducer,
    weekFilter: weekFilterReducer,
    toast: toastReducer,
    notification: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
