import React, { useState } from "react";
import { Avatar, Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { GenericLogOut } from "@heathmont/moon-icons";
import { removeUser } from "../../../../../../features/Auth Slice/authSlice";
import {
  getBorderColor,
  getTextColor,
} from "../../../../../Colors/colorSetting";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../../../services/auth.service";
import { capitalizeWords } from "../../../../../../utils/capitalizeWords";
const ProfileButton = ({ value, role, imageUrl, roles }: any) => {
  const [option, setOption] = useState({ name: value });
  const borderColor = getBorderColor(roles);
  const textColor = getTextColor(roles);
  const logOut = () => {
    logout();
  };
  const navigate = useNavigate();
  return (
    <>
      <Dropdown value={option} onChange={setOption}>
        <Dropdown.Trigger
          className={`flex items-center font-normal  w-auto text-white
                         py-0.5 px-1.5 rounded-xl cursor-pointer capitalize`}
        >
          <img className="me-1.5" src="/images/Avatar.png" alt="img" /> {value}
        </Dropdown.Trigger>

        <Dropdown.Options className="min-w-[200px] max-w-[200px]">
          <div className="text-center p-3">
            <Avatar
              className={`rounded-full border-2 inline-block imgAvatarRounded mb-1 ${borderColor}`}
              imageUrl={imageUrl}
              size="xl"
            />
            <h3 className="text-moon-14 capitalize">{value}</h3>
            <p className={`text-moon-12 ${textColor}`}>
              {capitalizeWords(role)}
            </p>
          </div>
          <hr className="my-1" />
          <Dropdown.Option>
            <div>
              {/* <Link to="/profile"> */}
              <MenuItem
                className="font-normal text-gray-400 text-moon-12"
                onClick={() => {
                  navigate("/profile");
                  console.log("HERE");
                }}
              >
                Profile
              </MenuItem>
              {/* </Link> */}
              <MenuItem
                className="font-normal text-gray-400 text-moon-12"
                onClick={() => {
                  navigate("/preferences");
                  console.log("HERE");
                }}
              >
                Preferences
              </MenuItem>
              <hr className="my-1" />
              <MenuItem
                className="font-normal text-moon-12 justify-start"
                onClick={logOut}
              >
                <GenericLogOut /> {""} Log out
              </MenuItem>
            </div>
          </Dropdown.Option>
        </Dropdown.Options>
      </Dropdown>
    </>
  );
};

export default ProfileButton;
