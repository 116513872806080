import { useState } from "react";
import { Modal, Button, IconButton } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";
import { IEmployees } from "../../types/branch-admin-interfaces/IEmployees.interface";

interface Props {
  isOpenConfirmation: boolean;
  setIsOpenConfirmation: (check: boolean) => void;
  message: string;
  actionFunction: (data: any) => void;
  sendBackParam: string | IEmployees;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpenConfirmation,
  setIsOpenConfirmation,
  message,
  actionFunction,
  sendBackParam,
}) => {
  const closeConfirmationModal = () => setIsOpenConfirmation(false);
  const openConfirmationModal = () => setIsOpenConfirmation(true);
  return (
    <>
      {/* <Button onClick={openModal}>Open dialog</Button> */}
      <Modal open={isOpenConfirmation} onClose={closeConfirmationModal}>
        <Modal.Backdrop />
        <Modal.Panel className="lg:max-w-md text-goten rounded-none">
          {/* <IconButton
            variant="ghost"
            size="sm"
            className="absolute top-4 end-5 text-goten"
            onClick={closeModal}
          >
            <ControlsCloseSmall className="text-moon-24" />
          </IconButton> */}
          <div className=" p-4 pt-11">
            <div className="mt-2">
              <h3 className="text-moon-24 text-black font-medium text-center">
                {message}
              </h3>
            </div>
          </div>
          <div className="p-4 flex items-center justify-center gap-1">
            <Button
              variant="ghost"
              className="border"
              onClick={() => {
                closeConfirmationModal();
              }}
            >
              No
            </Button>
            <Button variant="primary" className="bg-hit"
              onClick={() => {
                actionFunction(sendBackParam);
                closeConfirmationModal();
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
