import React, { useEffect, useState } from "react";
import { getLeavesView } from "../../../services/branch-admin-services/views.service";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "../../../Components/branch-admin/ViewTable/table";
import { ILeaves } from "../../../types/branch-admin-interfaces/ILeaves.interface";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { GenericDelete, GenericEdit } from "@heathmont/moon-icons-tw";
import { IconButton } from "@heathmont/moon-core-tw";
import ConfirmationModal from "../../super-admin/settings/hospital/widgets/ConfirmationModal";
import { deleteLeaves } from "../../../services/branch-admin-services/admin.service";
import { store } from "../../../store/store";
import { setSnackBar, setToastMessage } from "../../../features/ToastSlice/toastSlice";
interface Props {
  dataImported: boolean;
  searchQuery: string;
  filtersArray: IFilterParameters[];
  isApplyFilter: boolean;
}
const LeavesView: React.FC<Props> = ({
  dataImported,
  searchQuery,
  filtersArray,
  isApplyFilter,
}) => {
  const columnHelper = createColumnHelper<ILeaves>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const columns = [
    columnHelper.accessor("employeeId", {
      header: () => <span>ID</span>,
      cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.employeeName, {
      id: "employeeName",
      header: () => <span>Employee Name</span>,
      cell: (info) => (
        <span className="truncate max-w-[200px]">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor("employeeSection", {
      enableSorting: false,

      header: () => "Employee Section",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("leaveType", {
      enableSorting: false,

      header: () => <span>Leave type</span>,
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("status", {
      enableSorting: false,

      header: "Status",
      cell: (info) => (
        <span
          className={
            info.renderValue() === "Pending"
              ? `bg-[rgba(36,156,186,0.12)] text-[#249CBA] rounded-moon-i-sm px-3 py-1`
              : info.renderValue() === "Declined"
              ? `bg-dodoria-10 text-dodoria rounded-moon-i-sm px-3 py-1`
              : `bg-[#4AD15F]/[0.2] text-[#4AD15F] rounded-moon-i-sm px-3 py-1`
          }
        >
          {info.renderValue()}
        </span>
      ),
    }),
    columnHelper.accessor((row) => row.from.date, {
      enableSorting: false,

      header: "From (Date)",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor((row) => row.to.date, {
      enableSorting: false,

      header: "To (Date)",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor((row) => row.employeeId, {
      enableSorting: false,

      header: "Action",
      cell: (info) => (
      <IconButton
        variant="ghost"
        icon={<GenericDelete />}
        onClick={() => {
          openConfirmationModal({
            title: "Confirm Delete Leaves",
            actionFor: "",
            message: (
              <p className="text-moon-18 mb-[28px]">
                {" "}
                The Leaves of {" "}
                <strong className="font-semibold ">{info.row.original?.employeeName}</strong>
                <br />
                will be deleted
              </p>
            ),
            confirmAction: "Delete",
            id: {
              _id:info.row.original._id
            }
          });
        }}
        />
      ),
    }),
  ];

  const handleConfirmAction = async() => {
    const res:any = await deleteLeaves(confirmationData?.id?._id);
      if(!res?.err){
        store.dispatch(setSnackBar("success"));
        store.dispatch(
          setToastMessage("Leave deleted successfuly!")
        );
        await getLeavesData(1);   
      }else{
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage("An error occured. Try again later."));
      }
    setIsOpenConfirm(false);
  };
  const [data, setData] = React.useState(() => []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leavesViewRes, setLeavesViewRes] = useState<ITableDataResponse>();
  let timeoutId: NodeJS.Timeout | null = null;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const getLeavesData = async (pageChange: number, searchQuery?: string) => {
    const params = {
      pageChange: pageChange,
      limit: 50,
      search: searchQuery,
      filterParams: filtersArray,
    };

    setIsLoading(true);
    const res = await getLeavesView(params);
    setIsLoading(false);
    setData(res?.records);
    setLeavesViewRes(res);
  };

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getLeavesData(1, searchQuery);
      }, 1000);
    } else {
      getLeavesData(1);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dataImported, searchQuery, isApplyFilter]);

  const openConfirmationModal = (info:any) => {
    setConfirmationData(info);
    setIsOpenConfirm(true);
  };

  return (
    <div>
      {!isLoading && (
        <Table
          table={table}
          tableData={leavesViewRes as ITableDataResponse}
          handlePageChange={getLeavesData}
        />
      )}
      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </div>
  );
};

export default LeavesView;
