import React, { useState } from "react";

import {
  ControlsChevronRight,
  ControlsChevronLeft,
} from "@heathmont/moon-icons-tw";
import { IconButton } from "@heathmont/moon-core-tw";
// import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import moment from "moment";

interface Request {
  date: number;
  status: "day off" | "approved"; // Assuming "day off" and "approved" are the possible values for the status property
}

interface props {
  requests: Request;
}
const RequestCalendar = ({
  requests,
  dataOfDate,
  data,
  selectedMonthOption,
}: any) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // const getCurrentMonthDays = (currentMonth: any, currentYear: any) => {
  //   const firstDayOfMonth = new Date(currentYear, currentMonth);
  //   const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  //   const startDayOfWeek = firstDayOfMonth.getDay();
  //   console.log("first day");
  //   const days = [];
  //   const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  //   console.log("PRev", prevMonth);
  //   const prevMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  //   const prevMonthLastDay = new Date(
  //     prevMonthYear,
  //     prevMonth + 1,
  //     0,
  //   ).getDate();

  //   // Add days from previous month
  //   for (
  //     let i = prevMonthLastDay - startDayOfWeek;
  //     i <= prevMonthLastDay;
  //     i++
  //   ) {
  //     days.push({ day: i, currentMonth: false });
  //   }

  //   // Add days from current month
  //   for (
  //     let i = firstDayOfMonth.getDate();
  //     i <= lastDayOfMonth.getDate();
  //     i++
  //   ) {
  //     days.push({ day: i, currentMonth: true });
  //   }

  //   return days;
  // };
  const getCurrentMonthDays = (currentMonth: any, currentYear: any) => {
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
    const startDayOfWeek = firstDayOfMonth.getDay(); // 0 for Sunday, 1 for Monday, etc.

    // Calculate the number of days from the previous month to include
    // based on the day of the week of the first day of the current month.
    let daysToIncludeFromPrevMonth =
      startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;

    const days = [];
    const prevMonth = currentMonth === 1 ? 12 : currentMonth;
    const prevMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    const prevMonthLastDay = new Date(
      prevMonthYear,
      prevMonth - 1,
      0,
    ).getDate();

    // Add days from previous month
    for (
      let i = prevMonthLastDay - daysToIncludeFromPrevMonth + 1;
      i <= prevMonthLastDay;
      i++
    ) {
      days.push({
        day: i,
        currentMonth: false,
        date: new Date(prevMonthYear, prevMonth - 1, i),
      });
    }

    // Add days from the current month
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      days.push({
        day: i,
        currentMonth: true,
        date: new Date(currentYear, currentMonth - 1, i),
      });
    }

    return days;
  };
  const renderDaysOfWeek = () => {
    const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

    return daysOfWeek.map((day, index) => (
      <div
        key={index}
        className="basis-1/7 px-2 py-3 flex justify-center text-moon-12 text-trunks"
      >
        <p className="text-moon-12 font-medium text-center text-gray-800 dark:text-gray-100">
          {day}
        </p>
      </div>
    ));
  };

  // const { date } = useSelector((state: RootState) => state.time);
  const renderCalendarDays = () => {
    const days = getCurrentMonthDays(
      selectedMonthOption?.label,
      moment().format("YYYY"),
    );

    return days?.map((dayObj: any, index) => {
      // console.log("dayObj.day", dayObj, "Days", data);
      // console.log("req day", moment(requests[7]?.date).format("D"));
      const request = requests?.find(
        (req: any) => moment(req.date).format("D") == dayObj.day,
      );

      let className = "text-moon-14";
      if (!dayObj.currentMonth) {
        className += " text-gray-300";
      } else if (request && request?.date === "Approved") {
        className += " bg-roshi text-white";
      } else if (request && request?.date) {
        className += " text-blackcst  border border-gray-300 bg-gray-100";
      }

      return (
        <div
          key={index}
          className={`flex justify-center rounded-[11.25px] ${className} ${
            data?.date ===
              moment(dayObj?.date, "MM/DD/YYYY").format("MM/DD/YYYY") &&
            dayObj?.currentMonth
              ? "bg-roshi text-white"
              : ""
          }`}
          onClick={() => {
            if (dayObj?.currentMonth) {
              dataOfDate(dayObj);
            }
          }}
        >
          <p className="text-base font-medium text-center px-2 py-3 w-full h-full rounded-[11.25px]">
            {dayObj.day}
          </p>
        </div>
      );
    });
  };

  const getCurrentMonthName = () => {
    const currentYear = 2023;
    const currentMonth = selectedMonthOption?.label - 1;

    const monthName = new Date(currentYear, currentMonth).toLocaleString(
      "en-US",
      {
        month: "long",
      },
    );

    return `${monthName} ${currentYear}`;
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(nextMonth);
  };
  return (
    <div className="flex items-center justify-center px-4">
      <div className="max-w-sm w-full">
        <div className="bg-white max-h-[384px]">
          <div className="flex items-center justify-between">
            <div className="font-semibold w-[40px]  border-goku  shadow-none hover:shadow-none text-moon-18"></div>
            {/* <IconButton
              variant="ghost"
              onClick={handlePrevMonth}
              className=" font-semibold d w-[40px]  border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronLeft />
            </IconButton> */}
            <h3 className="text-moon-12 text-bulma font-semibold text-center py-[5.62px] ">
              {getCurrentMonthName()}
            </h3>
            <div className="font-semibold w-[40px]  border-goku  shadow-none hover:shadow-none text-moon-18"></div>
            {/* <IconButton
              variant="ghost"
              onClick={handleNextMonth}
              className="font-semibold w-[40px]  border-goku border shadow-none hover:shadow-none text-moon-18"
            >
              <ControlsChevronRight />
            </IconButton> */}
          </div>

          <div className="flex flex-wrap cursor-default">
            {renderDaysOfWeek()}
          </div>

          <div className="grid grid-cols-7 gap-1 cursor-default">
            {renderCalendarDays()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCalendar;
