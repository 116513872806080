import { api } from "../../utils/axios";

export const getNotifications = async () => {
  try {
    const { data, ok } = await api.get(`/employee/getNotifications`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getUnreadNotifications = async () => {
  try {
    const { data, ok } = await api.get(`/employee/getUnreadNotifications`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
