import { createSlice, current } from "@reduxjs/toolkit";
import { IEmployee } from "../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";

interface weekSliceProps {
  employees: IEmployee[];
  selectedFilter: string;
}
const initialState: weekSliceProps = {
  employees: [],
  selectedFilter: "All",
};

export const weekFilterSlice = createSlice({
  name: "weekFilter",
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
      console.log("STate", current(state));
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
  },
});

export const { setSelectedFilter, setEmployees } = weekFilterSlice.actions;

export default weekFilterSlice.reducer;
