import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Input,
  Label,
  Modal,
} from "@heathmont/moon-core-tw";
import { ControlsClose } from "@heathmont/moon-icons";

interface ModalAddRoleProps {
  isOpen: boolean;
  closeModal: () => void;
  sectionData: any;
  branchId: any;
  editHospitalSection: (
    branchId?: string,
    sectionId?: string,
    sectionName?: any,
  ) => void;
}

interface IHospitalData {
  name: string;
  logo: string;
}

const options = [
  { name: "01" },
  { name: "02" },
  { name: "03" },
  { name: "04" },
  { name: "05" },
  { name: "06" },
];

interface Section {
  sectionName: string;
}
const ModalEditSection: React.FC<ModalAddRoleProps> = ({
  isOpen,
  closeModal,
  sectionData,
  branchId,
  editHospitalSection,
}) => {
  const [section, setSection] = useState<any>();
  console.log("Branch Id", branchId);
  const inputChangeHandler = (event: any) => {
    setSection((prev: any) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  useEffect(() => {
    console.log("Sec", sectionData);
    setSection(sectionData);
  }, [sectionData, isOpen]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[500px]">
        <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
          <h3 className="text-moon-20 font-semibold">Edit Section</h3>

          <IconButton
            onClick={closeModal}
            variant="ghost"
            icon={<ControlsClose />}
          />
        </div>
        <div className="modal-content px-4 py-2  border-b border-beerus">
          <div className="w-full mb-[24px]">
            <Label htmlFor="#" className="text-moon-16 text-[#000]">
              Name of the Section
            </Label>
            <Input
              type="text"
              placeholder="e.g, Hamilton Hospital"
              name="sectionName"
              value={section?.sectionName || ""}
              onChange={inputChangeHandler}
            />
          </div>
        </div>
        <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
          <Button variant="secondary" onClick={closeModal}>
            Discard
          </Button>
          <Button
            variant="tertiary"
            onClick={() => {
              console.log("IDS", section?._id, branchId?._id);
              editHospitalSection(branchId, section?._id, section?.sectionName);
              closeModal();
            }}
          >
            Add
          </Button>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default ModalEditSection;
