import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSelectedCalendar } from "../../../features/nurse-manager/Time Slice/timeSlice";
import { RootState } from "../../../store/store";
const FilterWeekMonth = () => {
  // const [selectedButton, setSelectedButton] = useState("week");
  const data = useSelector((state: RootState) => state?.time);

  const dispatch = useDispatch();
  const handleButtonClick = (button: string) => {
    dispatch(setSelectedCalendar(button));
  };
  // useEffect(() => {
  //   handleButtonClick("month");
  // }, []);
  return (
    <div className="flex items-center justify-center w-fit gap-1 p-1 bg-goku rounded-moon-s-sm min-h-[42px]">
      <Link
        to=""
        className={`px-3 py-1 gap-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14  
        font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          data?.selectedCalendar === "week"
            ? "bg-gohan text-slate-900"
            : "text-trunks"
        }`}
        onClick={() => handleButtonClick("week")}
      >
        Week
      </Link>
      <Link
        to=""
        className={`px-3 py-1 gap-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14 
        font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          data?.selectedCalendar === "month"
            ? "bg-gohan text-slate-900"
            : "text-trunks"
        }`}
        onClick={() => handleButtonClick("month")}
      >
        Month
      </Link>
    </div>
  );
};

export default FilterWeekMonth;
