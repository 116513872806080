export const getBorderColor = (roles: string) => {
  // if (roles?.includes("supervisor")) {
  //   return "border-rose-500";
  // } else
  if (roles?.includes("senior nurse")) {
    return "border-dodoria";
  } else if (roles?.includes("nurse")) {
    return "border-whis";
  } else if (roles?.includes("HCA")) {
    return "border-krillin";
  } else {
    return "border-zeno";
  }
};

export const getTextColor = (roles: string) => {
  //   if (roles.includes("supervisor")) {
  //     return "text-rose-500";
  //   } else
  if (roles?.includes("senior nurse")) {
    return "text-dodoria bg-dodoria-10";
  } else if (roles?.includes("nurse")) {
    return "text-whis bg-whis-10";
  } else if (roles?.includes("HCA")) {
    return "text-krillin bg-krillin-10";
  } else {
    return "text-zeno";
  }
};
