import { Snackbar, Button } from "@heathmont/moon-core-tw";
import {
  GenericAlarm,
  GenericCheckRounded,
  GenericClose,
} from "@heathmont/moon-icons-tw";
import { useCallback, useEffect } from "react";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { NotificationsBellRingingAlternative } from "@heathmont/moon-icons";

interface Props {
  snackbar: string;
  toastMessage: string;
}

const Toast: React.FC<Props> = ({ snackbar, toastMessage }) => {
  // const openSnackbarHandler = useCallback((type: string, message: string) => {
  //   store.dispatch(setSnackBar(type));
  //   store.dispatch(setToastMessage(message));
  // }, []);

  useEffect(() => {
    if (snackbar) {
      openSnackbarHandler(snackbar, toastMessage);
    }
  }, []);

  const closeSnackbar = () => {
    // Close the snackbar when you're done with it
    store.dispatch(setSnackBar(""));
    store.dispatch(setToastMessage(""));
  };

  const openSnackbarHandler = useCallback(
    (type: string, message: string) => {
      if (snackbar) {
        store.dispatch(setSnackBar(""));
        store.dispatch(setToastMessage(""));
        setTimeout(() => {
          // setSnackbar(type);
          store.dispatch(setSnackBar(type));
          store.dispatch(setToastMessage(message));
        }, 400);
      } else {
        store.dispatch(setSnackBar(type));
        store.dispatch(setToastMessage(message));
      }
    },
    [snackbar]
  );

  return (
    <>
      <div>
        <Snackbar
          isOpen={snackbar === "success"}
          onOpenChange={closeSnackbar}
          autoClose={3000}
        >
          <Snackbar.Icon className="h-10 bg-roshi-10 text-roshi z-[1050]">
            <GenericCheckRounded />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Success</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar
          isOpen={snackbar === "warning"}
          onOpenChange={closeSnackbar}
          autoClose={3000}
        >
          <Snackbar.Icon className="h-10 bg-krillin-10 text-krillin">
            <GenericAlarm />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Warning</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar
          isOpen={snackbar === "error"}
          onOpenChange={closeSnackbar}
          autoClose={3000}
        >
          <Snackbar.Icon className="h-10 bg-chichi-10 text-chichi">
            <GenericClose />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Error</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
      <div>
        <Snackbar
          isOpen={snackbar === "notification"}
          onOpenChange={closeSnackbar}
          autoClose={3000}
        >
          <Snackbar.Icon className="h-10 bg-whis-10 text-whis">
            <NotificationsBellRingingAlternative />
          </Snackbar.Icon>
          <Snackbar.Content>
            <Snackbar.Header>Notification</Snackbar.Header>
            <Snackbar.Message>{toastMessage}</Snackbar.Message>
          </Snackbar.Content>
        </Snackbar>
      </div>
    </>
  );
};

export default Toast;
