import React from "react";

import { Avatar } from "@heathmont/moon-core-tw";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { IWeeklySchedule } from "../../../../types/nurse-manager-interfaces/IWeeklySchedule.interface";
import { getBorderColor } from "../../../Colors/colorSetting";

interface Props {
  employeeSchedules: IWeeklySchedule;
}
const EmployeeShift: React.FC<Props> = ({ employeeSchedules }) => {
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const employees = [
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Night", "Day", "Day", "Day", "Day", "Day", ""],
      roles: ["supervisor"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["AL", "Day", "Night", "Night", "", "Night", "Day"],
      roles: ["leader"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["", "Night", "", "Day", "Day", "Day", "AL"],
      roles: ["leader"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Day", "RO", "RO", "", "Night", "Day", "Day"],
      roles: ["nurse"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["", "Day", "Night", "RO", "Night", "", ""],
      roles: ["nurse"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["", "Day", "", "Day", "", "Day", "RO"],
      roles: ["HSA"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Day", "", "Night", "Day", "", "Night", "Day"],
      roles: ["supervisor"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Day", "RO", "AL", "Night", "", "Night", "Day"],
      roles: ["HSA"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Day", "", "Day", "Night", "", "Night", "Day"],
      roles: ["supervisor"],
    },
    {
      name: "Eleanor Pena",
      avatarImageUrl: "images/Avatar.png",
      shifts: ["Day", "", "Night", "Night", "", "Night", "Day"],
      roles: ["leader"],
    },
  ];

  console.log("employee: ", employeeSchedules?.employeeSchedules);
  return (
    <>
      {employeeSchedules?.employeeSchedules?.map(
        (employee: any, index: any) => (
          <div className="flex flex-wrap" key={index}>
            <div className="w-1/5">
              <div className="h-[64px] px-[30px] -[30px] py-2 border border-beerus border-s-0 flex items-center">
                <div className="flex items-center">
                  <Avatar
                    className={`rounded-full border-2   ${getBorderColor(
                      employee?.employee?.designation
                    )} overflow-hidden`}
                    // imageUrl={employee?.employee?.avatarImageUrl || ""} //currently there is no image url
                    imageUrl={""} //currently there is no image url
                  />
                  <div className="flex-1 text-moon-16 transition-colors pl-[10px] line-clamp-1 truncate">
                    {employee?.employee?.employeeName}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-4/5">
              <div className="flex flex-wrap">
                {employee?.shifts.map((shift: any, shiftIndex: any) => (
                  <div
                    className={`basis-1/7 h-[64px] border border-beerus border-s-0 justify-center px-3 py-2 gap-[5px] inline-flex items-center ${
                      shift?.leaveType === "AL"
                        ? "bg-amber-100 text-amber-600 justify-center"
                        : shift?.isOnTimeOff &&
                          !shift?.isNightShift &&
                          !shift?.isDayShift // shift === "RO" commented this bcz ro doesn't come in this format
                        ? "bg-cyan-50 text-cyan-600 justify-center"
                        : ""
                    }`}
                    key={shiftIndex}
                  >
                    {shift?.leaveType === "AL" ? (
                      <span className="font-semibold">{shift?.leaveType}</span>
                    ) : shift?.leaveType == "ML" ? (
                      <span className="font-semibold">{shift.leaveType}</span>
                    ) : shift?.isDayShift ? (
                      selectedShift !== "moon" ? (
                        <>
                          <i className=" text-moon-24 text-krillin ">
                            <Icon icon="uil:sun" />
                          </i>
                          <span className="text-sm font-semibold ">Day</span>
                        </>
                      ) : null
                    ) : shift?.isNightShift ? (
                      selectedShift !== "sun" ? (
                        <>
                          <i className="text-moon-24 ">
                            <OtherMoon />
                          </i>
                          <span className="text-sm font-semibold ">Night</span>
                        </>
                      ) : null
                    ) : shift?.isOnTimeOff ? (
                      <span className="font-semibold">{"RO"}</span>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default EmployeeShift;
