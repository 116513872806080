import React from "react";
import { NotificationsAlert } from "@heathmont/moon-icons-tw";
import { Accordion, Avatar, Checkbox, Chip } from "@heathmont/moon-core-tw";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { IEmployee } from "../../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";
import { getBorderColor, getTextColor } from "../../../Colors/colorSetting";
import { EmployeeCardData } from "../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import EmployeeCard from "../cards/card-employee/EmployeeCard";
import { ControlsChevronDownSmall } from "@heathmont/moon-icons";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";

interface Props {
  empsByDesignation: EmployeeCardData[];
  checkedItems: string[];
  handleCheckboxChange: (empId: string, event: any) => void;
  selectedDate: any;
  month: IMonthDropDown;
  year: IYearDropDown;
}
const CardPoolTrackingAccordion: React.FC<Props> = ({
  empsByDesignation,
  checkedItems,
  handleCheckboxChange,
  selectedDate,
  month,
  year,
}) => {
  // const { employees } = useSelector((state: RootState) => state.weekFilter);
  // console.log("EMPLO", employees);
  // const employees = [
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["leader"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["nurse"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["nurse"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   // Add more employee objects with their roles and utilization data
  // ];
  console.log("fil emp: ", empsByDesignation);

  return (
    <>
      <div className="px-[25px]">
        {empsByDesignation?.map((emp: EmployeeCardData, index: number) => {
          return (
            <div key={index} className="flex items-center gap-2">
              <Accordion itemSize="sm">
                <Accordion.Item value={`item-${index}`} className="mb-2">
                  <Accordion.Header>
                    <Accordion.Button>
                      <span className="flex items-center gap-1 ">
                        <Checkbox
                          bgColor="bg-hit"
                          className=" transform-none"
                          // checked={checkedItems[index]}
                          checked={checkedItems?.includes(emp?._id)}
                          onChange={(event) =>
                            handleCheckboxChange(emp?._id, event)
                          }
                          disabled={!selectedDate}
                        />
                        {/* {DESIGNATION[listing?.designation]} */}
                        {emp?.employeeName}
                        <Chip
                          size="sm"
                          className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${getTextColor(
                            emp?.designation
                          )}`}
                        >
                          {emp?.designation}
                        </Chip>
                      </span>
                      <ControlsChevronDownSmall className=" cst-accordian text-trunks text-moon-16 transition duration-200 moon-open:text-bulma" />
                    </Accordion.Button>
                  </Accordion.Header>
                  <Accordion.Content className="border-0 p-0">
                    <div className="p-3 flex w-full gap-2">
                      {/* <Checkbox
                                        bgColor="bg-hit"
                                        checked={checkedItems?.includes(
                                          listing?._id
                                        )}
                                        onChange={(event: any) =>
                                          handleCheckboxChange(
                                            listing?._id,
                                            event
                                          )
                                        }
                                      /> */}
                      <div className="flex-1">
                        <EmployeeCard
                          key={index}
                          employeeCardData={emp}
                          isChecked={checkedItems?.includes(emp?._id)}
                          selectedDate={selectedDate}
                          month={month}
                          year={year}
                        />
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion>
            </div>
          );
        })}
      </div>
      {/* {employees
        ? employees?.map((employee: IEmployee, index: any) => (
            <div
              key={index}
              className="p-[10px] border border-beerus rounded-[8px]"
            >
              <div className="flex items-start">
                <Avatar
                  className={`rounded-full border-2 
              ${getBorderColor(employee?.designation)} 
              overflow-hidden`}
                  imageUrl=""
                  size="lg"
                />
                <div className="flex-1 px-[10px]">
                  <h3 className="font-semibold capitalize mb-1 truncate max-w-[200px]">
                    {employee?.employeeName}
                  </h3>
                  <p className="capitalize text-moon-10 text-grey-100">
                    Hours Utilization
                  </p>
                  <div className="text-moon-12 font-normal inline-flex items-center">
                    <strong className="font-semibold">
                      {employee?.hoursSpent}
                    </strong>{" "}
                    /{employee?.hoursAllocated}
                  </div>
                </div>
                <NotificationsAlert />
              </div>
            </div>
          ))
        : null} */}
    </>
  );
};

export default CardPoolTrackingAccordion;
