const Employee_Id = [
  { name: "contains" },
  { name: "lessThan" },
  { name: "greaterThan" },
  { name: "lessThanOrExactly" },
  { name: "greaterThanOrExactly" },
];
const Employee_Name = [
  { name: "includes" },
  { name: "orderByAtoZ" },
  { name: "orderByZtoA" },
];
const Designation = [{ name: "contains" }];
const Appointed_Section = [{ name: "contains" }];
const Joining_Date = [
  { name: "byDate" },
  { name: "byMonthAndYear" },
  { name: "byMonth" },
];
const Seniority_Level = [
  { name: "senior" },
  { name: "junior" },
  { name: "intern" },
];

//patient
const Patient_Id = [
  { name: "contains" },
  { name: "lessThan" },
  { name: "greaterThan" },
  { name: "lessThanOrExactly" },
  { name: "greaterThanOrExactly" },
];
const Patient_Name = [
  { name: "includes" },
  { name: "orderByAtoZ" },
  { name: "orderByZtoA" },
];
const Admission_Discharge_Date = [
  { name: "byDate" },
  { name: "byMonthAndYear" },
  { name: "byMonth" },
];
const Assigned_Room = [{ name: "contains" }];
const Allocated_Section = [{ name: "includes" }];
const Needy_Profile = [
  { name: "needyProfile" },
  { name: "high" },
  { name: "medium" },
  { name: "low" },
];

export const FILTER_SELECT = {
  EMP_ID: Employee_Id,
  EMP_NAME: Employee_Name,
  EMP_DESIGNATION: Designation,
  EMP_JOINING_DATE: Joining_Date,
  EMP_APPOINTED_SECTION: Appointed_Section,
  EMP_SENIORITY_LEVEL: Seniority_Level,
  PAT_ID: Patient_Id,
  PAT_NAME: Patient_Name,
  PAT_ADD_DIS_DATE: Admission_Discharge_Date,
  PAT_ALLOCATED_SECTION: Allocated_Section,
  PAT_ASSIGNED_ROOM: Assigned_Room,
  PAT_NEEDY_PROFILE: Needy_Profile,
};


export const SENIORITY_LEVEL= {
  senior: "senior" ,
  junior: "junior" ,
  intern: "intern" ,
} 

export const ROLE = {
  NURSE : "nurse",
  HCA : "HCA",
  SENIOR_NURSE : "senior nurse",
  BRANCH_ADMIN : "branch admin",
  NURSE_MANAGER : "nurse manager",
  NURSE_DIRECTOR : "nurse director",
}
