import { api } from "../../utils/axios";
import { IGetPatientsRequest } from "../../types/branch-admin-interfaces/IGetPatientsRequest.interface";

export const getPatientsData = async (params: IGetPatientsRequest) => {
  try {
    const {
      branchName,
      allocatedSection,
      contains,
      patientId,
      search,
      page,
      limit,
    } = params;

    const response = await api.get(
      `/admin/getPatientsData?branchName=${branchName}&allocatedSection=${allocatedSection}&contains=${contains}&patientId=${patientId}&search=${search}&page=${page}&limit=${limit}`,
    );

    return response?.data;
  } catch (error) {}
};


export const deleteLeaves = async (id:string) => {
  try {

    const response = await api.delete(
      `/admin/deleteLeaves/${id}`,
    );
    return response?.data;
  } catch (error) {
    return {err: error}
  }
};
export const deleteRequestOffLeaves = async (id:string) => {
  try {

    const response = await api.delete(
      `/admin/deleteTimeOff/${id}`,
    );
    return response?.data;
  } catch (error) {
    return {err: error}
  }
};


// import createApi from "../utils/axios";
// import { METHODS } from "../enums/axios.enums";
// import { IGetPatientsRequest } from "../types/interfaces/IGetPatientsRequest.interface";

// const AdminApi = createApi("/admin");

// export const getPatientsData = async (params: IGetPatientsRequest) => {
//   const { data } = await AdminApi({
//     url: "/getPatientsData",
//     method: METHODS.POST,
//     data: params,
//   });

//   return data;
// };
