import { Button, Form, Input, Label } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  resetPassword,
  verifyResetPassword,
  changePassword,
} from "../../services/auth.service";
import toast from "react-hot-toast";
interface PasswordVerfication {
  newPassword: string;
  confirmPassword: string;
}

export default function NewPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState<PasswordVerfication>({
    newPassword: "",
    confirmPassword: "",
  });
  const [email, setEmail] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>("");

  const inputChangeHandler = (event: any) => {
    setPassword((prev: any) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  useEffect(() => {
    // const token = "";
    const resetToken = new URLSearchParams(window.location.search).get("token");
    const email: any = new URLSearchParams(window.location.search).get("email");
    const params = { resetToken, email };

    verifyEmailToken(params);
    //
    setEmail(email);
    // setToken(token);
  }, []);

  const verifyEmailToken = async (params: any) => {
    const res: any = await verifyResetPassword(params);

    if (res.verified) {
      return;
    } else {
      toast.error("There is an error please click on Resend");
    }
  };

  const handleNewPassword = async () => {
    const isValidPassword = validatePassword();
    if (isValidPassword) {
      const params: any = {
        newPassword: password?.newPassword,
        email: email,
      };
      const res: any = await changePassword(params);
      if (res?.changedSuccess) {
        toast.success("Password change successfully");
        navigate("/");
      } else {
      }
    }
  };

  const validatePassword = () => {
    const { newPassword, confirmPassword } = password;
    // Add your password validation logic here
    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return false;
    } else if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return false;
    }
    // Clear password error if validation passes
    setPasswordError("");
    return true;
  };
  return (
    <>
      <div className="h-screen flex flex-col justify-between bg-form-pattern bg-no-repeat bg-center">
        <div className="flex text-center flex-1  flex-col justify-center ">
          <div className="mx-auto max-w-1/2 pt-12 w-full">
            <img
              className="mx-auto mb-20"
              src={"images/login-weblogo.png"}
              alt="logo"
            />
            <h3 className="text-3xll mb-5">Set New Password</h3>
            <p className="text-base mb-10">
              Enter a new password below to reset.
            </p>
            <Form className="mx-auto py-9 px-10 bg-white rounded-xl shadow-3xl">
              <Form.Item className="mb-3">
                <Label htmlFor="password" className="text-start">
                  New Password
                </Label>
                <Input
                  type="password"
                  placeholder="New Password"
                  id="test-2"
                  name="newPassword"
                  onChange={inputChangeHandler}
                />
              </Form.Item>
              <Form.Item className="mb-3">
                <Label htmlFor="confirmpassword" className="text-start">
                  Confirm Password
                </Label>
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  id="test-2"
                  name="confirmPassword"
                  onChange={inputChangeHandler}
                />
              </Form.Item>
              {passwordError && <p className="text-red-500">{passwordError}</p>}
              <Button
                className="w-full h-10 mb-5 shadow-btnshadow "
                type="submit"
                form="login"
                value="Submit"
                variant="tertiary"
                onClick={handleNewPassword}
              >
                Change Password
              </Button>
              <Link
                to="/forgot-password"
                className="text-base text-[#8697A2] cursor-pointer"
              >
                Resend
              </Link>
            </Form>
          </div>
        </div>
        <hr />
        <div className="footer px-24 py-6">
          <div className="flex justify-between items-center">
            <p className="text-base text-[#8697A2]">
              Copyright ©
              <span className="text-[#249CBA] mx-1">
                Healthcare Management Portal |
              </span>
              All Rights Reserved
            </p>
            <img src={"images/healthlogo.svg"} alt="logo" />
          </div>
        </div>
      </div>
    </>
  );
}
