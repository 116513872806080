import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
// import { setAuthUser } from "./features/Auth Slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import "simplebar-react/dist/simplebar.min.css";
import LoaderSpinner from "./Components/Loader/Loader";
import LogIn from "./Components/Login";
import Toast from "./Components/Toast/Toast";
import CreateProfile from "./Components/create-profile";
import EmailSent from "./Components/email-sent";
import NewPassword from "./Components/new-password";
import Forgot from "./Pages/Forgot";
import Branch_Admin_Routes from "./Pages/branch-admin/Branch_Admin_Routes";
import Nurse_Manager_Routes from "./Pages/nurse-manager/Nurse_Manager_Routes";
import Super_Admin_Routes from "./Pages/super-admin/Super_Admin_Routes";
import { setAuthUser } from "./features/Auth Slice/authSlice";
import { RootState } from "./store/store";
import spinnerSvc from "./utils/spinner-service";

const App = () => {
  const [loading, setLoading] = useState(null);

  const dispatch: any = useDispatch();
  const data = useSelector((state: RootState) => state.auth.User);
  const { snackbar, toastMessage } = useSelector(
    (state: RootState) => state.toast
  );
  useEffect(() => {
    const subscription = spinnerSvc.requestInProgress.subscribe((data) => {
      setLoading(data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  // const { User } = useSelector((state: RootState) => state.auth);
  // const socket = io("https://dev.api.healthcare.aquila360.com");

  // const socket = io("https://dev.api.healthcare.aquila360.com", {
  //   transports: ["websocket"], // Specify the WebSocket transport
  //   secure: true, // Enable secure connection (wss)
  // });

  const user = JSON.parse(sessionStorage.getItem("user")!);
  useEffect(() => {
    // console.log(process.env.REACT_APP_API_URL);
    if (user) dispatch(setAuthUser(user));
  }, []);
  // useEffect(() => {
  //   if (!User?.id) return;
  //   console.log("check user: ", User);
  //   // Join the Nurse Director's room using their unique ID
  //   // socket.emit("joinRoom", User?.id);
  //   socket.emit("send_message", { message: "Hello" });
  //   // Listen for the 'scheduleSubmitted' event in the Nurse Director's room
  //   // socket.on("", (data) => {
  //   //   // Handle the received data (schedule submission notification)
  //   //   console.log("Received schedule submission:", data);
  //   // });

  //   // Cleanup the event listener when the component unmounts
  //   // return () => {
  //   //   socket.off("scheduleSubmitted");
  //   // };
  // }, [User]);
  return (
    <>
      {loading && <LoaderSpinner />}
      <Toast
        snackbar={snackbar}
        toastMessage={toastMessage}
        // setSnackbar={setSnackbar}
        // setToastMessage={setToastMessage}
      />
      {user?.designation === "branch admin" ? (
        <Branch_Admin_Routes />
      ) : user?.designation === "super admin" ? (
        <Super_Admin_Routes />
      ) : user?.designation === "nurse manager" ||
        user?.designation === "nurse director" ||
        user?.temporaryRole === "nurse manager" ? (
        <Nurse_Manager_Routes />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/create-profile" element={<CreateProfile />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;
