import React from "react";
import { Rules } from "../../../types/branch-admin-interfaces/IRulesForm.interface";
import SimpleBar from "simplebar-react";

interface Props {
  rules: Rules;
}
const AllocateRules: React.FC<Props> = ({ rules }) => {
  return (
    <>
      <div className="bg-[#f5f5f5] py-5 rounded-lg">
        <h3 className="text-lg text-[#272D36] mb-6 font-semibold px-5">
          Rules
        </h3>
        <SimpleBar className="h-[284px] overflow-y-auto px-5">
          <div className="flex justify-between gap-3 items-center">
            <p className="text-base text-[#272D36] flex-1">
              Number of beds in section
            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {rules?.numberOfBeds}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between gap-3 items-center">
            <p className="text-base text-[#272D36]">
              Number of rooms in section
            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {rules?.numberOfRooms}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between gap-3 items-center">
            <p className="text-base text-[#272D36]">
            Number of patients per HCA
            </p>
            <p className="text-sm text-[#272D36] font-semibold flex items-center gap-3">
              <span className="text-sm text-[#8697A2]">Day shift</span>{" "}
              {rules?.numberOfBedsPerHCADayShift}{" "}
              <span className="text-sm text-[#8697A2]">Night shift</span>{" "}
              {rules?.numberOfBedsPerHCANightShift}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between gap-3 items-center">
            <p className="text-base text-[#272D36]">Number of patients per Nurse
            </p>
            <p className="text-sm text-[#272D36] font-semibold flex items-center gap-3">
              <span className="text-sm text-[#8697A2]">Day shift</span>{" "}
              {rules?.numberOfBedsPerNursesDayShift}{" "}
              <span className="text-sm text-[#8697A2]">Night shift</span>{" "}
              {rules?.numberOfBedsPerNursesNightShift}
            </p>
          </div>
          <hr className="my-5 text-[#f1f1f1]" />
          <div className="flex justify-between gap-3 items-center">
            <p className="text-base text-[#272D36]">
            Number of Senior Nurses per shift            </p>
            <p className="text-sm text-[#272D36] font-semibold">
              {rules?.numberOfBedsPerSeniorNurses}
            </p>
          </div>
        </SimpleBar>
      </div>
    </>
  );
};

export default AllocateRules;
