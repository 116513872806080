// import { ITableDataResponse } from "../types/ITableData.interface";

export const generatePageNumbers = (tableData: any) => {
  const totalPages = tableData?.totalPages || 0;
  const currentPage = tableData?.currentPage || 1;
  const pagesToShow = 5;
  const pages = [];

  if (totalPages <= pagesToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const middlePage = Math.max(Math.min(currentPage, totalPages - 2), 3);

    pages.push(1);

    if (middlePage > 4) {
      pages.push("...");
    }

    for (let i = middlePage - 2; i <= middlePage + 2; i++) {
      if (i >= 2 && i <= totalPages - 1) {
        pages.push(i);
      }
    }

    if (middlePage < totalPages - 3) {
      pages.push("...");
    }

    pages.push(totalPages);
  }

  return pages;
};
