import React, { useEffect } from "react";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedShift } from "../../../features/nurse-manager/Time Slice/timeSlice";
import { RootState } from "../../../store/store";
import { useLocation } from "react-router-dom";
const FilterShifts = () => {
  // const [selectedButton, setSelectedButton] = useState("all");
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const user = useSelector((state: RootState) => state.auth.User);
  const dispatch = useDispatch();

  const handleButtonClick = (button: string) => {
    dispatch(setSelectedShift(button));
  };

  const location = useLocation();

  // useEffect(() => {
  //   if (selectedShift !== "all") {
  //     dispatch(setSelectedShift("sun"));
  //   }
  // }, []);
  return (
    <div className="flex items-center justify-center gap-1 p-1 bg-goku rounded-moon-s-sm min-h-[42px]">
      {location.pathname === "/monthly" ||
      (location.pathname === "/scheduler" &&
        user?.designation === "nurse manager") ||
      user?.temporaryRole === "nurse manager" ? null : (
        <button
          className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
            selectedShift === "all" ? "bg-gohan text-slate-900" : "text-trunks"
          }`}
          onClick={() => handleButtonClick("all")}
        >
          All
        </button>
      )}{" "}
      <button
        className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24  font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          selectedShift === "sun" ? "bg-gohan text-krillin " : "text-trunks"
        }`}
        onClick={() => handleButtonClick("sun")}
      >
        <Icon icon="uil:sun" />
      </button>
      <button
        className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
          selectedShift === "moon" ? "bg-gohan text-slate-900" : "text-trunks"
        }`}
        onClick={() => handleButtonClick("moon")}
      >
        <OtherMoon />
      </button>
    </div>
  );
};

export default FilterShifts;
