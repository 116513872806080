import React, { useEffect, useState } from "react";
import { Modal, IconButton, Avatar } from "@heathmont/moon-core-tw";
import { ControlsClose, TimeClock } from "@heathmont/moon-icons-tw";
import { GenericAlarmRound } from "@heathmont/moon-icons-tw";
import RequestCalendar from "./RequestCalendar";
import moment from "moment";
import { IMonthDropDown } from "../../../../types/IDateDropDown.interface";

interface RequestedDate {
  date: string;
  reason: string;
  time: string;
}

interface RequestData {
  result: {
    requestedBy: {
      _id: string;
      employeeId: string;
      designation: string;
      employeeName: string;
    };
    type: string;
    status: string;
    month: number;
    year: number;
    requestedDays: number;
    requestedDates: RequestedDate[];
  };
}

interface Request {
  date: number;
  status: "day off" | "approved"; // Assuming "day off" and "approved" are the possible values for the status property
}

interface RequestModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  calendarData: RequestData;
  selectedMonthOption: IMonthDropDown;
}

const RequestModal = ({
  isOpen,
  setIsOpen,
  onClose,
  calendarData,
  selectedMonthOption,
}: RequestModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Call the onClose function from the parent component
  };

  console.log("Selected", selectedMonthOption);
  const [dateSelected, setSelected] = useState<RequestedDate>(
    calendarData?.result?.requestedDates[0] as RequestedDate,
  );

  const requests: Request[] = [
    { date: 6, status: "day off" },
    { date: 10, status: "day off" },
    { date: 13, status: "day off" },
    { date: 14, status: "day off" },
    { date: 16, status: "approved" },
    { date: 18, status: "approved" },
    { date: 27, status: "day off" },
    { date: 28, status: "day off" },
  ];

  // console.log("Calendar Data", calendarData);

  const dataOfDate = (dayObj: any) => {
    // console.log("Day Obj", dayObj);
    const data = calendarData?.result?.requestedDates?.find(
      (req: any) => moment(req.date).format("D") == dayObj.day,
    );
    console.log("Data OF date", data);
    if (data) {
      setSelected({ ...data });
    }
  };

  useEffect(() => {
    if (calendarData) {
      setSelected(calendarData?.result?.requestedDates[0]);
      console.log("Sele", dateSelected);
    }
  }, [calendarData, isOpen]);
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[929px]  rounded-xl overflow-hidden">
        <div className="flex flex-wrap">
          <div className="w-1/2 border-r border-beerus">
            <div className="p-4 border-b border-beerus">
              <h3 className="text-moon-18 text-bulma font-semibold">
                Time-off Request
              </h3>
            </div>
            <div className="p-[12px_24px]">
              <div className="py-[10px] flex items-center gap-[22px]">
                <span className="inline-flex items-center justify-center h-[64px] w-[64px] rounded-full bg-indigo-100">
                  <TimeClock className="text-moon-48 text-frieza" />
                </span>
                <div className="flex-1">
                  <h3 className="text-frieza font-semibold">Time-off</h3>
                  <p className="text-gray-400 text-moon-16">
                    Manage time-off request of an employee
                  </p>
                </div>
              </div>
              <div className="py-[10px]">
                <h4 className="text-gray-400 text-moon-16 font-normal">
                  Reason
                </h4>
                <p className="text-bulma text-moon-16 ">
                  {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. */}
                  {dateSelected?.reason}
                </p>
              </div>
              <div className="grid grid-cols-2 gap-[15px]">
                <div className="py-[10px]">
                  <h4 className="text-gray-400 text-moon-16 font-normal">
                    Time
                  </h4>
                  <p className="text-bulma text-moon-16  font-semibold">
                    {moment(dateSelected?.time).format("h:mm A")}
                  </p>
                </div>
                <div className="py-[10px]">
                  <h4 className="text-gray-400 text-moon-16 font-normal">
                    Requested
                  </h4>
                  <p className="text-bulma text-moon-16 font-semibold">
                    {calendarData?.result?.requestedDays}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-[15px]">
                <div className="py-[10px]">
                  <h4 className="text-gray-400 text-moon-16 font-normal">
                    Status
                  </h4>
                  <p className="text-moon-16 font-semibold text-krillin">
                    {calendarData?.result?.status}
                  </p>
                </div>
                <div className="py-[10px]">
                  <h4 className="text-gray-400 text-moon-16 font-normal">
                    Requested by
                  </h4>
                  <div className="flex items-center gap-[10px] mt-[10px]">
                    <span className="inline-flex rounded-full shadow-md ">
                      <Avatar
                        size="lg"
                        className="rounded-full border-2 overflow-hidden"
                        imageUrl=""
                      ></Avatar>
                    </span>
                    <div className="flex-1">
                      <h4 className="text-bulma text-moon-16 font-semibold">
                        {calendarData?.result?.requestedBy?.employeeName}
                      </h4>
                      <p className="text-gray-400 text-moon-14 font-normal">
                        {calendarData?.result?.requestedBy?.designation}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <div className="p-4 border-b border-beerus">
              <h3 className="text-moon-18 text-bulma font-semibold text-left">
                Choose Employees
              </h3>
              <IconButton
                onClick={handleClose}
                variant="ghost"
                className="absolute top-[9px] right-[16px]"
                icon={<ControlsClose />}
              />
            </div>
            <div className="p-4">
              <RequestCalendar
                requests={calendarData?.result?.requestedDates}
                dataOfDate={dataOfDate}
                data={dateSelected}
                selectedMonthOption={selectedMonthOption}
              />
            </div>
          </div>
        </div>
        <div className="p-[26px_24px] flex flex-wrap bg-goku gap-[8px]">
          <div>
            <p className="text-gray-600 text-moon-12 flex items-center gap-1">
              <i className="leading-0 text-moon-18">
                {" "}
                <GenericAlarmRound />
              </i>
              <span className="flex-1">
                Select out of requested to choose time-off days of the employee
              </span>
            </p>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default RequestModal;
