import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  IEmployeeData,
  IShift,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";

interface Props {
  month: IEmployeeData[];
  monthDetail: IMonthDropDown;
  year: IYearDropDown;
}
const MonthDate: React.FC<Props> = ({ month, monthDetail, year }) => {
  console.log("Month Detail", monthDetail);
  // const { date } = useSelector(state => state.time);
  // const { date } = useSelector((state: RootState) => state.time);
  const { selectedShift } = useSelector((state: RootState) => state.time);
  // const selectedDay: number = Number(date?.day); //current day
  // const selectedMonth: string = date?.month;
  // const selectedYear: number = Number(date?.year);

  const selectedDay: number = moment().date(); //current day
  const selectedMonthIndex: number = moment().month(monthDetail?.name).month();
  const firstDay = moment([year?.year, selectedMonthIndex])
    .startOf("month")
    .format("D");
  const daysInMonth: any = moment([year?.year, selectedMonthIndex])
    .endOf("month")
    .format("D");
  const renderCalendarDates = () => {
    return Array.from({ length: daysInMonth }, (_, index) => {
      const day: any = parseInt(firstDay) + index;
      const isCurrentDay =
        selectedDay === day &&
        monthDetail?.monthName === moment().format("MMMM");
      const dateClassName = isCurrentDay ? "bg-blackcst text-white" : "";

      return (
        <div
          key={day}
          className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center ${dateClassName}`}
        >
          <div className="text-moon-14 font-semibold transition-colors">
            {/* {day} */}
          </div>
        </div>
      );
    });
  };
  // const renderCalendarDates = () => {
  //   for (let i: any = firstDay; calendarDates.length < daysInMonth; i++) {
  //     const date1 = calendarDates.length + 1;
  //     const isCurrentDay =
  //       selectedDay === date1 && selectedMonth === moment().format("MMMM");

  //     const dateClassName = isCurrentDay ? "bg-blackcst  text-white" : "";

  //     calendarDates.push(
  //       <div
  //         key={date1}
  //         className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center ${dateClassName}`}
  //       >
  //         <div className="text-moon-14 font-semibold transition-colors">
  //           {date1}
  //         </div>
  //       </div>,
  //     );
  //   }

  //   return calendarDates;
  // };

  // Extract date (day)
  const dateFormat = (timestamp: any) => {
    const date: any = new Date(timestamp);
    const day: any = date.getDate();

    return day;
  };

  return (
    <>
      <div className="flex flex-wrap w-full">
        {month ? (
          month[0]?.shifts?.map((monthData: IShift, index: number) => (
            <div
              key={index}
              className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center 
              ${
                moment().date() === dateFormat(monthData?.date) &&
                monthDetail?.monthName === moment().format("MMMM")
                  ? "bg-blackcst text-white"
                  : ""
              }
              `}
            >
              <div>
                {selectedShift !== "all" ? (
                  <span
                    className={`w-2 h-2 rounded-full inline-flex ${
                      // selectedShift === "sun" &&
                      selectedShift === "sun" && monthData?.isDayOverUtilization
                        ? "bg-krillin"
                        : selectedShift === "moon" &&
                          monthData?.isNightOverUtilization
                        ? "bg-krillin"
                        : selectedShift === "sun"
                        ? monthData?.isDayBalanced
                          ? "bg-roshi"
                          : "bg-dodoria"
                        : selectedShift === "moon"
                        ? monthData?.isNightBalanced
                          ? "bg-roshi"
                          : "bg-dodoria"
                        : ""
                    }`}
                  ></span>
                ) : (
                  ""
                )}

                <div className="flex flex-wrap w-full">
                  {" "}
                  {dateFormat(monthData?.date)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
          // <div className="flex flex-wrap w-full"> {renderCalendarDates()}</div>
        )}
      </div>
    </>
  );
};
export default MonthDate;
