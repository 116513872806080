import { api } from "../../utils/axios";
import { IBranchData } from "../../types/super-admin-interfaces/IBranchData.interface";
export const addBranchHospital = async (
  hospitalId: string,
  params: IBranchData
) => {
  try {
    const { data, ok } = await api.post(
      `branch/addBranch/${hospitalId}`,
      params
    );
    if (ok) {
      return { res: data, err: null };
    }
    return {res:null, err:data}
  } catch (error) {
    return { res: null, err: error };
  }
};
export const editBranchHospital = async (
  branchId?: string,
  params?: IBranchData
) => {
  try {
    const { data, ok } = await api.post(
      `branch/editBranch/${branchId}`,
      params
    );
    if (ok) {
      return { res: data, err: null };
    }
    return {res:null, err:data}

  } catch (error) {
    return { res: null, err: error };
  }
};
// editBranch
export const getHospitalBranches = async (hospitalId: string) => {
  try {
    const { data, ok } = await api.get(`branch/getBranches/${hospitalId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getSections = async (branchId?: string) => {
  try {
    const { data, ok } = await api.get(`section/getSectionsList/${branchId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// section/getSections/64b7c5864306689b3629cb33/64b8d019f9008a7a5db35191
export const getSectionsSelected = async (
  hospitalId: string,
  branchId?: string
) => {
  try {
    const { data, ok } = await api.get(
      `section/getSections/${hospitalId}/${branchId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// /branch/deleteBranch/64b8d026f9008a7a5db35194
// export const deleteBranch = async (branchId: string) => {
//   try {
//     const { data, ok } = await api.delete(`/branch/deleteBranch/${branchId}`);
//     if (ok) {
//       return { res: data, err: null };
//     }
//   } catch (error) {
//     return { res: null, err: error };
//   }
// };

export const activateBranch = async (branchId: string) => {
  try {
    // /hospital/activateBranch/64b8d019f9008a7a5db35191
    const { data, ok } = await api.post(`/hospital/activateBranch/${branchId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { data: null, err: error };
  }
};

export const deActivateBranch = async (branchId: string) => {
  try {
    // /hospital/activateBranch/64b8d019f9008a7a5db35191
    const { data, ok } = await api.post(
      `/hospital/deActivateBranch/${branchId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { data: null, err: error };
  }
};
// /section/addSection/650d42bf101241855fa50344/650d768ada6eab9c2b6e5b3c
export const addSection = async (
  hospitalId: string,
  branchId?: string,
  sections?: any
) => {
  try {
    const { data, ok } = await api.post(
      `/section/addSection/${hospitalId}/${branchId}`,
      sections
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// /section/deleteSection/:hospitalId/:branch/:sectionId
// export const deleteSection = async (
//   hospitalId: string,
//   branchId?: string,
//   sectionId?: any,
// ) => {
//   try {
//     const { data, ok } = await api.delete(
//       `/section/deleteSection/${hospitalId}/${branchId}/${sectionId}`,
//     );
//     if (ok) {
//       return { res: data, err: null };
//     }
//   } catch (error) {
//     return { data: null, err: error };
//   }
// };
// {{healthcareLocal}}/hospital/activateSection/64b8f0404ce6dbb6fef3a906
export const activateSection = async (sectionId?: any) => {
  try {
    const { data, ok } = await api.post(
      `/hospital/activateSection/${sectionId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { data: null, err: error };
  }
};

export const deActivateSection = async (sectionId?: any) => {
  try {
    const { data, ok } = await api.post(
      `/hospital/deActivateSection/${sectionId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { data: null, err: error };
  }
};

// {{healthCareDev}}/section/editSection/:hospitalId/branch/sectionId
export const editSection = async (
  hospitalId: string,
  branchId?: string,
  sectionId?: any,
  sectionName?: string
) => {
  try {
    const bodyData = {
      sectionName: sectionName,
    };
    console.log("Body Data", bodyData);
    const { data, ok } = await api.put(
      `/section/editSection/${hospitalId}/${branchId}/${sectionId}`,
      bodyData
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { data: null, err: error };
  }
};

//   https://dev.api.healthcare.aquila360.com/branch/getBranches/:hospitalId

// Type : Get

// authorization : super admin Token

// get all branches with sections count

// https://dev.api.healthcare.aquila360.com/branch/addBranch/:hospitalId

// Type : Post

// auth : Super admin

// Body : {

//   "branchName": "Branch Number 3",

//   "address": "Model Town",

//   "contact": "John Doe",

//   "phoneNumber": "1234567890"

// }

// Add branch
