import React, { useState } from 'react';
import { Checkbox, Dropdown, IconButton, MenuItem, Switch } from '@heathmont/moon-core-tw';
import { ControlsChevronDown, Other3Dots } from '@heathmont/moon-icons';

type Column = {
    Header: () => JSX.Element;
    accessor: keyof DataRow; // Using DataRow type here
};


type DataRow = {
    check: JSX.Element;
    role: string;
    order: JSX.Element;
    action: JSX.Element;
    // ... other properties
};

const UserListing = () => {
    const columnsInitial: Column[] = [
        {
            Header: () => <Checkbox bgColor="bg-hit" />,
            accessor: 'check',
        },
        {
            Header: () => <div>User Role</div>,
            accessor: 'role',
        },
        {
            Header: () => <div>Order</div>,
            accessor: 'order',
        },
        {
            Header: () => <div>Action</div>,
            accessor: 'action',
        },
        // ... other columns
    ];



    type Cta = {
        name?: string;
    };

    const cta = [
        { name: 'Edit' },
    ];

    const [option, setOption] = useState<Cta>({ name: 'Choose a name...' });
    const [collapsedRows, setCollapsedRows] = useState<number[]>([]);

    const data: DataRow[] = [
        {
            check: <Checkbox bgColor="bg-hit" />,
            role: 'User role',
            order: (
                <div className='flex items-center justify-between'>
                    1
                    <div>
                        <IconButton
                            variant="ghost"
                            icon={<ControlsChevronDown />}
                            onClick={() => handleCollapseRow(0)}
                        />
                    </div>
                </div>
            ),
            action: (
                <Dropdown value={option} onChange={setOption}>
                    <Dropdown.Trigger
                        aria-label="Dropdown trigger"
                    >
                        <Other3Dots />
                    </Dropdown.Trigger>

                    <Dropdown.Options className='min-w-[200px] w-[200px]'>
                        {cta.map((cta, index) => (
                            <Dropdown.Option value={cta} key={index}>
                                {({ selected, active }) => (
                                    <MenuItem isActive={active} isSelected={selected}>
                                        {cta.name}
                                    </MenuItem>
                                )}
                            </Dropdown.Option>
                        ))}
                    </Dropdown.Options>
                </Dropdown>
            ),
        },
    ];

    const handleCollapseRow = (rowIndex: number) => {
        if (collapsedRows.includes(rowIndex)) {
            setCollapsedRows(collapsedRows.filter(index => index !== rowIndex));
        } else {
            setCollapsedRows([...collapsedRows, rowIndex]);
        }
    };

    const [state, setState] = useState(true);
    return (
        <div className="tableResponsive bg-[#F5F5F5] p-[8px] rounded-[8px]">
            <table className='w-full'>
                <thead>
                    <tr>
                        {columnsInitial.map((column, columnIndex) => (
                            <th className='text-left text-moon-16 text-[#000] font-normal' key={columnIndex}>{column.Header()}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((rowData, rowIndex) => {
                        const isCollapsed = collapsedRows.includes(rowIndex);
                        return (
                            <React.Fragment key={rowIndex}>
                                <tr>
                                    {columnsInitial.map((column, columnIndex) => (
                                        <td key={columnIndex}>
                                            {(rowData as any)[column.accessor]}
                                        </td>
                                    ))}
                                </tr>
                                {!isCollapsed && (
                                    <tr >
                                        <td colSpan={columnsInitial.length} >
                                            <div className='text-left p-[15px_30px_15px_56px]'>
                                                <h4 className='text-hit font-semibold mb-[18px]'>Access Permissions</h4>
                                                <div className="flex gap-4 mb-[18px]">
                                                    <div className="flex-1 text-moon-14 text-gray-600 ">
                                                        Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat ultricies leo nisi ornare tortor mattis pellentesque.
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            checked={state}
                                                            onChange={setState}
                                                            size="xs"
                                                            name="notification"
                                                            id="notification"
                                                            value="on"
                                                            className="bg-beerus moon-checked:bg-cyan-500"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-[18px]">
                                                    <div className="flex-1 text-moon-14 text-gray-600 ">
                                                        Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat ultricies leo nisi ornare tortor mattis pellentesque.
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            checked={state}
                                                            onChange={setState}
                                                            size="xs"
                                                            name="notification"
                                                            id="notification"
                                                            value="on"
                                                            className="bg-beerus moon-checked:bg-cyan-500"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-[18px]">
                                                    <div className="flex-1 text-moon-14 text-gray-600 ">
                                                        Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat ultricies leo nisi ornare tortor mattis pellentesque.
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            checked={state}
                                                            onChange={setState}
                                                            size="xs"
                                                            name="notification"
                                                            id="notification"
                                                            value="on"
                                                            className="bg-beerus moon-checked:bg-cyan-500"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-[18px]">
                                                    <div className="flex-1 text-moon-14 text-gray-600 ">
                                                        Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat ultricies leo nisi ornare tortor mattis pellentesque.
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            checked={state}
                                                            onChange={setState}
                                                            size="xs"
                                                            name="notification"
                                                            id="notification"
                                                            value="on"
                                                            className="bg-beerus moon-checked:bg-cyan-500"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex gap-4 mb-[18px]">
                                                    <div className="flex-1 text-moon-14 text-gray-600 ">
                                                        Lorem ipsum dolor sit amet consectetur. Vitae diam volutpat ultricies leo nisi ornare tortor mattis pellentesque.
                                                    </div>
                                                    <div>
                                                        <Switch
                                                            checked={state}
                                                            onChange={setState}
                                                            size="xs"
                                                            name="notification"
                                                            id="notification"
                                                            value="on"
                                                            className="bg-beerus moon-checked:bg-cyan-500"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default UserListing;
