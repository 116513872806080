import { FILTER_SELECT } from "../../enums/branch-admin/filters.enums";
import {
  PATIENTS_FILTER,
  EMPLOYEES_FILTER,
  REQUEST_TIMEOFF_FILTER,
  LEAVES_FILTER,
} from "../../enums/branch-admin/viewsFilter.enums";

interface FilterOpt {
  name: string;
}

// Define a mapping between filters and their corresponding options
const filterOptionsMap: Record<string, FilterOpt[]> = {
  [PATIENTS_FILTER.ID]: FILTER_SELECT.PAT_ID,
  [PATIENTS_FILTER.PATIENT_NAME]: FILTER_SELECT.PAT_NAME,
  [PATIENTS_FILTER.ADMISSION_DATE]: FILTER_SELECT.PAT_ADD_DIS_DATE,
  [PATIENTS_FILTER.DISCHARGE_DATE]: FILTER_SELECT.PAT_ADD_DIS_DATE,
  [PATIENTS_FILTER.ALLOCATED_SECTION]: FILTER_SELECT.PAT_ALLOCATED_SECTION,
  [PATIENTS_FILTER.ASSIGNED_ROOM]: FILTER_SELECT.PAT_ASSIGNED_ROOM,
  [PATIENTS_FILTER.NEEDY_PROFILE]: FILTER_SELECT.PAT_NEEDY_PROFILE,

  [EMPLOYEES_FILTER.ID]: FILTER_SELECT.EMP_ID,
  [EMPLOYEES_FILTER.EMPLOYEE_NAME]: FILTER_SELECT.EMP_NAME,
  [EMPLOYEES_FILTER.DESIGNATION]: FILTER_SELECT.EMP_DESIGNATION,
  [EMPLOYEES_FILTER.JOINING_DATE]: FILTER_SELECT.EMP_JOINING_DATE,
  [EMPLOYEES_FILTER.APPOINTED_SECTION]: FILTER_SELECT.EMP_APPOINTED_SECTION,
  [EMPLOYEES_FILTER.SENIORITY]: FILTER_SELECT.EMP_SENIORITY_LEVEL,

  [REQUEST_TIMEOFF_FILTER.ID]: FILTER_SELECT.EMP_ID,
  [REQUEST_TIMEOFF_FILTER.EMPLOYEE_NAME]: FILTER_SELECT.EMP_NAME,
  [REQUEST_TIMEOFF_FILTER.STATUS]: FILTER_SELECT.EMP_RTO_LEAVE_STATUS,
  [REQUEST_TIMEOFF_FILTER.SECTION]: FILTER_SELECT.EMP_RTO_SECTION,
  [REQUEST_TIMEOFF_FILTER.MONTH]: FILTER_SELECT.EMP_RTO_MONTH,
  [REQUEST_TIMEOFF_FILTER.YEAR]: FILTER_SELECT.EMP_RTO_YEAR,
  [REQUEST_TIMEOFF_FILTER.DATE]: FILTER_SELECT.EMP_RTO_LEAVES,

  [LEAVES_FILTER.ID]: FILTER_SELECT.EMP_ID,
  [LEAVES_FILTER.EMPLOYEE_NAME]: FILTER_SELECT.EMP_NAME,
  [LEAVES_FILTER.EMPLOYEE_SECTION]: FILTER_SELECT.EMP_LE_EMPLOYEE_SECTION,
  [LEAVES_FILTER.LEAVE_TYPE]: FILTER_SELECT.EMP_LEAVE_TYPE,
  [LEAVES_FILTER.STATUS]: FILTER_SELECT.EMP_LE_EMPLOYEE_LEAVE_STATUS,
  [LEAVES_FILTER.LEAVES_FROM_TO_DATE]: FILTER_SELECT.EMP_LEAVES_FROM_TO,
};

export const getFilterOption = (optionParam: string): FilterOpt[] => {
  const filterOptions = filterOptionsMap[optionParam] || [
    { name: "Select an Option" },
  ];
  return filterOptions;
};
