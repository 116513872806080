import {
  Accordion,
  Button,
  Drawer,
  IconButton,
  MenuItem,
} from "@heathmont/moon-core-tw";
import {
  ControlsChevronDownSmall,
  ControlsClose,
  ControlsPlus,
  GenericAlarmRound,
  GenericBlock,
  GenericDelete,
  GenericEdit,
  GenericPlus,
  OtherFrame,
} from "@heathmont/moon-icons";
import React, { useEffect, useState } from "react";
import AddSection from "./AddSection";
import AddBranch from "./AddBranch";
import { IHospital } from "../../../../../types/super-admin-interfaces/IHospital.interface";
import ConfirmationModal from "./ConfirmationModal";
import { IBranch } from "../../../../../types/super-admin-interfaces/IBranch.interface";
import { IBranchData } from "../../../../../types/super-admin-interfaces/IBranchData.interface";
import ModalEditSection from "./ModalEditSection";

interface DrawerProps {
  isOpenDrawer: boolean;
  closeDrawer: () => void;
  setIsOpenDrawer: (isOpen: boolean) => void; // Assuming this function sets the open state
  hospitalData: IHospital;
  addHospitalBranch: (params: any) => Promise<void>;
  hospitalBranchesData?: IBranch[];
  getAllSectionsList: (data: string) => void;
  editBranchData: (data: IBranchData) => void;
  editHospitalBranch: (data?: any, dataId?: any) => void;
  activateHospitalBranch: (data: string) => void;
  deActivateHospitalBranch: (data: string) => void;
  setBranchData: (data: any) => void;
  getSectionsList: (data: string) => void;
  addSectionsList: (data?: string, sections?: any) => void;
  sectionsList: any;
  branchData: any;
  getSectionData: string[];
  deActivateHospitalSection: (branchId?: string, sectionId?: string) => void;
  activateHospitalSection: (branchId?: string, sectionId?: string) => void;
  editHospitalSection: (branchId?: string, sectionId?: string) => void;
}

// Sample data for sections
const sections = [
  {
    id: 1,
    name: "Section 1",
    address: "1250 N Cable Rd, Lima, OH",
    count: 4,
  },
  {
    id: 2,
    name: "Section 2",
    address: "1250 N Cable Rd, Lima, OH",
    count: 2,
  },
  {
    id: 3,
    name: "Section 3",
    address: "1250 N Cable Rd, Lima, OH",
    count: 1,
  },
];

const AddSectionDrawer = ({
  isOpenDrawer,
  setIsOpenDrawer,
  closeDrawer,
  hospitalData,
  addHospitalBranch,
  hospitalBranchesData,
  getAllSectionsList,
  editHospitalBranch,
  activateHospitalBranch,
  deActivateHospitalBranch,
  setBranchData,
  getSectionsList,
  sectionsList,
  branchData,
  addSectionsList,
  getSectionData,
  activateHospitalSection,
  deActivateHospitalSection,
  editHospitalSection,
}: DrawerProps) => {
  const [isOpenModalSection, setOpenModalSection] = useState(false);
  const [isOpenModalBranch, setOpenModalBranch] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [sectionData, setSectionData] = useState<string>();
  const [branchId, setBranchId] = useState<any>();
  const [openAccordion, setOpenAccordion] = useState<string>();
  const [branchEditData, setBranchEditData] = useState<any>();
  const closeConfirmModal = () => setIsOpenConfirm(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const closeModal = () => setIsOpen(!isOpen);

  const handleAccordionToggle = (branch: any) => {
    setOpenAccordion(branch?.branch?._id);
    if (!openAccordion?.includes(branch?.branch?._id)) {
      setBranchData({ ...branch?.branch });
      getSectionsList(branch?.branch?._id);
    }
  };

  const handleModalSection = () => {
    setOpenModalSection(true);
  };

  const handleModalBranch = () => {
    setOpenModalBranch(true);
  };

  const closeModalBranch = () => {
    setOpenModalBranch(false);
    // setBranchEditData("");
  };

  const closeModalSection = () => {
    setOpenModalSection(false);
  };

  const [confirmationData, setConfirmationData] = useState<any>(null);

  const openConfirmationModal = (data: any) => {
    setConfirmationData(data);
    setIsOpenConfirm(true);
  };

  const handleConfirmAction = () => {
    if (confirmationData?.actionFor === "Branch") {
      deActivateHospitalBranch(confirmationData?.id?.branchId);
    } else if (confirmationData?.actionFor === "Section") {
      deActivateHospitalSection(
        confirmationData?.id?.branchId,
        confirmationData?.id?.sectionId
      );
    }
  };
  useEffect(() => {
    setOpenAccordion("");
  }, [isOpenDrawer]);
  // const [isOpenModal, setIsOpenBranch] = useState(false);
  // // const closeModalBranch = () => setIsOpenBranch(false);

  // const openBranch = () => {
  //   setIsOpenBranch(true);
  // };

  return (
    <>
      <Drawer open={isOpenDrawer} setOpen={setIsOpenDrawer}>
        <Drawer.Panel className="flex flex-col">
          <div className="flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
            <h3 className="text-moon-20 font-semibold">
              Branches and Sections
            </h3>
            <IconButton
              onClick={closeDrawer}
              variant="ghost"
              icon={<ControlsClose />}
            />
          </div>
          <div className="px-4 py-2">
            <p className="mt-2 mb-4 text-moon-14 text-gray-600">
              Click on <GenericPlus className="inline" /> icon to add/view a
              section to the related branch
            </p>
            <div className="flex flex-wrap  justify-between items-center">
              <p className="text-moon-16 text-gray-600">
                {" "}
                Total Branches{""}
                <span className="font-normal text-moon-12 inline-flex items-center justify-center bg-hit text-white rounded-full ms-1 py-[2px] px-[6px] min-w-[20px]">
                  {hospitalData?.numberOfBranches}
                </span>
              </p>
              <Button
                variant="tertiary"
                iconLeft={<ControlsPlus />}
                onClick={() => {
                  handleModalBranch();
                  setBranchEditData("");
                }}
              >
                Add Branch{" "}
              </Button>
            </div>
          </div>

          <div className="px-4 py-2 flex-1 overflow-auto ">
            <Accordion singleOpen>
              {hospitalBranchesData?.map((branch: IBranch) => (
                <Accordion.Item value={branch.branch._id}>
                  <div
                    // key={branch.branch._id}
                    className="bg-[#F8F8F8] p-3 mt-[5px]"
                  >
                    <div className=" flex gap-3 items-center rounded-md ">
                      <div className=" flex-1 ">
                        <span className="text-moon-14 text-gray-600">
                          {branch.sectionsCount} Sections
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        {branch?.branch?.isActive ? (
                          <Button
                            size="sm"
                            variant="ghost"
                            className="text-primary font-normal"
                            iconLeft={<GenericEdit />}
                            onClick={() => {
                              setBranchData({ ...branch?.branch });
                              handleModalSection();
                              getAllSectionsList(branch?.branch?._id);
                              // getSectionsList(branch?.branch?._id);
                            }}
                          >
                            Manage Sections
                          </Button>
                        ) : (
                          <>
                            <GenericAlarmRound />
                            <span>Activate to Manage Branch</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <div className="flex-1">
                        <h4 className="text-moon-18 text-blackcst">
                          {branch.branch.branchName}
                        </h4>
                      </div>
                      {branch?.branch?.isActive ? (
                        <div className="flex items-center gap-2">
                          {/* {branch?.branch?.isActive ? ( */}
                          {/* <> */}
                          <IconButton
                            size="sm"
                            variant="ghost"
                            className="text-blackcst rounded-full text-moon-32 flex-[0_0_32px] h-[32px] w-[32px]"
                            icon={<GenericEdit />}
                            onClick={() => {
                              // editBranchData && editBranchData(branch.branch);
                              setBranchEditData(branch?.branch);
                              // setIsOpenConfirm(true);
                              handleModalBranch();
                            }}
                          />

                          <IconButton
                            size="sm"
                            variant="ghost"
                            className="text-blackcst rounded-full text-moon-32 flex-[0_0_32px] h-[32px] w-[32px]"
                            icon={<GenericBlock />}
                            onClick={() => {
                              openConfirmationModal({
                                title: "Confirm Branch De-Activate",
                                actionFor: "Branch",
                                message: (
                                  <p className="text-moon-18 mb-[28px]">
                                    {" "}
                                    The Branch{" "}
                                    <strong className="font-semibold ">
                                      {branch?.branch?.branchName}
                                    </strong>
                                    <br />
                                    and all its related services will be
                                    de-activated
                                  </p>
                                ),
                                confirmAction: "De-Activate",
                                id: {
                                  branchId: branch?.branch?._id,
                                },
                              });
                            }}
                          />

                          <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-transparent p-0">
                            <Accordion.Button className="acrdianbtn p-0 ps-[-10px] w-auto">
                              <IconButton
                                variant="ghost"
                                size="sm"
                                className="rounded-full h-[32px] w-[32px] ps-0"
                                onClick={() => {
                                  handleAccordionToggle(branch);
                                }}
                              >
                                <ControlsChevronDownSmall className="text-trunks text-moon-32 transition duration-200 moon-open:text-bulma p-0" />
                              </IconButton>
                            </Accordion.Button>
                          </Accordion.Header>
                        </div>
                      ) : (
                        <Button
                          variant="primary"
                          size="sm"
                          className="bg-[rgba(36,156,186,0.15)] text-hit"
                          onClick={() => {
                            activateHospitalBranch(branch?.branch._id);
                            // setOpenAccordion("");
                            // getSectionsList(branch?.branch._id);
                          }}
                        >
                          Activate
                        </Button>
                      )}
                    </div>
                    <Accordion.Content className="bg-[#F5F5F5] rounded-md">
                      <div className="w-full">
                        <h4 className="text-moon-14 text-bulma">
                          Selected Sections
                        </h4>
                        <p className="text-gray-400 text-moon-12">
                          Clicking on{" "}
                          <span className="inline-block">
                            <GenericBlock />
                          </span>{" "}
                          will blocked the section and its all data.
                        </p>

                        <div className="mt-3">
                          {sectionsList &&
                            sectionsList?.map((section: any) => (
                              <MenuItem as="div" className="py-1">
                                <div className="flex-1">
                                  {section?.sectionName}
                                </div>
                                {section?.isActive ? (
                                  <>
                                    <IconButton
                                      size="sm"
                                      variant="ghost"
                                      className="text-blackcst rounded-full text-moon-32 h-[32px] w-[32px]"
                                      icon={<GenericBlock />}
                                      onClick={() => {
                                        // setIsOpenConfirm(true);
                                        // setBranchId(branch?.branch?._id);
                                        setSectionData(section);
                                        openConfirmationModal({
                                          title: "Confirm Section De-Activate",
                                          message: (
                                            <p className="text-moon-18 mb-[28px]">
                                              {" "}
                                              The Section{" "}
                                              <strong className="font-semibold ">
                                                {section?.sectionName}
                                              </strong>
                                              <br /> and all its related
                                              services will be de-activated
                                            </p>
                                          ),
                                          confirmAction: "De-Activate",
                                          actionFor: "Section",
                                          id: {
                                            branchId: branch?.branch?._id,
                                            sectionId: section?._id,
                                          },
                                        });
                                      }}
                                    />
                                    <IconButton
                                      size="sm"
                                      variant="ghost"
                                      className="text-blackcst rounded-full text-moon-32 h-[32px] w-[32px]"
                                      icon={<GenericEdit />}
                                      onClick={() => {
                                        // setIsOpenConfirm(true);
                                        setIsOpen(true);
                                        setSectionData(section);
                                        setBranchId(branch?.branch?._id);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    className="bg-[rgba(36,156,186,0.15)] text-hit"
                                    onClick={() => {
                                      activateHospitalSection(
                                        section?._id as string,
                                        branch?.branch?._id as string
                                      );
                                      // setOpenAccordion(() => {
                                      //   return "";
                                      // });
                                    }}
                                  >
                                    Activate
                                  </Button>
                                )}
                              </MenuItem>
                            ))}
                          {/* <MenuItem as="div" className="py-1">
                            <div className="flex-1">Pathology</div>
                            <IconButton
                              size="sm"
                              variant="ghost"
                              className="text-blackcst rounded-full text-moon-32 h-8 w-8"
                              icon={<GenericDelete />}
                            />
                          </MenuItem>
                          <MenuItem as="div" className="py-1" >
                            <div className="flex-1">
                              Neurology
                            </div>
                            <IconButton
                              size="sm"
                              variant="ghost"
                              className="text-blackcst rounded-full text-moon-32 h-8 w-8"
                              icon={<GenericDelete />}

                            />
                          </MenuItem>
                          <MenuItem as="div" className="py-1" >

                            <div className="flex-1">
                              Pathology
                            </div>
                            <IconButton
                              size="sm"
                              variant="ghost"
                              className="text-blackcst rounded-full text-moon-32 h-8 w-8"
                              icon={<GenericDelete />}

                            />
                          </MenuItem>
                          <MenuItem as="div" className="py-1" >
                            <div className="flex-1">
                              ICU
                            </div>
                            <IconButton
                              size="sm"
                              variant="ghost"
                              className="text-blackcst rounded-full text-moon-32 h-8 w-8"
                              icon={<GenericDelete />}

                            />
                          </MenuItem>
                          <MenuItem as="div" className="py-1" >
                            <div className="flex-1">
                              Dermatology
                            </div>
                            <IconButton
                              size="sm"
                              variant="ghost"
                              className="text-blackcst rounded-full text-moon-32 h-8 w-8"
                              icon={<GenericDelete />}

                            />
                          </MenuItem> */}
                        </div>
                      </div>
                    </Accordion.Content>
                  </div>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Drawer.Panel>

        <Drawer.Backdrop />
      </Drawer>
      <AddSection
        branchData={branchData}
        isOpenModalSection={isOpenModalSection}
        closeModalSection={closeModalSection}
        getAllSectionsList={getSectionData as string[]}
        sectionsList={sectionsList}
        addSectionsList={addSectionsList}
      />

      <AddBranch
        isOpenModal={isOpenModalBranch}
        closeModalBranch={closeModalBranch}
        postBranchData={addHospitalBranch}
        editBranchData={branchEditData}
        editHospitalBranch={editHospitalBranch}
      />

      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
      <ModalEditSection
        isOpen={isOpen}
        closeModal={closeModal}
        sectionData={sectionData}
        branchId={branchId}
        editHospitalSection={editHospitalSection}
      />
    </>
  );
};

// deleteHospitalSection(
//   branch?.branch?._id,
//   section?._id,
// )
export default AddSectionDrawer;
