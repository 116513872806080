import { Drawer, IconButton } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";

import React, { useEffect, useState } from "react";
import EmployeInfo from "./EmployeContact";
import EmployeeDetail from "./EmployeeDetail";
import LeavesCard from "../../Widgets/cards/leave-card/LeavesCard";
import RecentSchedule from "./RecentSchedule";
import EmployeeRecentMeetings from "./EmployeeRecentMeetings";
import {
  IEmployee,
  IEmployeeDetails,
  ILeave,
} from "../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { IShift } from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
interface DrawerEmployeeProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  employeeInfo?: IEmployeeDetails;
}

const DrawerEmployee: React.FC<DrawerEmployeeProps> = ({
  isOpen,
  setIsOpen,
  handleClose,
  employeeInfo,
}) => {
  // const [employee, setEmployee] = useState<any>(employeeInfo?.employee);
  // const [leaves, setLeaves] = useState<any>(employeeInfo?.leaves);

  // const { employee, leaves } = employeeInfo;
  return (
    <Drawer open={isOpen} setOpen={setIsOpen}>
      <Drawer.Panel className="max-w-[741px] flex flex-col">
        <div className="flex justify-between items-center p-3 border-b border-beerus">
          <h3 className="text-moon-20 font-bold transition-colors">Employee</h3>
          <IconButton variant="ghost" onClick={handleClose}>
            <ControlsCloseSmall />
          </IconButton>
        </div>
        <EmployeInfo employee={employeeInfo?.employee as IEmployee} />
        <div className="flex flex-wrap  border-1 border-t border-beerus">
          <div className="w-1/2  flex flex-col h-full ">
            <EmployeeDetail employee={employeeInfo?.employee as IEmployee} />
          </div>
          <div className="w-1/2 bg-slate-50 border-1 border-l border-beerus">
            <RecentSchedule employeeData={employeeInfo?.shifts as IShift[]} />

            {/* <EmployeeRecentMeetings /> */}
            <div className=" h-full">
              <LeavesCard leaves={employeeInfo?.leaves as ILeave[]} />
            </div>
          </div>
        </div>
      </Drawer.Panel>
      <Drawer.Backdrop />
    </Drawer>
  );
};

export default DrawerEmployee;
