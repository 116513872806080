import { api } from "../../utils/axios";
export const getDashboardData = async (sectionId: string) => {
  try {
    const { data, ok } = await api.get(
      `/nurseManagerDashboard/getSectionInfo?assignedSection=${sectionId}`
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
