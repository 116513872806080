import React from "react";
import { flexRender } from "@tanstack/react-table";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { generatePageNumbers } from "../../../utils/generatePageNumbers";
import { IconButton } from "@heathmont/moon-core-tw";
import {
  ArrowsSorting,
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
} from "@heathmont/moon-icons";
import { ArrowsUp } from "@heathmont/moon-icons-tw";
import { ISectionsTable } from "../../../types/branch-admin-interfaces/ISectionsView.interface";

interface Props {
  table: any;
  tableData: ISectionsTable;
}

const SectionsTable: React.FC<Props> = ({ table, tableData }) => {
  if (!tableData || !tableData?.sectionsWithRules) {
    return (
      <div className="sectionsvg flex items-center justify-center text-center">
        {/* You can render a loading indicator or an error message here */}
        {/* Loading or No Data Available */}
        <img
          src={"images/no-schedule-to-approve.svg"}
          alt="No schedule to approve"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="branchtable overflow-y-auto">
        <table className="w-full">
          <thead>
            {table?.getHeaderGroups()?.map((headerGroup: any) => (
              <tr key={headerGroup?.id}>
                {headerGroup?.headers?.map((header: any) => (
                  <th
                    key={header?.id}
                    colSpan={header.colSpan}
                    className="text-left border py-4 px-5 text-moon-12 capitalize"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? " select-none flex justify-between"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() == "desc" ? (
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] rotate-180 cursor-pointer`}
                            icon={<ArrowsUp />}
                          />
                        ) : header.column.getIsSorted() == "asc" ? (
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] cursor-pointer`}
                            icon={<ArrowsUp />}
                          />
                        ) : header.column.columnDef.enableSorting !== false ? (
                          // <>⬍{console.log("A", header.column)}</>
                          <IconButton
                            size="xs"
                            variant="ghost"
                            className={` p-0 h-[24px] cursor-pointer`}
                            icon={<ArrowsSorting />}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel()?.rows?.map((row: any) => (
              <tr key={row?.id}>
                {row?.getVisibleCells()?.map((cell: any) => (
                  <td
                    key={cell?.id}
                    className="text-left border py-2 px-5 text-moon-14"
                  >
                    {flexRender(
                      cell?.column?.columnDef?.cell,
                      cell?.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table?.getFooterGroups()?.map((footerGroup: any) => (
              <tr key={footerGroup?.id}>
                {footerGroup?.headers?.map((header: any) => (
                  <th key={header?.id}>
                    {header?.isPlaceholder
                      ? null
                      : flexRender(
                          header?.column?.columnDef?.footer,
                          header?.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {/*  */}
      </div>
    </div>
  );
};

export default SectionsTable;
