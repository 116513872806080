import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";
import { store } from "../../../../../store/store";
import { setDate } from "../../../../../features/nurse-manager/Time Slice/timeSlice";

interface Option {
  name: string;
  value: number;
}

interface NeedStatsProps {
  value: any;
  statsNumber: number;
  title: string;
  options: any;
  bgColor: string;
  textColor: string;
  setSelectedMonthOption?: any;
  selectedMonthOption?: any;
}

const NeedStats: React.FC<NeedStatsProps> = ({
  value,
  statsNumber,
  title,
  options,
  bgColor,
  textColor,
  setSelectedMonthOption,
  selectedMonthOption,
}) => {
  const [option, setOption] = useState<any>(value);
  // const handleOptionChange = (selectedItem: any) => {
  //   console.log(selectedItem);
  //   store.dispatch(
  //     setDate({
  //       month: selectedItem.monthName,
  //       currentMonth: selectedItem?.label,
  //     }),
  //   );
  // };

  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md flex`}>
      <div className="flex-1">
        <h3 className="text-moon-14">{title}</h3>
        <div className="font-semibold text-[32px] leading-[40px]">
          {statsNumber}
        </div>
      </div>
      <div>
        {/* <Dropdown
          value={option}
          onChange={(option) => {
            setOption(option);
            setSelectedMonthOption(option);
          }}
          position="bottom-end"
        >
          <Dropdown.Trigger className="inline-flex items-center cursor-pointer">
            <span className="text-moon-14 cursor-pointer ">
              {selectedMonthOption?.name}
            </span>
            <ControlsChevronDown className="ml-2" />
          </Dropdown.Trigger>
          <Dropdown.Options className="min-w-[100px]">
            {options.map((option: any, index: number) => (
              <Dropdown.Option value={option} key={index}>
                {({ selected, active }) => (
                  <MenuItem
                    isActive={active}
                    isSelected={selected}
                    className="text-moon-12"
                    value={option.value}
                  >
                    {option.name}
                  </MenuItem>
                )}
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </Dropdown> */}
      </div>
    </div>
  );
};

export default NeedStats;
