import { ICreateDraft } from "../../Components/nurse-manager/Widgets/modal/SaveAsDraftModal";
import { api } from "../../utils/axios";

export const createDraftOfSchedule = async (args: ICreateDraft) => {
  try {
    const { data, ok } = await api.post(
      `/schedule/createDraft/${args?.tempScheduleId}`,
      args?.reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getDraftsOfSchedules = async () => {
  try {
    const { data, ok } = await api.get(`/schedule/getDrafts`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const getDraft = async (draftId: string) => {
  try {
    const { data, ok } = await api.get(`/schedule/getDraft/${draftId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const deleteDraft = async (draftId: string) => {
  try {
    const { data, ok } = await api.delete(`/schedule/deleteDraft/${draftId}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
