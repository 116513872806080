import React, { useEffect, useState } from "react";
import { getPatientsView } from "../../../services/branch-admin-services/views.service";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "../../../Components/branch-admin/ViewTable/table";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { IPatient } from "../../../types/branch-admin-interfaces/IPatients.interface";
import {
  Chip,
  Input,
  Label,
  Modal,
  Radio,
  Button,
  Select,
  Dropdown,
  MenuItem,
  PrevButton,
  IconButton,
} from "@heathmont/moon-core-tw";
import ReactDatePicker from "react-datepicker";
import { set } from "react-hook-form";
import { getSections } from "../../../services/branch-admin-services/section.service";
import PatientEditCreateModal from "../../../Components/branch-admin/ui/PatientEditCreateModal";
import {
  postPatient,
  updatePatient,
} from "../../../services/branch-admin-services/patient.service";
import { setSnackBar, setToastMessage } from "../../../features/ToastSlice/toastSlice";
import { store } from "../../../store/store";
import { GenericEdit } from "@heathmont/moon-icons-tw";
interface Props {
  selectedButton: string;
  dataImported: boolean;
  searchQuery: string;
  filtersArray: IFilterParameters[];
  isApplyFilter: boolean;
}

interface ProfileData {
  _id?:string;
  patientId: string;
  status: string | undefined;
  critical: boolean;
  patientName: string;
  admissionDate: Date | null;
  dischargeDate: Date | null;
  sectionId: string;
  allocatedSection: string;
  assignedRoom: string;
  referredDoctor: string;
  needyProfile: string |undefined;
}

const PatientsView: React.FC<Props> = ({
  selectedButton,
  dataImported,
  searchQuery,
  filtersArray,
  isApplyFilter,
}) => {
  const columnHelper = createColumnHelper<IPatient>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [sections, setSections] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState<ProfileData>(
    {
      patientId: '',
      status: "Active",
      critical: false,
      patientName: '',
      admissionDate: new Date() ,
      dischargeDate: new Date(),
      sectionId: '',
      allocatedSection: '',
      assignedRoom:'' ,
      referredDoctor: '',
      needyProfile: "Low"
    } as ProfileData
  );
  const [edit, setEdit] = useState(false);
  const columns = [
    columnHelper.accessor("patientId", {
      header: () => <span>ID</span>,
      cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.patientName, {
      id: "patientName",
      header: () => <span>Patient Name</span>,
      cell: (info) => (
        <div className="flex justify-between items-center border-none cursor-pointer">
          <span className="truncate max-w-[200px] inline-flex">
            {info.getValue()}
          </span>
          {info?.row?.original?.critical && (
            <div className=" justify-items-end">
              <Chip
                size="sm"
                className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold text-dodoria bg-dodoria-10`}
              >
                {"Critical"}
              </Chip>
            </div>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("admissionDate", {
      enableSorting: false,
      header: () => "Admission",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("dischargeDate", {
      enableSorting: false,
      header: () => <span>Discharge</span>,
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("allocatedSection", {
      enableSorting: false,
      header: "Allocated Section",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("assignedRoom", {
      enableSorting: false,
      header: "Assigned Room",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("referredDoctor", {
      enableSorting: false,
      header: "Referred Doctor",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("needyProfile", {
      enableSorting: false,
      header: "Needy Profile",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),

    columnHelper.accessor("status", {
      enableSorting: false,
      header: "status",
      cell: (info) => (
        <span
          className={
            info.renderValue() === "Active"
              ? `bg-[rgba(36,156,186,0.12)] text-[#249CBA] rounded-moon-i-sm px-3 py-1`
              : `bg-dodoria-10 text-dodoria rounded-moon-i-sm px-3 py-1`
          }
        >
          {info.renderValue()}
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      enableSorting: false,
      header: "Action",
      cell: (info) => (
      <IconButton
        variant="ghost"
        icon={<GenericEdit />}
        onClick={() => {
            editHandler(info.row.original);
        }}
      />
      ),
    }),
  ];

  const [data, setData] = useState(() => []);
  const [patientsViewRes, setPatientsViewRes] = useState<ITableDataResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let timeoutId: NodeJS.Timeout | null = null;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const getPatientsData = async (pageChange: number, searchQuery?: string) => {
    const params = {
      pageChange: pageChange,
      selectedButton: selectedButton,
      limit: 50,
      search: searchQuery,
      filterParams: filtersArray,
    };
    setIsLoading(true);
    const res = await getPatientsView(params);
    setIsLoading(false);
    setData(res?.records);
    setPatientsViewRes(res);
  };
  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getPatientsData(1, searchQuery);
      }, 1000);
    } else {
      getPatientsData(1);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [selectedButton, dataImported, searchQuery, isApplyFilter]);

  const [passwordError, setPasswordError] = useState("");

  // Function to handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!edit) {
      let data: any = { ...profileData };
      data.admissionDate = formatDate(profileData.admissionDate as Date);
      data.dischargeDate = formatDate(profileData.dischargeDate as Date);
      console.log("data", data);
      const res: any = await postPatient(data);
      if (!res.err) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Patient Added Successfully"));
        await getPatientsData(1);
        closeModal();
      }else {
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage(res?.err?.message));
      }

    } else {
      let data: any = { ...profileData };
      data.admissionDate = formatDate(profileData.admissionDate as Date);
      data.dischargeDate = formatDate(profileData.dischargeDate as Date);
      const res: any = await updatePatient(data);
      if (!res.err) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Patient Edit Successfully"));
        await getPatientsData(1);
        closeModal();
      }else  store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage(res?.err?.message));
    }
    
    // Implement your form submission logic here
  };
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setEdit(false);
    setProfileData({
      patientId: '',
      status: "Active",
      critical: false,
      patientName: '',
      admissionDate: new Date() ,
      dischargeDate: new Date(),
      sectionId: '',
      allocatedSection: '',
      assignedRoom:'' ,
      referredDoctor: '',
      needyProfile: "Low",
    } as ProfileData);
  };

  function formatDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  function dateStringToDate(dateString: string) {
    const parts = dateString.split("/"); // Split the string by "/"
    const month = parseInt(parts[0], 10) - 1; // Convert month part to integer (subtract 1 as months are zero-indexed)
    const day = parseInt(parts[1], 10); // Convert day part to integer
    const year = parseInt(parts[2], 10); // Convert year part to integer
  
    // Create Date object
    const dateObject = new Date(year, month, day);
    console.log('date object', dateObject);
    return dateObject;
  }
  

  const editHandler = (info: any) => {
    setEdit(true);
    let editData = { ...info };
    editData.admissionDate = dateStringToDate(editData.admissionDate);
    editData.dischargeDate = dateStringToDate(editData.dischargeDate);
    setProfileData(editData as ProfileData);
    setIsOpen(true);
  };

  const getSectionsData = async () => {
    const response: any = await getSections();
    if (!response.err) {
      setSections(response?.sections);
    }
  };

  useEffect(() => {
    getSectionsData();
  }, []);

  return (
    <div>
      <div className="w-full px-[16px] text-right">
        <div className="flex items-center justify-end gap-2 py-1">
          <Button
            variant="tertiary"
            className="ml-aut text-white"
            onClick={openModal}
          >
            Add Patient
          </Button>
        </div>
      </div>

      {!isLoading && (
        <Table
          header={true}
          table={table}
          tableData={patientsViewRes as ITableDataResponse}
          handlePageChange={getPatientsData}
        />
      )}
      <PatientEditCreateModal
        isOpen={isOpen}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        handleSubmit={handleSubmit}
        sections={sections}
        edit={edit}
      />
    </div>
  );
};

export default PatientsView;
