import { useRef, useState, useEffect } from "react";
import PageTitle from "../../../Components/branch-admin/ui/PageTitle";
import { Button, Input } from "@heathmont/moon-core-tw";
import { MailFilter } from "@heathmont/moon-icons";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { handleViewSelect } from "../../../utils/branch-admin/viewSelectOptions";
import FilterTabs from "../../../Components/branch-admin/ui/FilterTabs";
import { VIEWS } from "../../../enums/branch-admin/views.enums";
import FilterChips from "../../../Components/branch-admin/ui/FilterChips";
import FilterDrawer from "../../../Components/branch-admin/ui/FilterDrawer";
import ViewSelect from "../../../Components/branch-admin/ui/ViewSelect";
import moment from "moment";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import {
  getFilePattern,
  importData,
} from "../../../services/branch-admin-services/files.service";
interface Options {
  name: string;
}
interface FilterElements {
  id: number;
}

const AdminViews = () => {
  const data = useSelector((state: RootState) => state?.auth?.User);
  const fileInputRef = useRef<HTMLInputElement>();
  const currentView = JSON.parse(sessionStorage.getItem("viewOption")!);
  const [viewOption, setViewOption] = useState<string | null>(currentView);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedButton, setSelectedButton] = useState<string>("active");
  const [dataImported, setDataImported] = useState<boolean>(false);
  const [nextId, setNextId] = useState<number>(0);
  const [filtersElements, setFiltersElements] = useState<FilterElements[]>([]);
  const [filtersArray, setFiltersArray] = useState<IFilterParameters[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<Options[]>(
    []
  );
  const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
  const [filtersChipsArray, setFiltersChipsArray] = useState<
    IFilterParameters[]
  >([]);
  const links = [
    { to: "/home", label: "Active Patients", value: "active" },
    { to: "/home", label: "Historic Patients", value: "historic" },
  ];

  const filterOptions: Options[] = handleViewSelect(viewOption);
  const selectedOptions = filtersArray?.map((filter) => {
    return filter?.option;
  });
  let options;
  options = filterOptions?.filter((opt) => {
    return !selectedOptions.includes(opt.name);
  });

  const newFilter: IFilterParameters = {
    id: nextId,
    option: "",
    filter: "",
    value: "",
  };

  const match: Options[] = selectedFilterOptions;
  const handleButtonClick = (button: string) => {
    setSelectedButton(button);
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile: any = event.target.files && event.target.files[0];
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("csvFile", selectedFile);
        if (selectedFile?.type === "text/csv") {
          const response: any = await importData(
            formData,
            viewOption as string
          );
          if (
            response?.insertResult?.length > 0 &&
            response?.skippedRecords?.length === 0
          ) {
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("success"));
            store.dispatch(setToastMessage("Data uploaded successfully."));
          } else if (
            // response?.insertResult?.length > 0 &&
            response?.skippedRecords?.length > 0
          ) {
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("warning"));
            store.dispatch(
              setToastMessage("Already existing records may have been skipped.")
            );
          } else if (
            response?.insertResult?.length === 0 &&
            response?.skippedRecords?.length === 0
          ) {
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("error"));
            store.dispatch(
              setToastMessage(
                "Please upload file according to provided format."
              )
            );
          }else if (
            response?.message &&
            response?.inValidFile
          ) {
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("error"));
            store.dispatch(
              setToastMessage(
                response.message
              )
            );
          } else if(response.message && response.inValidRecord){
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("error"));
            store.dispatch(
              setToastMessage(
                response.message
              )
            );
          }
          else {
            console.log('res', response.details)
            setDataImported(!dataImported);
            store.dispatch(setSnackBar("error"));
            console.log('res',response.err)
            store.dispatch(setToastMessage(response.details));
          }
        } else {
          store.dispatch(setSnackBar("error"));
          store.dispatch(setToastMessage("Please Upload .csv file"));
        }
      } catch (error) {
        console.log("file not uploaded (In catch)");

        console.error("API Error:", error);
        setDataImported(!dataImported);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the input value
      }
    }
  };

  const handleDownloadFilePattern = async () => {
    const res: any = await getFilePattern(viewOption as string);
    if (res?.url) {
      window.open(res?.url, "_blank");
      console.log("check file: ", res);
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(
        setToastMessage("Sorry, couldn't download template file.")
      );
    }
  };
  // filters
  const handleAddFilter = () => {
    setFiltersElements((prev) => {
      return [...prev, { id: nextId }];
    });
    setNextId(nextId + 1);
  };

  const handleRemoveFilter = (indexId: number, forChips?: boolean) => {
    const updatedFiltersElements = filtersElements.filter(
      (filt) => filt.id !== indexId
    );
    setFiltersElements(updatedFiltersElements);

    if (forChips) {
      const updatedFiltersChipsArray = filtersChipsArray.filter(
        (filt) => filt?.id !== indexId
      );
      setFiltersChipsArray(updatedFiltersChipsArray);
      setIsApplyFilter(!isApplyFilter);
      return;
    }
    const updatedFiltersArray = filtersArray.filter(
      (filt) => filt?.id !== indexId
    );
    setFiltersArray(updatedFiltersArray);
  };

  const handleRemoveFilterChips = (indexId: number) => {
    handleRemoveFilter(indexId, true);
  };

  const handleFilterValue = (
    index: number,
    value: string[] | Date | string,
    isDate?: boolean,
    isRangeDate?: boolean
  ) => {
    const tempArray = [...filtersArray];
    if (isDate && tempArray[index])
      tempArray[index].value = moment(value).format("MM/DD/YYYY");
    else if (isRangeDate && Array.isArray(value) && tempArray[index]) {
      const startDate = moment(value[0]).format("MM/DD/YYYY");
      const endDate = moment(value[1]).format("MM/DD/YYYY");
      tempArray[index].value = [startDate, endDate];
    } else if (tempArray[index]) tempArray[index].value = value as string;
    setFiltersArray(tempArray);
  };

  const handleApplyFilter = () => {
    console.log("apply");
    const deepCopyFiltersArray = JSON.parse(JSON.stringify(filtersArray));
    setFiltersChipsArray(deepCopyFiltersArray);
    // setFiltersChipsArray([...filtersArray]);
    setIsApplyFilter(!isApplyFilter);
    handleClose();
  };
  const clearAllParams = () => {
    setNextId(0);
    setFiltersArray([]);
    setFiltersElements([]);
  };

  useEffect(() => {
    setFiltersChipsArray([]);
    setSearchQuery("");
    clearAllParams();
    setIsApplyFilter(!isApplyFilter);
    sessionStorage.setItem("viewOption", JSON.stringify(viewOption));
  }, [viewOption]);

  const handleClick = () => {
    setIsFilterOpen(true);
  };
  const handleClose = () => setIsFilterOpen(false);

  const filterBasedOnChipsArray = (
    originalArray: FilterElements[] | IFilterParameters[],
    chipsArray: IFilterParameters[]
  ) => {
    return originalArray
      .map((item) =>
        chipsArray.some((obj) => obj?.id === item?.id) ? item : null
      )
      .filter((item) => item !== null);
  };

  const handleElementsAndFiltersArray = () => {
    const filteredArray = filterBasedOnChipsArray(
      filtersArray,
      filtersChipsArray
    );
    const filteredElementsArray = filterBasedOnChipsArray(
      filtersElements,
      filtersChipsArray
    );

    setFiltersElements(filteredElementsArray as FilterElements[]);
    setFiltersArray(filteredArray as IFilterParameters[]);
  };
  // console.log("chips array: ", filtersChipsArray);
  // console.log("filter elements: ", filtersElements);
  return (
    <>
      <div className="bg-slate-100 min-h-full">
        <PageTitle
          title={data?.branchName as string}
          content="See all the data with reference to the hospital"
          viewOption={viewOption}
          setViewOption={setViewOption}
          handleFileChange={handleFileChange}
          handleImportClick={handleImportClick}
          handleDownloadFilePattern={handleDownloadFilePattern}
          fileInputRef={fileInputRef}
        />
        {viewOption && (
          <div className="px-4 py-4 bg-gray-100 relative">
            <div className="flex flex-wrap gap-3 mb-3">
              {filtersChipsArray.length > 0 &&
                filtersChipsArray.map((filterParams: IFilterParameters) => (
                  <FilterChips
                    filterParams={filterParams}
                    handleRemoveFilterChips={handleRemoveFilterChips}
                  />
                ))}
            </div>
            <div className="flex z-50 items-start  gap-2">
              {viewOption !== VIEWS.SECTIONS && (
                <div className="">
                  <Button
                    variant="ghost"
                    className="text-blackcst"
                    iconLeft={<MailFilter />}
                    onClick={() => {
                      handleClick();
                      if (filtersChipsArray?.length === 0) {
                        // setFiltersElements([]);

                        // if (filtersElements?.length === 0) {
                        // handleElementsAndFiltersArray();
                        const filteredArray = filterBasedOnChipsArray(
                          filtersArray,
                          filtersChipsArray
                        );
                        const filteredElementsArray = filterBasedOnChipsArray(
                          filtersElements,
                          filtersChipsArray
                        );
                        setFiltersElements(
                          filteredElementsArray as FilterElements[]
                        );
                        setFiltersArray(filteredArray as IFilterParameters[]);

                        handleAddFilter();
                        setFiltersArray([
                          ...(filteredArray as IFilterParameters[]),
                          newFilter,
                        ]);
                      } else {
                        // const tempFiltersArray = filtersArray?.map((filter) => {
                        //   // Check if there is a corresponding element with the same id in filtersChipsArray
                        //   const existsInChipsArray = filtersChipsArray.some(
                        //     (obj) => obj?.id === filter?.id
                        //   );

                        //   // If it exists in filtersChipsArray, return the filter, otherwise return null
                        //   return existsInChipsArray ? filter : null;
                        // });

                        // // Use filter to remove null values from the array
                        // const filteredArray = tempFiltersArray.filter(
                        //   (filter) => filter !== null
                        // );

                        // const tempFiltersElementsArray = filtersArray?.map(
                        //   (filter) => {
                        //     // Check if there is a corresponding element with the same id in filtersChipsArray
                        //     const existsInChipsArray = filtersChipsArray.some(
                        //       (obj) => obj?.id === filter?.id
                        //     );

                        //     // If it exists in filtersChipsArray, return the filter, otherwise return null
                        //     return existsInChipsArray ? filter : null;
                        //   }
                        // );

                        // // Use filter to remove null values from the array
                        // const filteredElementsArray =
                        //   tempFiltersElementsArray.filter(
                        //     (filter) => filter !== null
                        //   );

                        // // Update the filtersArray state with the filteredArray
                        // setFiltersElements(
                        //   filteredElementsArray as FilterElements[]
                        // );
                        // setFiltersArray(filteredArray as IFilterParameters[]);
                        handleElementsAndFiltersArray();

                        // console.log("filtered: ", filteredArray);
                        // console.log("filtered: ", filteredArray);
                      }
                      setSearchQuery("");
                    }}
                  >
                    Filter
                  </Button>
                </div>
              )}
              {/* {filtersChipsArray?.length === 0 && ( */}
              <div className="flex-1">
                <Input
                  className="z-0"
                  type="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e?.target?.value)}
                />
              </div>
              {/* )} */}
              {viewOption === VIEWS.PATIENTS && (
                <FilterTabs
                  links={links}
                  selectedButton={selectedButton}
                  handleButtonClick={handleButtonClick}
                />
              )}
            </div>
          </div>
        )}
        <ViewSelect
          viewOption={viewOption}
          selectedButton={selectedButton}
          dataImported={dataImported}
          searchQuery={searchQuery}
          // filtersArray={filtersArray}
          filtersArray={filtersChipsArray}
          isApplyFilter={isApplyFilter}
        />
      </div>

      <FilterDrawer
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        handleClose={handleClose}
        filtersElements={filtersElements}
        handleAddFilter={handleAddFilter}
        newFilter={newFilter}
        handleRemoveFilter={handleRemoveFilter}
        filterOptions={filterOptions}
        options={options}
        match={match}
        filtersArray={filtersArray as IFilterParameters[]}
        setFiltersArray={setFiltersArray}
        handleFilterValue={handleFilterValue}
        clearAllParams={clearAllParams}
        handleApplyFilter={() => handleApplyFilter()}
      />
    </>
  );
};

export default AdminViews;
