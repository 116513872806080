export const PATIENTS_FILTER = {
  ID: "Patient Id",
  PATIENT_NAME: "Name",
  ADMISSION_DATE: "Admission Date",
  DISCHARGE_DATE: "Discharge Date",
  ALLOCATED_SECTION: "Allocated Section",
  ASSIGNED_ROOM: "Assigned Room",
  // REFERRED_DOCTOR: "Referred Deoctor",
  NEEDY_PROFILE: "Needy Profile",
  // STATUS: "Status",
};

export const EMPLOYEES_FILTER = {
  ID: "Employee Id",
  EMPLOYEE_NAME: "Name",
  DESIGNATION: "Designation",
  // PHONE_NUM: "Phone#",
  // EMAIL: "Email",
  JOINING_DATE: "Joining Date",
  APPOINTED_SECTION: "Appointed Section",
  SENIORITY: "Seniority Level",
};

export const REQUEST_TIMEOFF_FILTER = {
  ID: "Employee Id",
  EMPLOYEE_NAME: "Name",
  // LEAVE_TYPE: "Leave Type",
  STATUS: "Status",
  SECTION: "Section",
  MONTH: "Month",
  YEAR: "Year",
  DATE: "Date",
  // LEAVES_TO: "To Date",
};

export const LEAVES_FILTER = {
  ID: "Employee Id",
  EMPLOYEE_NAME: "Name",
  EMPLOYEE_SECTION: "Employee Section",
  LEAVE_TYPE: "Leave Type",
  STATUS: "Leave Status",
  LEAVES_FROM_TO_DATE: "From Date - To Date",
  // LEAVES_TO: "To Date",
};
//   export const SWAP_REQUEST_FILTER = {
//     ID: "Employee Id",
//     EMPLOYEE_NAME: "Name",
//     LEAVE_TYPE: "Leave Type",
//     STATUS: "Status",
//     SECTION: "Section",
//     MONTH: "Month",
//     YEAR:"Year",
//     LEAVES_FROM:"From",
//     LEAVES_TO:"To"
//   };

