import React from "react";
import TrackingHeader from "./TrackingHeader";
import CardPoolTracking from "./CardPoolTracking";
import SimpleBar from "simplebar-react";

interface Props {}
const TrackingFilter: React.FC<Props> = ({}) => {
  return (
    <>
      <div className="mb-4">
        <TrackingHeader title="Pool Tracking" />
      </div>

      <SimpleBar
        className="flex flex-col gap-4 overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 447px" }}
      >
        <CardPoolTracking />
      </SimpleBar>
    </>
  );
};

export default TrackingFilter;
