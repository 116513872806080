import { api } from "../../utils/axios";

export const getEmployeeProfileData = async (
  employeeId: string,
  month: string,
  year: string,
) => {
  try {
    const { data, ok } = await api.get(
      `/employee/getEmployeeProfileInScheduling/${employeeId}/${month}/${year}`,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
interface IEditProfile {
  firstName: string;
  lastName: string;
  contactNo: string;
  address: string;
}

export const editEmployeeProfileData = async (params: IEditProfile) => {
  try {
    const { data, ok } = await api.patch(
      `/employee/updateEmployeeProfile`,
      params,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
// /employee/updateEmployeeProfile
// {{healthCareDev}}/employee/getEmployeeProfileInScheduling/64bf88c1c523ab2594b7f3ac/2023
