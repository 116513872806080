// Notification.js
import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { NotificationsBell } from "@heathmont/moon-icons-tw";
import { Button } from "@heathmont/moon-core-tw";
import NotificationList from "./NotificationList";
import SimpleBar from "simplebar-react";
import io, { Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import {
  getNotifications,
  getUnreadNotifications,
} from "../../services/nurse-manager-services/notifications.service";
import {
  setNotifications,
  setRespondedScheduleId,
} from "../../features/Notification Slice/notificationSlice";
import { notificationSocket } from "../../utils/notifications.socket.service";
import { apiBaseUrl } from "../../constants/environmentConstants";
export interface NotificationsList {
  notifications: Notification[];
}

export interface Notification {
  _id: string;
  message: string;
}
interface NotificationRead {
  hasUnreadNotification: false;
}
interface Props {
  isNeedRoute?: string;
}
// let socket: Socket;
const Notification: React.FC<Props> = ({ isNeedRoute }) => {
  const [option, setOption] = useState({ name: "Choose a name..." });
  const { User } = useSelector((state: RootState) => state.auth);
  const [notificationsList, setNotificationsList] = useState<NotificationsList>(
    { notifications: [] } as NotificationsList
  );
  const [isUnreadNotification, setIsUnreadNotification] =
    useState<NotificationRead>({ hasUnreadNotification: false });
  const updateData = useSelector(
    (state: RootState) => state?.notification?.updateData
  );
  const roleBasedNotification = (designation: string) => {
    switch (designation) {
      case "nurse manager":
        notificationSocket.socket?.on(
          "respondScheduleRequest",
          (resOfScheduleReq: any) => {
            console.log(" check manager now: ", resOfScheduleReq);
            store.dispatch(setSnackBar("notification"));
            store.dispatch(setToastMessage(resOfScheduleReq?.message));
            fetchUnreadNotification();
            // if (!resOfScheduleReq?.message?.includes("declined"))
            // store.dispatch(setNotifications(!updateData));
            store.dispatch(setNotifications(true));
            store.dispatch(
              setRespondedScheduleId(resOfScheduleReq?.schedule?._id)
            );
            console.log("nurse manager ");
          }
        );
        return () => {
          // notificationSocket.socket?.off("respondScheduleRequest");
        };
      case "nurse director":
        notificationSocket.socket?.on(
          "scheduleSubmitted",
          (submitToApprove) => {
            store.dispatch(setSnackBar("notification"));
            store.dispatch(setToastMessage(submitToApprove?.message));
            fetchUnreadNotification();
            // store.dispatch(setNotifications(!updateData));
            store.dispatch(setNotifications(true));
            console.log("nurse director ");
          }
        );
        return () => {
          // notificationSocket.socket?.off("scheduleSubmitted");
        };

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!User?.id || !apiBaseUrl) return;
    fetchUnreadNotification();
    // socket = io("https://dev.api.healthcare.aquila360.com");
    // fetchNotifications();
    // socket.emit("joinRoom", User?.id);
    notificationSocket?.initializeConnection(
      apiBaseUrl,
      User?.id,
      User?.designation
    );
    roleBasedNotification(User?.designation);
    // socket?.disconnect();
  }, [User?.id, apiBaseUrl]);

  const fetchNotifications = async () => {
    const res = await getNotifications();
    if (res?.res) {
      setNotificationsList(res?.res as NotificationsList);
      fetchUnreadNotification();
    }
  };

  const fetchUnreadNotification = async () => {
    const res = await getUnreadNotifications();
    if (res?.res) {
      setIsUnreadNotification(res?.res as NotificationRead);
    }
  };

  return (
    <Dropdown value={option} onChange={setOption} className="p-0">
      <Dropdown.Trigger>
        <Button
          className={`inline-flex items-center justify-center w-[30px] h-[30px] rounded-full text-moon-24 relative ${
            isNeedRoute
              ? "bg-white bg-opacity-25 text-[#272D36] "
              : "text-white bg-primarydarkl"
          } p-0 `}
          onClick={() => fetchNotifications()}
        >
          <NotificationsBell />
        </Button>
        {isUnreadNotification?.hasUnreadNotification && (
          <span className="absolute top-[-3px] right-[-2px] bg-dodoria w-3 h-3 rounded-full"></span>
        )}
      </Dropdown.Trigger>
      <Dropdown.Options className="min-w-[440px] max-w-[440px]  p-0">
        <div className="flex justify-between w-full py-[12px] px-[16px]">
          <div className="inline-flex items-center">
            <h4 className="mr-[13px]">Notifications</h4>
            {/* <Dropdown value={selected3} onChange={setSelected3}>
              {({ open }) => (
                <>
                  <Dropdown.Select
                    open={open}
                    placeholder="Without Label and Hint message"
                    className="p-0 h-auto border-['transparent'] shadow-[none] hover:shadow-[none] focus:shadow-[none] transition-colors"
                  >
                    <span className="text-moon-12 text-gray-400">
                      {selected3?.name}
                    </span>
                  </Dropdown.Select>
                  <Dropdown.Options className="min-w-[200px] max-w-[200px]">
                    {people?.map((person, index) => (
                      <Dropdown.Option value={person} key={index}>
                        {({ selected, active }) => (
                          <MenuItem
                            isActive={active}
                            isSelected={selected}
                            className="text-moon-12"
                          >
                            {person.name}
                          </MenuItem>
                        )}
                      </Dropdown.Option>
                    ))}
                  </Dropdown.Options>
                </>
              )}
            </Dropdown> */}
          </div>
          {/* <button className="inline-flex w-auto">Mark all as read</button> */}
        </div>
        <SimpleBar className="max-h-[540px]">
          <NotificationList
            notifications={notificationsList?.notifications as Notification[]}
            // fetchNotifications={fetchNotifications}
          />
        </SimpleBar>
      </Dropdown.Options>
    </Dropdown>
  );
};

export default Notification;
