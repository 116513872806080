import { useEffect, useState } from "react";
import HeaderSetting from "../../../Components/super-admin/ui/HeaderSetting";
import {
  getSchedulesNurseDirector,
  respondScheduleRequest,
} from "../../../services/nurse-manager-services/nursedirector.service";
import Table from "../../../Components/nurse-manager/View Table/NurseManagerTable";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  RowSelection,
} from "@tanstack/react-table";
import { Avatar, Button, Chip } from "@heathmont/moon-core-tw";
import { ControlsEye } from "@heathmont/moon-icons-tw";

import { useNavigate } from "react-router";
import moment from "moment";
import DropDown from "../../../Components/nurse-manager/UI/DropDown";
import { getSections } from "../../../services/branch-admin-services/section.service";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../store/store";
import ScheduleEmployDay from "../../../Components/nurse-manager/ScheduleNavDay";
import ScheduleStatus from "../../../Components/nurse-manager/layouts/header/ScheduleStatus";
import FilterShifts from "../../../Components/nurse-manager/UI/FilterShifts";
import FilterWeekMonth from "../../../Components/nurse-manager/UI/FilterWeekMonth";
import { setDate } from "../../../features/nurse-manager/Time Slice/timeSlice";
import WeeklyScheduleCalendar from "../../../Components/nurse-manager/schedule/WeeklyScheduleCalendar";
import MonthlyScheduleCalendar from "../../../Components/nurse-manager/schedule/MonthlyScheduleCalendar";
import { useLocation } from "react-router-dom";
import { setNotifications } from "../../../features/Notification Slice/notificationSlice";
import { ISectionInfo } from "../../../types/nurse-manager-interfaces/ISectionInfo.interface";
interface Schedule {
  _id: string;
  status: string;
  month: number;
  year: number;
  section: string;
  scheduledBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdAt: string;
  sectionName: string;
}

interface SectionsData {
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  limit: number;
  nextPage: number | null;
  prevPage: number | null;
  records: Schedule[]; // Assuming the Schedule interface from the previous response
  totalPages: number;
  totalRecords: number;
}

interface IRequest {
  sectionId: string;
  status: string;
}

interface Section {
  _id: string;
  sectionName: string;
}

const ScheduleRequest = () => {
  const location = useLocation();
  const updateData = useSelector(
    (state: RootState) => state?.notification?.updateData
  );
  const [data, setData] = useState(() => []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const columnHelper = createColumnHelper<Schedule>(); //schedule
  const [sectionData, setSectionsData] = useState<SectionsData>();
  const [fetchAgain, setFetchAgain] = useState<boolean>(false);
  const [controlView, setControlView] = useState<boolean>(true);
  // const [controlViewSection, setControlViewSection] = useState<boolean>(false);
  const [sectionInfo, setSectionInfo] = useState<ISectionInfo>(); /// pass data for next page to appear when we click on eye
  const [selectSection, setSelectedSection] = useState<Section>({
    sectionName: "All Sections",
    _id: "",
  });
  const [sectionName, setSectionName] = useState("");
  const navigate = useNavigate();

  const onChangeSection = (data: Section) => {
    console.log("Data Section", data);
    setSelectedSection(data);
  };
  const ActionCell = (row: any) => {
    return row.original.status !== "accepted" &&
      row.original.status !== "declined" ? (
      <div className="flex">
        <div className="flex items-center gap-[10px]">
          <Button
            className=""
            variant="secondary"
            size="sm"
            onClick={() =>
              responseOfScheduleRequest({
                sectionId: row?.original?._id,
                status: "declined",
              })
            }
          >
            Decline
          </Button>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() =>
              responseOfScheduleRequest({
                sectionId: row.original?._id,
                status: "accepted",
              })
            }
          >
            Accept
          </Button>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const handleRowClick = (row: any) => {};

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "r",
      enableSorting: false,

      header: () => "View",
      cell: (info) => (
        <span
          onClick={() => {
            // navigate("/", { state: { id: info.row.original } });
            setControlView(false);
            // setControlViewSection(true);
            setSectionInfo(info.row.original);
            setSectionName(info?.row?.original?.sectionName);
            store.dispatch(
              setDate({
                year: info.row.original?.year,
                currentMonth: info.row.original?.month,
                month: moment({ month: info.row.original?.month - 1 }).format(
                  "MMMM"
                ),
                day: 1,
                week: 1,
              })
            );
          }}
        >
          <ControlsEye className="w-[30px] h-[32px] text-gray-600 cursor-pointer" />
        </span>
      ),
    }),
    columnHelper.accessor((row) => row, {
      enableSorting: false,

      id: "row",
      header: () => "Schedule Months",
      cell: (info) => (
        <div className="flex-1 py-[8px] text-moon-16">
          <span>
            {moment({
              month: info?.row?.original?.month - 1,
              year: info?.row?.original?.year,
            }).format("MMMM YYYY")}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("sectionName", {
      // id: "employeeName",
      enableSorting: false,

      header: () => "Section",
      cell: (info) => (
        <div className="flex-1  py-[8px]  text-moon-16 ">{info.getValue()}</div>
      ),
    }),
    columnHelper.accessor("createdAt", {
      enableSorting: false,

      header: () => "Submitted Date",
      cell: (info) => (
        <h4 className="block text-trunks">
          {moment(info.row.original.createdAt).format("DD/MM/YYYY")}
        </h4>
      ),
    }),

    columnHelper.accessor("scheduledBy", {
      enableSorting: false,

      header: () => "Submitted by",
      cell: (info) => (
        <div className="flex-1  py-[8px]  text-moon-16 ">
          <div className="flex justify-between items-center ">
            <span className="inline-flex rounded-full shadow-md  mr-[15px]">
              <Avatar
                size="md"
                className={`rounded-full border-2  overflow-hidden`}
                imageUrl={""}
              />
            </span>
            <div className="flex-1">
              <h4 className="text-moon-16 font-semibold">
                {info.row.original.scheduledBy.firstName +
                  " " +
                  info.row.original.scheduledBy.lastName}
              </h4>
            </div>
          </div>
        </div>
      ),
    }),

    columnHelper.accessor("status", {
      enableSorting: false,
      header: () => "Status",
      cell: (info) => (
        <div className="flex text-moon-16">
          <h4
            className={
              info.renderValue() === "pending"
                ? `bg-[rgba(36,156,186,0.12)] text-[#249CBA] rounded-moon-i-sm px-3 py-1`
                : info.renderValue() === "Rejected"
                ? `bg-dodoria-10 text-dodoria rounded-moon-i-sm px-3 py-1`
                : `bg-gray-300 text-thunks rounded-moon-i-sm px-3 py-1`
            }
          >
            {info.renderValue()}
          </h4>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row, {
      enableSorting: false,

      id: "row",
      header: "",
      cell: (info) => ActionCell(info.row),
    }),
  ];
  const [sorting, setSorting] = useState<SortingState>([]);
  // const [section, setSection] = useState<any>();

  const section = useSelector((state: RootState) => state.section);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    debugTable: true,
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchSchedulesNurseDirector = async (pageNumber = 1) => {
    setIsLoading(true);
    const params: any = {
      Page: pageNumber,
      sectionId: selectSection?._id ?? null,
    };
    const res: any = await getSchedulesNurseDirector(params);
    console.log("objed: ", res);

    if (res.res) {
      setData(res?.res?.records);
      setSectionsData(res?.res);
    }
    setIsLoading(false);
  };
  console.log("section data: ", sectionData);
  const responseOfScheduleRequest = async (params: IRequest) => {
    const res: any = await respondScheduleRequest(params);
    if (res?.res) {
      console.log("Success");
      setFetchAgain(true);
    }
  };

  useEffect(() => {
    if (fetchAgain || updateData) {
      setFetchAgain(false);
      fetchSchedulesNurseDirector();
      store.dispatch(setNotifications(false));
    }
  }, [fetchAgain, updateData]);

  useEffect(() => {
    fetchSchedulesNurseDirector();
  }, [selectSection]);

  const selectedCalendar = useSelector(
    (state: RootState) => state.time.selectedCalendar
  );
  return (
    <>
      {/* <ScheduleEmployDay /> */}
      <ScheduleStatus
        section={selectSection}
        onChangeSection={onChangeSection}
        controlView={!controlView}
        sectionName={sectionName}
      />

      <div className="bg-slate-100 min-h-full  relative z-[0] ">
        <div className=" w-full">
          {controlView ? (
            <>
              {" "}
              <HeaderSetting
                title="Schedule Requests"
                leadText="Approve/decline schedule requests"
                buttonText=""
                handleModal={false}
              />
              <div className="px-8">
                {!isLoading && (
                  <Table
                    table={table}
                    tableData={sectionData as SectionsData}
                    handlePageChange={fetchSchedulesNurseDirector}
                    handleRowClick={handleRowClick} // Add this prop
                  />
                )}
              </div>
            </>
          ) : selectedCalendar === "month" ? (
            <MonthlyScheduleCalendar
              sectionInfo={sectionInfo}
              setControlView={setControlView}
              controlView={controlView}
              responseOfScheduleRequest={responseOfScheduleRequest ?? ""}
            />
          ) : selectedCalendar === "week" ? (
            <WeeklyScheduleCalendar
              sectionInfo={sectionInfo}
              setControlView={setControlView}
              controlView={controlView}
              // responseOfScheduleRequest={}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleRequest;
