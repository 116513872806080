import { useState, useEffect } from "react";
import { getIdleTime } from "../../services/super-admin-services/adminSettings.service";
import { useIdle } from "@custom-react-hooks/all";
import { logout } from "../../services/auth.service";

const useIdleTime = (defaultIdleTime = 10000) => {
  const [idleTime, setIdleTime] = useState(defaultIdleTime);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: any = await getIdleTime();
        if (res?.data) {
          setIdleTime(res?.data?.inActive);
        }
      } catch (error) {
        console.error("API call failed:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Check if the user is idle
  const isIdle = useIdle(idleTime * 1000);

  useEffect(() => {
    if (isIdle) {
      console.log("User is idle, logging out...");
      logout()
    }
  }, [isIdle]);

  return { idleTime, loading };
};

export default useIdleTime;
