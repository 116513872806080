import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ListingLeaves from "./ListingLeaves";
import SimpleBar from "simplebar-react";
import { ILeave } from "../../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";

interface LeaveItem {
  title: string;
  description: string;
  date: string;
  status: string;
}

interface LeaveMonth {
  month: string;
  items: LeaveItem[];
}

const data: LeaveMonth[] = [
  {
    month: "October",
    items: [
      {
        title: "Study Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Requested",
      },
      {
        title: "Study Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Approved",
      },
      {
        title: "Annual Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Approved",
      },
    ],
  },
  {
    month: "September",
    items: [
      {
        title: "Study Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Requested",
      },
      {
        title: "Study Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Approved",
      },
      {
        title: "Annual Leave",
        description: "I want to go for the study...",
        date: "Mon, Oct 24, 2022 - Fri, Oct 29, 2022",
        status: "Approved",
      },
    ],
  },
];
interface Props {
  leaves: ILeave[];
}
const LeavesCard: React.FC<Props> = ({ leaves }) => {
  const [selected3, setSelected3] = useState({ name: "2022" });
  const year = [{ name: "2021" }, { name: "2022" }, { name: "2023" }];

  return (
    <>
      {leaves?.length !== 0 ? (
        <>
          <div className="px-5 pt-5">
            <div className="flex flex-wrap mb-[8px]">
              <div className="inline-flex items-center">
                <h3 className="font-bold mr-2">Leaves</h3>
                {/* <Dropdown value={selected3} onChange={setSelected3}>
              {({ open }) => (
                <>
                  <Dropdown.Select
                    open={open}
                    placeholder="Without Label and Hint message"
                    className="p-0 h-auto border-['transparent'] shadow-[none] hover:shadow-[none] focus:shadow-[none] transition-colors"
                  >
                    <span className="text-moon-12 text-[#272D36]">
                      {selected3?.name}
                    </span>
                  </Dropdown.Select>
                  <Dropdown.Options className="min-w-[200px] max-w-[200px]">
                    {year.map((year, index) => (
                      <Dropdown.Option value={year} key={index}>
                        {({ selected, active }) => (
                          <MenuItem
                            isActive={active}
                            isSelected={selected}
                            className="text-moon-12"
                          >
                            {year.name}
                          </MenuItem>
                        )}
                      </Dropdown.Option>
                    ))}
                  </Dropdown.Options>
                </>
              )}
            </Dropdown> */}
              </div>
              <div className="flex-1 text-end">
                {/* <Link to="#" className="text-primary">
              View all
            </Link> */}
              </div>
            </div>
          </div>
          <SimpleBar style={{ height: "calc(100vh - 475px)" }}>
            <div className="px-5">
              {/* {data.map((monthData: LeaveMonth, index) => ( */}
              <div className="mb-2">
                <h3 className="font-bold text-moon-14 mb-[8px]">
                  {/* {monthData.month} */}
                </h3>
                {leaves?.map((item: any, itemIndex: any) => (
                  <ListingLeaves {...item} key={itemIndex} />
                ))}
              </div>
              {/* ))} */}
            </div>
          </SimpleBar>
        </>
      ) : (
        <div className="sectionsvg flex items-center justify-center text-center">
          <img
            src={"images/no-schedule-to-approve.svg"}
            alt="No schedule to approve"
          />
        </div>
      )}{" "}
    </>
  );
};

export default LeavesCard;
