import { api } from "../../utils/axios";

export const importData = async (formData: FormData, viewOption: string) => {
  try {
    // https://dev.api.healthcare.aquila360.com/admin/uploadFile/${view.toLowerCase()}
    const response = await api.post(
      `/admin/uploadFile/${viewOption?.toLowerCase()}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const getFilePattern = async (viewOption: string) => {
  try {
    const response = await api.get(
      `/admin/getTemplate/${viewOption?.toLowerCase()}`
    );

    return response?.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
