import { Dropdown, MenuItem } from '@heathmont/moon-core-tw';
import { ControlsChevronDown } from '@heathmont/moon-icons-tw';
import React from 'react';

type DropdownComponentProps = {
  options: { label: string; value: string }[];
  selectedOption: { label: string; value: string };
  onOptionChange: (option: { label: string; value: string }) => void;
};

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  options,
  selectedOption,
  onOptionChange
}) => {
    console.log('opt',options)
  return (
    <Dropdown value={selectedOption} onChange={onOptionChange}>
      <Dropdown.Trigger>
        <button className="w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-white">
          {selectedOption?.label || 'Select an option'}
          <i>
            <ControlsChevronDown className="text-moon-18" />
          </i>
        </button>
      </Dropdown.Trigger>
      <Dropdown.Options className="dropitems min-w-[100px]">
        {(options||[]).map((option, index) => (
          <Dropdown.Option value={option} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                className={`${
                  selected || active ? "bg-goku text-cyan-500" : "text-gray-900"
                } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default DropdownComponent;
