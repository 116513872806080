import React, { useState } from "react";
import NeedHeader from "../../../../Components/nurse-manager/need/NeedHeader";
import FilterSideBar from "../../../../Components/nurse-manager/employees/FilterSideBar";
import SimpleBar from "simplebar-react";
import NeedSearchFilter from "../../../../Components/nurse-manager/need/NeedSearchFilter";
import NeedListing from "../../../../Components/nurse-manager/need/NeedListing";
import NeedModal from "../../../../Components/nurse-manager/Widgets/modal/NeedModal";

const Need = () => {
  const [sidebarData, setSidebarData] = useState([
    {
      id: 1,
      label: "Sort",
      isOpen: true,
      subItems: [
        {
          id: 1,
          label: "Your value",
          isSelected: false,
          subMenu: [
            { id: 1, label: "Sub Option 1", isSelected: false },
            { id: 2, label: "Sub Option 2", isSelected: false },
          ],
        },
      ],
    },
    {
      id: 2,
      label: "Section",
      isOpen: true,
      subItems: [
        { id: 1, label: "Annual Leave", isSelected: false },
        { id: 2, label: "Compensation Leave", isSelected: false },
        { id: 3, label: "Paternity Leave", isSelected: false },
        { id: 4, label: "Time in Lieu", isSelected: false },
        { id: 5, label: "Request Off", isSelected: false },
        { id: 6, label: "Study Leave", isSelected: false },
        { id: 7, label: "Compensation Leave", isSelected: false },
      ],
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const handleSidebarDataUpdate = (data: any) => {
    setSidebarData(data);
  };

  const listings = [
    {
      month: "This month",
      items: [
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "5",
          date: "15 May 2020 8:00 am",
          chipText: "Allocate",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Provided",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Dropped",
        },
      ],
    },
    {
      month: "September 2022",
      items: [
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "5",
          date: "15 May 2020 8:00 am",
          chipText: "Allocate",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Provided",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Dropped",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "5",
          date: "15 May 2020 8:00 am",
          chipText: "Allocate",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Provided",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Dropped",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "5",
          date: "15 May 2020 8:00 am",
          chipText: "Allocate",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Provided",
        },
        {
          title: "Why choose a theme that looks good with WooCommerce",
          section: "ICU",
          requested: "4",
          date: "15 May 2020 8:00 am",
          chipText: "Dropped",
        },
      ],
    },
  ];
  return (
    <>
      <div className="bg-slate-100">
        <NeedHeader />
        <div className="flex flex-wrap">
          <div className="w-1/4">
            <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
              <div className="bg-whitept-[30px] p-[30px]">
                <h3 className="text-moon-20 font-semibold ">Filters</h3>
              </div>

              <div className="bg-gohan flex flex-col gap-2 rounded-moon-s-lg">
                <SimpleBar className="px-[30px] h-[calc(100vh-300px)] w-full">
                  <FilterSideBar
                    onDataUpdate={handleSidebarDataUpdate}
                    sidebarData={sidebarData}
                  />
                </SimpleBar>
              </div>
            </div>
          </div>
          <div className="w-3/4">
            <div className="p-[20px_30px_0]">
              <NeedSearchFilter />
            </div>
            <SimpleBar className="p-[0_30px_20px] h-[calc(100vh-284px)] ">
              <NeedListing listings={listings} openModal={openModal} />
            </SimpleBar>
          </div>
        </div>
      </div>
      <NeedModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
};

export default Need;
