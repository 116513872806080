import React from "react";

import PatientsView from "../../../Pages/branch-admin/admin-views/patientsView";
import EmployeesView from "../../../Pages/branch-admin/admin-views/employeesView";
import LeavesView from "../../../Pages/branch-admin/admin-views/leavesView";
import RequestTimeOffView from "../../../Pages/branch-admin/admin-views/requestTimeOffView";
import SwapRequest from "../../../Pages/branch-admin/admin-views/swapRequest";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { VIEWS } from "../../../enums/branch-admin/views.enums";
import SectionsView from "../../../Pages/branch-admin/admin-views/sectionsView";

interface Props {
  viewOption: string | null;
  selectedButton: string;
  dataImported: boolean;
  searchQuery: string;
  filtersArray: IFilterParameters[];
  isApplyFilter: boolean;
}
const ViewSelect: React.FC<Props> = ({
  viewOption,
  selectedButton,
  dataImported,
  searchQuery,
  filtersArray,
  isApplyFilter,
}) => {
  switch (viewOption) {
    case VIEWS.PATIENTS:
      return (
        <PatientsView
          selectedButton={selectedButton}
          dataImported={dataImported}
          searchQuery={searchQuery}
          filtersArray={filtersArray}
          isApplyFilter={isApplyFilter}
        />
      );
    case VIEWS.EMPLOYEES:
      return (
        <EmployeesView
          dataImported={dataImported}
          searchQuery={searchQuery}
          filtersArray={filtersArray}
          isApplyFilter={isApplyFilter}
        />
      );
    case VIEWS.LEAVES:
      return (
        <LeavesView
          dataImported={dataImported}
          searchQuery={searchQuery}
          filtersArray={filtersArray}
          isApplyFilter={isApplyFilter}
        />
      );
    case VIEWS.REQUEST_TIME_OFF:
      return (
        <RequestTimeOffView
          dataImported={dataImported}
          searchQuery={searchQuery}
          filtersArray={filtersArray}
          isApplyFilter={isApplyFilter}
        />
      );
    case VIEWS.SWAP_REQUEST:
      return <SwapRequest />;
    case VIEWS.SECTIONS:
      return (
        <SectionsView
          dataImported={dataImported}
          searchQuery={searchQuery}
          filtersArray={filtersArray}
          isApplyFilter={isApplyFilter}
        />
      );
    default:
      return (
        <div className="min-h-[calc(100vh-228px)] flex items-center justify-center">
          <div className="block text-center">
            <img src="images/img-views.svg" alt="" className="inline-block" />
            <h3 className="text-gray-600 text-moon-20 font-semibold mb-3">
              Choose a view to show data{" "}
            </h3>
            <p className="text-gray-600 text-moon-16">
              Select from the drop down to choose which branch do you want to
              view
            </p>
          </div>
        </div>
      );
  }
};

export default ViewSelect;
