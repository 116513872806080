import { api } from "../../utils/axios";

export const getSettings = async () => {
    try {
      const { data, ok } = await api.get(
        `/admin/getSettings/`
      );
      if (ok) {
        return { res: data, err: null };
      }
    } catch (error) {
      return { data: null, err: error };
    }
  };
export const updateSetting = async (body:any) => {
    try {
      const { data, ok } = await api.put(
        `/admin/updateSettings/`,body
      );
      if (ok) {
        return { res: data, err: null };
      }
      return {err: data, res:null}
    } catch (error) {
      return { data: null, err: error };
    }
  };

export const getIdleTime =async()=>{
  const res = await api.get(`/employee/inActiveTime`)
  if(res.ok){
    return {data : res?.data , err : null}
  }
  return {data:null , err: res?.data}
}