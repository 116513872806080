import React, { FC } from "react";
interface Props {
  title: string;
  content: string;
}
const PageTitle: React.FC<Props> = ({ title, content }) => {
  return (
    <div className="px-7 bg-hit">
      <div className="flex items-end py-[23px]  -mx-[16px]">
        <div className="w-2/4 px-[16px]">
          <div>
            <h3 className="text-moon-32 font-semibold -tracking-[0.5px] text-white  mb-8px">
              {title}
            </h3>
            <p className="text-white text-[16px] mt-[8px] opacity-50 ">
              {content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
