import React from "react";
import SimpleBar from "simplebar-react";
import SearchBox from "../../../../../Components/nurse-manager/UI/Search";
import WeekDaysTab from "../../../../../Components/nurse-manager/Calendar/full-month-view/WeekDays";
import TopbarMonth from "../../../../../Components/nurse-manager/Calendar/full-month-view/header-month/TopbarMonth";
import EmployeeCard from "../../../../../Components/nurse-manager/Widgets/cards/card-employee/EmployeeCard";
import MonthCalenderEmpty from "../../../../../Components/nurse-manager/Calendar/full-month-view/MonthCalender/MonthCalenderEmpty";
import ScheduleEmployDay from "../../../../../Components/nurse-manager/ScheduleNavDay";
import ScheduleStatus from "../../../../../Components/nurse-manager/layouts/header/ScheduleStatus";
import { Button } from "@heathmont/moon-core-tw";

interface DaysLeft {
  current: number;
  total: number;
}

interface HoursLeft {
  current: number;
  total: number;
}

interface Listing {
  avatarImageUrl: string;
  chipText: string;
  name: string;
  daysLeft: DaysLeft;
  hoursLeft: HoursLeft;
  preference: "On Leave" | "Applied for leave" | "Day" | "Night";
}

const listings: Listing[] = [
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Leader",
    name: "Jacob Jones",
    daysLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },

    preference: "On Leave",
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Leader",
    name: "Jacob Jones",
    daysLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },

    preference: "Applied for leave",
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Chief Nurse",
    name: "Jacob Jones",
    daysLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },

    preference: "Day",
  },
  {
    avatarImageUrl: "images/Avatar.png",
    chipText: "Chief Nurse",
    name: "Jacob Jones",
    daysLeft: {
      current: 3,
      total: 5,
    },
    hoursLeft: {
      current: 48,
      total: 217,
    },

    preference: "Night",
  },
];

export default function EmptyView() {
  return (
    <>
      <ScheduleEmployDay />
      <ScheduleStatus />
      <div className="flex flex-wrap bg-goku">
        <div className="w-1/4">
          <div className="pt-[25px] px-[25px] flex flex-col">
            <SearchBox />
          </div>
          <SimpleBar
            className="flex flex-col gap-3 mt-3 flex-1"
            style={{ height: "calc(100dvh - 440px)" }}
          >
            <div className="px-[25px]">
              {/* {listings.map((listing, index) => (
                <EmployeeCard key={index} {...listing} />
              ))} */}
            </div>
          </SimpleBar>
          <div className="submitbox p-4 flex gap-4 items-center justify-between bg-[#EDFDFF]">
            <div className="flex-1">
              <Button variant="secondary" size="sm">
                Cancel
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <Button variant="tertiary" size="sm">
                Submit
              </Button>
              <Button variant="secondary" size="sm">
                Save as draft...
              </Button>
            </div>
          </div>
        </div>
        <div className="w-3/4">
          {/* <TopbarMonth /> */}
          <div>
            <WeekDaysTab />
          </div>
          <div>
            <MonthCalenderEmpty />
          </div>
        </div>
      </div>
    </>
  );
}
