import React from "react";
interface Props {
  dayNames: string[];
}
const WeekDays: React.FC<Props> = ({ dayNames }) => {
  return (
    <div className="flex flex-wrap w-full">
      {dayNames?.map((day, index) => (
        <div
          key={index}
          className={`basis-1/7 h-[64px] border border-beerus border-s-0 px-3 py-2 inline-flex items-center`}
        >
          <div className="text-moon-14 font-semibold transition-colors">
            {day}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WeekDays;
