import { IRequestTableDataParams } from "../../types/branch-admin-interfaces/IRequestTableDataParams.interface";
import { api } from "../../utils/axios";

export const getPatientsView = async (params: IRequestTableDataParams) => {
  try {
    let searchQueryPart = "";

    if (params?.search != null) {
      const encodedSearchQuery = encodeURIComponent(params.search);
      searchQueryPart = `&Search=${encodedSearchQuery}`;
    }
    let filterQueryPart = "";

    if (params?.filterParams?.length) {
      params?.filterParams?.forEach((filterParam) => {
        if (filterParam?.option && filterParam?.value) {
          filterQueryPart += `&${filterParam?.option}=${filterParam?.value}${
            filterParam?.filter ? "&" + filterParam?.filter + "=true" : ""
          }`;
        }
      });
    }

    const response: any = await api.get(
      `/admin/getPatientsData?Page=${params?.pageChange}&Limit=${
        params?.limit
      }${
        params?.selectedButton === "active"
          ? "&Active Patients=true"
          : "&Historical Patients=true"
      }${searchQueryPart}${filterQueryPart}`
    );

    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getEmployeesView = async (params: IRequestTableDataParams) => {
  let searchQueryPart = "";
  try {
    if (params?.search != null) {
      const encodedSearchQuery = encodeURIComponent(params.search);
      searchQueryPart = `&Search=${encodedSearchQuery}`;
    }

    let filterQueryPart = "";

    if (params?.filterParams?.length) {
      params?.filterParams?.forEach((filterParam) => {
        if (filterParam?.option && filterParam?.value) {
          filterQueryPart += `&${filterParam?.option}=${filterParam?.value}&${
            filterParam?.filter ? filterParam?.filter + "=true" : ""
          }`;
        }
      });
    }

    const response: any = await api.get(
      `/admin/getEmployeesData?Page=${params?.pageChange}&Limit=${params?.limit}${filterQueryPart}${searchQueryPart}`
    );
    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const getReqTimeOffView = async (params: IRequestTableDataParams) => {
  try {
    let searchQueryPart = "";

    if (params?.search != null) {
      const encodedSearchQuery = encodeURIComponent(params.search);
      searchQueryPart = `&Search=${encodedSearchQuery}`;
    }
    let filterQueryPart = "";

    if (params?.filterParams?.length) {
      params?.filterParams?.forEach((filterParam) => {
        if (filterParam?.option && filterParam?.value) {
          filterQueryPart += `&${filterParam?.option}=${filterParam?.value}&${
            filterParam?.filter ? filterParam?.filter + "=true" : ""
          }`;
        }
      });
    }

    const response: any = await api.get(
      `/admin/getRequestTimeOff?Page=${params?.pageChange}&Limit=${params?.limit}${searchQueryPart}${filterQueryPart}`
    );
    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const getLeavesView = async (params: IRequestTableDataParams) => {
  try {
    let searchQueryPart = "";

    if (params?.search != null) {
      const encodedSearchQuery = encodeURIComponent(params.search);
      searchQueryPart = `&Search=${encodedSearchQuery}`;
    }
    let filterQueryPart = "";

    if (params?.filterParams?.length) {
      params?.filterParams?.forEach((filterParam) => {
        if (filterParam?.option && filterParam?.value) {
          filterQueryPart += Array.isArray(filterParam?.value)
            ? `&${filterParam?.option}=${true}&From Date=${
                filterParam?.value[0]
              }&To Date=${filterParam?.value[1]}${
                filterParam?.filter ? "&" + filterParam?.filter + "=true" : ""
              }`
            : `&${filterParam?.option}=${filterParam?.value}${
                filterParam?.filter ? "&" + filterParam?.filter + "=true" : ""
              }`;
        }
      });
    }

    const response: any = await api.get(
      `/admin/getLeaves?Page=${params?.pageChange}&Limit=${params?.limit}${searchQueryPart}${filterQueryPart}`
    );
    return response?.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getSectionsView = async (search?: string) => {
  try {
    let searchQueryPart = "";

    if (search != null) {
      const encodedSearchQuery = encodeURIComponent(search);
      searchQueryPart = `Search=${encodedSearchQuery}`;
    }
    const { data, ok } = await api.get(
      `/section/getSectionsAndRules?${searchQueryPart}`
    );

    if (ok) {
      return data;
    }
    throw new Error("Invalid Response");
  } catch (error) {
    return { err: error };
  }
};

export const activateEmpSvc = async (id: string) => {
  try {
    const response = await api.post(`/employee/activateEmployee/${id}`);

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const deActivateEmpSvc = async (id: string) => {
  try {
    const response = await api.post(`/employee/deActivateEmployee/${id}`);

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
export const elevateEmpRole = async (id: string, role: string) => {
  try {
    const response = await api.post(
      `employee/addRemoveUserRole/${id}?role=${role}`
    );

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
