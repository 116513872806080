import { Chip } from "@heathmont/moon-core-tw";
import React from "react";

interface Item {
  title: string;
  section: string;
  requested: string;
  date: string;
  chipText: string;
}

interface Listing {
  month: string;
  items: Item[];
}

interface NeedListingProps {
  listings: Listing[];
  openModal: () => void;
}

const NeedListing = ({ listings, openModal }: NeedListingProps) => {
  return (
    <>
      {listings.map((listing, index) => (
        <div className="py-[7.5px]" onClick={openModal} key={index}>
          <h4 className="text-moon-14 text-gray-600 mb-[10px]">
            {listing.month}
          </h4>
          {listing.items.map((item, itemIndex) => (
            <div
              className="flex items-center flex-row p-[0px_8px] my-[5px] rounded-[8px] bg-white"
              key={itemIndex}
            >
              <div className="p-[8px] flex-[0_0_315px]">
                <h4 className="text-moon-16 font-semibold  line-clamp-1">
                  {item.title}
                </h4>
              </div>
              <div className="p-[8px] flex-[0_0_141px]">
                <span className="block text-trunks text-moon-12 leading-1">
                  Section
                </span>
                <h4 className="text-moon-16  font-semibold  line-clamp-1">
                  {item.section}
                </h4>
              </div>
              <div className="p-[8px] flex-[0_0_146px]">
                <span className="block text-trunks text-moon-12">
                  Requested
                </span>
                <h4 className="text-moon-16  font-semibold  line-clamp-1">
                  {item.requested}
                </h4>
              </div>
              <div className="p-[8px] flex-1">
                <div className="text-moon-14 text-gray-600  line-clamp-1">
                  {item.date}
                </div>
              </div>
              <div className="p-[8px]">
                <Chip
                  className={`px-4 h-10 py-2 font-semibold text-moon-14 rounded-moon-i-sm relative z-0 flex justify-center items-center no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 min-w-[112px] ${
                    item.chipText === "Allocate"
                      ? "bg-pink-50 text-pink-600"
                      : item.chipText === "Provided"
                      ? "bg-gray-50 text-bulma"
                      : "bg-gray-50 text-gray-400 "
                  }`}
                >
                  {item.chipText}
                </Chip>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default NeedListing;
