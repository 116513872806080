import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  Input,
  Label,
  Modal,
} from "@heathmont/moon-core-tw";
import { ControlsClose } from "@heathmont/moon-icons";
import React, { useEffect, useRef, useState } from "react";
interface ModalAddSectionProps {
  isOpenModalSection: boolean;
  closeModalSection: () => void;
  branchData: any;
  getAllSectionsList: string[];
  sectionsList: any;
  branchId?: string;
  addSectionsList: (data?: any, sections?: any) => void;
}

const AddSection = ({
  isOpenModalSection,
  closeModalSection,
  branchData,
  getAllSectionsList,
  addSectionsList,

}: ModalAddSectionProps) => {
  console.log("Get all sections", getAllSectionsList);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [manualSection, setManualSections] = useState<any>();
  const modalContentRef: any = useRef(null);

  const handleInputChange = (event: any) => {
    setManualSections(event?.target.value);
  };
  const handleCheckboxChange = (event: any) => {
    const { id, checked } = event.target;
    console.log("Event", event);
    if (checked) {
      setSelectedSections([...selectedSections, id]);
    } else {
      setSelectedSections(
        selectedSections?.filter((section) => section !== id),
      );
    }
    console.log("Sele", selectedSections);
  };
  const addSection = () => {
    if (manualSection) {
      if (!selectedSections?.includes(manualSection)) {
        setSelectedSections((prevSelectedSections) => [
          ...prevSelectedSections,
          manualSection,
        ]);
        getAllSectionsList?.unshift(manualSection);
        modalContentRef?.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    setManualSections("");
  };

  useEffect(() => {
    setSelectedSections([] as string[]);
  }, [isOpenModalSection]);

  return (
    <>
      <Modal
        open={isOpenModalSection}
        onClose={() => {
          closeModalSection();
          setSelectedSections("" as any);
        }}
      >
        <Modal.Backdrop />
        <Modal.Panel className="max-w-[500px]">
          <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus">
            <h3 className="text-moon-20 font-semibold">Add Section (s)</h3>

            <IconButton
              onClick={() => {
                closeModalSection();
                setSelectedSections("" as any);
                setManualSections("");
              }}
              variant="ghost"
              icon={<ControlsClose />}
            />
          </div>
          <div className="modal-content px-4 py-2  border-b border-beerus">
            <div className="flex items-center rounded-md justify-between mb-3">
              <p className="text-moon-16 text-blackcst font-semibold">
                {branchData?.branchName}
              </p>
              <span className="text-moon-12 text-gray-600">Branch</span>
            </div>
            <div className="w-full mb-[24px]">
              {/* <Label htmlFor="#" className="text-moon-14 text-[#000]">
                Selected Sections
                <span className="font-normal inline-flex items-center text-moon-10 justify-center bg-hit text-white rounded-full py-[2px] px-[6px] min-w-[20px] ml-3">
                  {sectionsList?.length}
                </span>
              </Label>
              <div className="flex items-center flex-wrap gap-[8px] pr-[16px] max-h-[100px] overflow-y-auto">
                {sectionsList?.map((section: any) => (
                  <Chip
                    variant="ghost"
                    className="text-gray-400 bg-gray-100 inline-flex gap-2"
                    iconRight={<ControlsClose className="text-moon-24" />}
                  >
                    {section?.sectionName}
                  </Chip>
                ))}{" "}
                <Chip
                  variant="ghost"
                  className="text-gray-400  bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  Pathology
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400 bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  Neurology
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400 bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  Pathology
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400  bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  Pathology
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400 bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  ICU
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400 bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  Dermatology
                </Chip>
                <Chip
                  variant="ghost"
                  className="text-gray-400 bg-gray-100 inline-flex gap-2"
                  iconRight={<ControlsClose className="text-moon-24" />}
                >
                  ICU
                </Chip>
              </div> */}
            </div>
            <Label htmlFor="#" className="text-moon-14 text-[#000]">
              Add Sections
              <span className="font-normal inline-flex items-center text-moon-10 justify-center bg-hit text-white rounded-full py-[2px] px-[6px] min-w-[20px] ml-3">
                {getAllSectionsList?.length}
              </span>
            </Label>

            <div
              className="w-full mb-[24px] max-h-[150px] overflow-y-auto"
              ref={modalContentRef}
            >
            {getAllSectionsList
              ?.sort((a: string, b: string) => a.localeCompare(b)) // Sort alphabetically
              .map((data: string) => (
                <div className="mb-2" key={data}>
                  <Checkbox
                    label={data}
                    bgColor="bg-hit"
                    id={data}
                    checked={selectedSections?.includes(data)}
                    onChange={handleCheckboxChange}
                  />
                </div>
              ))}
              {/* <div className="mb-2">
                <Checkbox label="Pathology" bgColor="bg-hit" id="2" />
              </div>
              <div className="mb-2">
                <Checkbox label="Neurology" bgColor="bg-hit" id="3" />
              </div>
              <div className="mb-2">
                <Checkbox label="Pathology" bgColor="bg-hit" id="4" />
              </div>
              <div className="mb-2">
                <Checkbox label="ICU" bgColor="bg-hit" id="5" />
              </div> */}
            </div>
            <div className="w-full mb-[24px]">
              <Label htmlFor="#" className="text-moon-16 text-[#000]">
                Others
              </Label>
              <div className="flex gap-[8px]">
                <div className="flex-1">
                  <Input
                    type="text"
                    placeholder="Add Manually..."
                    value={manualSection ? manualSection : ""}
                    onChange={handleInputChange}
                  />
                </div>
                <Button
                  variant="ghost"
                  className="text-hit"
                  onClick={() => {
                    addSection();
                  }}
                  disabled={manualSection?.length === 0}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
          <div className="modal-footer  px-4 py-2  flex items-center justify-end gap-[8px]">
            <Button
              variant="secondary"
              onClick={() => {
                closeModalSection();
                setSelectedSections("" as any);
                setManualSections("");
              }}
            >
              Discard
            </Button>
            <Button
              variant="tertiary"
              onClick={() => {
                addSectionsList(branchData?._id, selectedSections as any);
                closeModalSection();
                setSelectedSections("" as any);
              }}
              disabled={selectedSections?.length === 0}
            >
              Save
            </Button>
          </div>
        </Modal.Panel>
      </Modal>
    </>
  );
};

export default AddSection;
