import React from "react";
import { Drawer, IconButton } from "@heathmont/moon-core-tw";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";
import DateCard from "../../Widgets/cards/DateCard";
import DetailShift from "../../Widgets/cards/DetailShift";
import TrackingFilter from "../../Widgets/TrackingPool/TrackingFilter";
import { IEmployeeRecord } from "../../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";
// const ShiftData = [
//   {
//     shift: "Day",
//     status: "Balanced",
//     capacity: 8,
//     patients: 20,
//     utilization: 20,
//     criticalPatients: 3,
//   },
//   // Add more shift data objects as needed
// ];

interface ShiftDataItem {
  shift: string;
  status: string;
  capacity: number;
  patients: number;
  utilization: number;
  criticalPatients: number;
}

interface ScheduleDrawerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  shiftData: ShiftDataItem[];
}

const ShiftData: ShiftDataItem[] = [
  {
    shift: "Day",
    status: "Balanced",
    capacity: 8,
    patients: 20,
    utilization: 20,
    criticalPatients: 3,
  },
  // Add more shift data objects as needed
];

interface Props {
  data: IEmployeeRecord;
  isOpen: boolean;
  setIsOpen: () => any;
  handleClose: () => any;
}

const ScheduleDrawer: React.FC<Props> = ({
  data,
  isOpen,
  setIsOpen,
  handleClose,
}) => {
  return (
    <Drawer open={isOpen} setOpen={setIsOpen}>
      <Drawer.Panel className="max-w-[507px]">
        <div className="flex justify-between items-center p-3 border-b border-beerus">
          <h3 className="text-moon-20 font-bold transition-colors">
            View Schedule
          </h3>
          <IconButton variant="ghost" onClick={handleClose}>
            <ControlsCloseSmall />
          </IconButton>
        </div>
        <div className="p-[24px]">
          <DateCard
            avatarImageUrl=""
            avatarSize="xs"
            scheduledBy={
              data?.specificDayRecord?.scheduledBy?.firstName +
              " " +
              data?.specificDayRecord?.scheduledBy.lastName
            }
            day={data?.specificDayRecord?.day}
            date={data?.specificDayRecord?.dayNumber}
            month={data?.specificDayRecord?.monthName}
            year={data?.specificDayRecord?.year}
          />
          <hr className="border-t border-beerus my-[24px]" />

          {/* {ShiftData.map((shiftData: ShiftDataItem, index: any) => ( */}
          <DetailShift
            shift={
              data?.specificDayRecord?.dayShift ? "Day Shift" : "Night Shift"
            }
            status={data?.specificDayRecord?.status}
            capacity={data?.specificDayRecord?.capacity}
            patients={data?.specificDayRecord?.numberOfPatients}
            utilization={data?.specificDayRecord?.utilization}
            criticalPatients={data?.specificDayRecord?.criticalPatients}
          />
          {/* ))} */}
          <hr className="border-t border-beerus my-[24px]" />
          <TrackingFilter />
        </div>
      </Drawer.Panel>
      <Drawer.Backdrop />
    </Drawer>
  );
};

export default ScheduleDrawer;
