import React, { useEffect, useState } from "react";
import CalendarControl from "../UI/CalendarControl";
import DropDown from "../UI/DropDown";
import FilterShifts from "../UI/FilterShifts";
import FilterWeekMonth from "../UI/FilterWeekMonth";
import WeeklyCalendar from "../Calendar/weekly";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../../store/store";
import {
  currentMonth,
  setSelectedCalendar,
} from "../../../features/nurse-manager/Time Slice/timeSlice";
import { Button } from "@heathmont/moon-core-tw";
import { respondScheduleRequest } from "../../../services/nurse-manager-services/nursedirector.service";
import { ISectionInfo } from "../../../types/nurse-manager-interfaces/ISectionInfo.interface";
import { ISection } from "../../../types/branch-admin-interfaces/ISection.interface";
import { years } from "../../../utils/years";
import { months } from "../../../utils/months";
import { generateWeeks } from "../../../utils/weeks";
import { ScheduleRecordCardData } from "../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import { useNavigate } from "react-router-dom";
export let data: any;

let dayIndex = moment().startOf("month").day();

dayIndex = dayIndex === 0 ? 6 : dayIndex - 1;

interface Props {
  sectionInfo?: ISectionInfo;
  setControlView?: (data: boolean) => void;
  controlView?: boolean;
  selectedSection?: ISection;
  selectedRecord?: ScheduleRecordCardData;
  setIsShowSchedule?: (check: boolean) => void;
}

const WeeklyScheduleCalendar: React.FC<Props> = ({
  sectionInfo,
  setControlView,
  controlView,
  selectedSection,
  selectedRecord,
  setIsShowSchedule,
}) => {
  // const { date }: any = useSelector((state: RootState) => state.time);
  const User = useSelector((state: RootState) => state.auth.User);
  const [isScheduleAvailable, setIsScheduleAvailable] = useState(false);
  const [month, setMonth] = useState(
    {
      name: "",
      monthName: "",
      label: "",
    }
    // {
    //   name: moment()?.format("MMMM"),
    //   monthName: moment()?.format("MMMM"),
    //   label: moment()?.format("MM"),
    // }
  );

  const [year, setYear] = useState(
    {
      name: "",
      year: "",
      label: "",
    }
    //   {
    //   name: moment()?.format("YYYY"),
    //   year: moment()?.format("YYYY"),
    //   label: "year",
    // }
  );
  const currentDate = moment();
  // const currentDate = moment("2023-10-08");
  const dateInfo = moment({
    month: currentDate.month(),
    year: currentDate.year(),
    day: currentDate.date(),
  });

  const currentWeek = Math.ceil(currentDate.date() / 7);
  const [week, setWeek] = useState({
    name: `Week ${currentWeek}`,
    weekLabel: "Week",
    label: currentWeek,
  });

  // const years = Array.from({ length: 15 }, (_, index) => ({
  //   name: moment().year() - index,
  //   year: moment().year() - index,
  //   label: "year",
  // }));

  // const months = Array.from({ length: 12 }, (_, index) => ({
  //   name: `${moment().month(index).format("MMMM")}`,
  //   monthName: moment().month(index).format("MMMM"),
  //   label: index + 1,
  // }));

  // const selectedMonthIndex = Number(moment().month(date.month).month());

  // const getWeeksInMonth = () => {
  //   const currentMonth = date?.currentMonth; // Get the current month
  //   const year = date?.year; // Get the current year

  //   const daysInMonth = moment(
  //     `${year}-${+currentMonth}`,
  //     "YYYY-MM",
  //   ).daysInMonth(); // Get the number of days in the month

  //   console.log("Days in month", daysInMonth);
  //   const firstDayOfMonth = moment(`${year}-${+currentMonth}-01`, "YYYY-MM-DD"); // Get the first day of the month
  //   const lastDayOfMonth = moment(
  //     `${year}-${+currentMonth}-${daysInMonth}`,
  //     "YYYY-MM-DD",
  //   ); // Get the last day of the month

  //   console.log("first Day Of Month", firstDayOfMonth);
  //   console.log("last Day Of Month", lastDayOfMonth);
  //   const firstWeek = firstDayOfMonth.isoWeek(); // Get the week of the first day
  //   const lastWeek = lastDayOfMonth.isoWeek(); // Get the week of the last day
  //   const weeksInMonth = +lastWeek - +firstWeek + 1;
  //   // Calculate the number of weeks
  //   console.log("first week Of Month", firstWeek);
  //   console.log("last week Of Month", lastWeek);
  //   console.log("weeks in month", weeksInMonth);
  //   return weeksInMonth;
  // };

  // const weekCount = () => {
  //   // month_number is in the range 1..12

  //   const year = date?.year;
  //   const currentMonth = date?.currentMonth;
  //   const firstOfMonth = moment(`${year}-${currentMonth}-01`, "YYYY-MM-DD");
  //   const lastOfMonth = firstOfMonth.clone().endOf("month");
  //   const totalCalendarDaysForMonth = firstOfMonth.day() + lastOfMonth.date();
  //   return Math.ceil(totalCalendarDaysForMonth / 7);
  // };

  //updated function
  function getWeeksInMonth() {
    const firstDay = new Date(+year?.year, +month?.label - 1, 1);
    const lastDay = new Date(+year?.year, +month?.label, 0);
    console.log("firstDay", firstDay);
    console.log("lastDay", lastDay);
    const daysInMonth = lastDay.getDate();
    return Math.ceil(daysInMonth / 7);
  }
  // const numberOfWeekss = getWeeksInMonth();

  // console.log("Number of weeks", numberOfWeekss);
  // const numberOfWeeks = getWeeksInMonth();
  // const numberOfWeeks = weekCount();
  const numberOfWeeks = getWeeksInMonth();

  // Generate data for the weeks
  // const weeks = Array.from({ length: numberOfWeeks }, (_, index) => ({
  //   name: `Week ${index + 1}`,
  //   weekLabel: "Week",
  //   label: index + 1,
  // }));

  const weeks = generateWeeks(numberOfWeeks);

  const responseOfScheduleRequest = async (params: any) => {
    const res: any = await respondScheduleRequest(params);
    if (res?.res) {
      console.log("Success");
      // setFetchAgain(!fetchAgain);
    }
  };
  useEffect(() => {
    if (!selectedRecord) return;
    setMonth({
      name: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      monthName: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MMMM"),
      label: moment()
        ?.month((selectedRecord?.month as number) - 1)
        ?.format("MM"),
    });
    setYear({
      name: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      year: moment()
        ?.year(selectedRecord?.year as number)
        ?.format("YYYY"),
      label: "year",
    });
  }, [selectedRecord]);

  useEffect(() => {
    if (!sectionInfo) return;

    console.log("Section Info", sectionInfo);
    setMonth({
      name: `${moment()
        .month(sectionInfo?.month - 1)
        .format("MMMM")}`,
      monthName: `${moment()
        .month(sectionInfo?.month - 1)
        .format("MMMM")}`,
      label: moment()
        .month(sectionInfo?.month - 1)
        .format("MM"),
    });
    setYear({
      name: `${moment().year(sectionInfo?.year).format("YYYY")}`,
      year: `${moment().year(sectionInfo?.year).format("YYYY")}`,
      label: "year",
    });
    setWeek({
      name: `Week 1`,
      weekLabel: "Week",
      label: 1,
    });

    console.log("object", moment(sectionInfo?.createdAt).format("YYYY"));
  }, [sectionInfo]);

  const navigate = useNavigate()
  return (
    <>
      <div className="py-[10px] px-7 bg-gohan shadow-moon-lg relative">
        <div className="flex gap-[10px] items-center w-full">
          {/* <CalendarControl data={data} /> */}
          <div className="flex-1 flex gap-[10px]">
            {isScheduleAvailable ? (
              <>
                <div className="max-w-[137px] w-full">
                  <DropDown
                    field={"name"}
                    value={week}
                    options={weeks}
                    setOptions={setWeek}
                  />
                </div>

                <div>
                  <FilterShifts />
                </div>
                <div>
                  <FilterWeekMonth />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {sectionInfo ? (
            <div className="flex items-center gap-5">
              {sectionInfo?.status !== "accepted" &&
              sectionInfo?.status !== "declined" ? (
                <div className="flex items-center gap-[10px]">
                  <Button
                    // className=""
                    variant="secondary"
                    // className="min-h-[42px]"
                    // size="sm"
                    onClick={() =>
                      responseOfScheduleRequest({
                        sectionId: sectionInfo?._id,
                        status: "declined",
                      })
                    }
                  >
                    Decline
                  </Button>
                  <Button
                    variant="tertiary"
                    // className="min-h-[42px]"
                    // size="sm"
                    onClick={() =>
                      responseOfScheduleRequest({
                        sectionId: sectionInfo?._id,
                        status: "accepted",
                      })
                    }
                  >
                    Accept
                  </Button>
                </div>
              ) : (
                ""
              )}
              <Button
                variant="secondary"
                // className="min-h-[42px]"
                // size="sm"
                onClick={() => setControlView && setControlView(!controlView)}
              >
                Back to Scheduler
              </Button>
            </div>
          ) : User?.designation === "nurse director" ? (
            <div className="flex-[0_0_170px]">
              {/* <DropDown
                option={`Sections`}
                data={sections}
                onSectionChange={handleSectionChange}
              /> */}
            </div>
          ) : (
            ""
          )}
          {User?.designation !== "nurse director" ? (
            <>
              {/* <div className="max-w-[173px] w-full">
                <DropDown
                  field={"monthName"}
                  value={month}
                  options={months}
                  setOptions={setMonth}
                />
              </div>
              <div className="max-w-[173px] w-full">
                <DropDown
                  field={"name"}
                  value={year}
                  options={years}
                  setOptions={setYear}
                />
              </div> */}
              <div className="max-w-[138px] w-full">
                <Button
                  // className="min-h-[42px]"
                  variant="secondary"
                  onClick={() => {
                    setIsShowSchedule && setIsShowSchedule(false);
                    navigate('/')
                  }}
                >
                  Back to Records
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="bg-white">
        <WeeklyCalendar
          sectionInfo={sectionInfo as ISectionInfo}
          setIsScheduleAvailable={setIsScheduleAvailable}
          selectedSection={selectedSection}
          selectedRecord={selectedRecord}
          month={month}
          year={year}
          week={week}
        />
      </div>
    </>
  );
};

export default WeeklyScheduleCalendar;

// import React, { useEffect, useState } from "react";
// import CalendarControl from "../UI/CalendarControl";
// import DropDown from "../UI/DropDown";
// import FilterShifts from "../UI/FilterShifts";
// import FilterWeekMonth from "../UI/FilterWeekMonth";
// import WeeklyCalendar from "../Calendar/weekly";
// import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
// import { RootState } from "../../../store/store";
// import {
//   currentMonth,
//   setSelectedCalendar,
// } from "../../../features/nurse-manager/Time Slice/timeSlice";
// export let data: any;

// let dayIndex = moment().startOf("month").day();

// dayIndex = dayIndex === 0 ? 6 : dayIndex - 1;

// const currentWeek = Math.floor((moment().date() + dayIndex - 1) / 7);
// //const month = [{ name: "November 2023" }, { name: "December 2023" }];

// const WeeklyScheduleCalendar = () => {
//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(setSelectedCalendar("week"));
//   }, []);

//   const { date }: any = useSelector((state: RootState) => state.time);

//   const selectedMonthIndex = Number(moment().month(date.month).month());

//   const getWeeksInMonth = () => {
//     const currentMonth = selectedMonthIndex; // Get the current month
//     const year = moment().year(); // Get the current year
//     const daysInMonth = moment(
//       `${year}-${currentMonth + 1}`,
//       "YYYY-MM"
//     ).daysInMonth(); // Get the number of days in the month
//     const firstDayOfMonth = moment(
//       `${year}-${currentMonth + 1}-01`,
//       "YYYY-MM-DD"
//     ); // Get the first day of the month
//     const lastDayOfMonth = moment(
//       `${year}-${currentMonth + 1}-${daysInMonth}`,
//       "YYYY-MM-DD"
//     ); // Get the last day of the month

//     const firstWeek = firstDayOfMonth.week(); // Get the week of the first day
//     const lastWeek = lastDayOfMonth.week(); // Get the week of the last day

//     const weeksInMonth = lastWeek - firstWeek + 1; // Calculate the number of weeks

//     return weeksInMonth;
//   };

//   const numberOfWeeks = getWeeksInMonth();

//   // Generate data for the weeks
//   data = Array.from({ length: numberOfWeeks }, (_, index) => ({
//     name: `Week ${index + 1}`,
//     label: index + 1,
//   }));
//   // console.log("week check: ", data);
//   return (
//     <>
//       <div className="py-[18px] px-7  shadow-moon-lg relative z-10">
//         <div className="flex gap-[10px] items-center w-full">
//           <CalendarControl data={data} />
//           <div className="flex-1 flex gap-[10px]">
//             <div className="max-w-[137px] w-full">
//               <DropDown
//                 option={
//                   date?.month === currentMonth
//                     ? data[currentWeek - 1]?.name
//                     : data[0]?.name
//                 }
//                 data={data}
//               />
//             </div>
//             <div>
//               <FilterShifts />
//             </div>
//             <div>
//               <FilterWeekMonth />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bg-white">
//         <WeeklyCalendar />
//       </div>
//     </>
//   );
// };

// export default WeeklyScheduleCalendar;
