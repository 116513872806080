import React from "react";
import { Button } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
import { GenericLightningBolt } from "@heathmont/moon-icons-tw";
import OverlappedAvatar from "../UI/OverlappedAvatar";

export default function ScheduleEmployDay() {
  return (
    <>
      <div className="py-2 px-7 bg-primary  z-0">
        <div className="py-3 px-4 flex justify-between items-center bg-white rounded-lg border-l-4 border-s-darkpink">
          <div className="flex items-center">
            <div className="flex items-center space-x-2 me-5 text-sm">
              <Button
                className="w-auto bg-dark1 text-blackcst  rounded-lg py-2.5 px-3"
                index={0}
              >
                Upcoming
              </Button>
              <Button
                className="flex items-center bg-pink-200 w-auto text-pink-500 rounded-lg py-2.5 px-3"
                index={0}
              >
                <GenericLightningBolt className="text-moon-20 b me-2 " />
                Important
              </Button>
            </div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
            repellendus odit natus doloribus dignissimos beatae in corrupti.
            {/* <p className="me-12 text-sm font-semibold text-blackcst  ">
              27 Septermber 2022
              <span className="block text-grey600 text-xs">
                8:30am - 2:00pm
              </span>
            </p>
            <p className="me-8 text-piccolo text-sm font-semibold  line-clamp-1">
              Aligning employees to ICU day
            </p>
            <p className="text-sm font-semibold text-grey600">ICU</p> */}
          </div>
          {/* <OverlappedAvatar /> */}
        </div>
      </div>
    </>
  );
}
