import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import "./App.css";
import ScheduleHeader from "../../Components/super-admin/layout/ScheduleHeader";
import Home from "./settings";
import Profile from "../profile";
import useIdleTime from "../../hooks/branch-admin/useIdleTime";
import { useIdle } from "@custom-react-hooks/all";
const Super_Admin_Routes = () => {
  const {idleTime} = useIdleTime();
  const isIdle  = useIdle(idleTime * 1000);

  
  return (
    <>
      <Router>
        <header className=" z-1">
          <ScheduleHeader />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default Super_Admin_Routes;
