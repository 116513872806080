export function capitalizeWords(inputString: string) {
  let words = inputString.split(" ");
  let capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  let resultString = capitalizedWords.join(" ");

  return resultString;
}
