import { Chip } from "@heathmont/moon-core-tw";
import React from "react";

interface Item {
  leaveType: string;
  from: any;
  to: any;
  status: string;
}
interface ListingLeavesProps {
  item: Item;
}

interface ListingLeavesProps {
  // title: string;
  // description: string;
  // date: string;
  // status: string;
  leaveType: string;
  from: any;
  to: any;
  status: string;
}

const ListingLeaves = ({
  // title,
  // description,
  // date,
  // status,
  leaveType,
  from,
  to,
  status,
}: ListingLeavesProps) => {
  let statusIndicatorColor;
  let bgIndicatorColor;
  let textColorTitle;
  let statusColor;
  let textColor;

  if (leaveType === "Study Leave") {
    statusIndicatorColor = "bg-piccolo";
    textColorTitle = "text-piccolo";
  } else {
    statusIndicatorColor = "bg-chichi";
    textColorTitle = "text-chichi";
  }

  if (status === "Requested") {
    bgIndicatorColor = "bg-piccolo";
    textColor = "text-white";
  } else {
    bgIndicatorColor = "bg-green-100";
    textColor = "text-green-500";
  }
  function formatDateWithDay(inputDateString: any) {
    const inputDate = new Date(inputDateString);
    const options: any = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    const formattedDate = inputDate
      .toLocaleDateString("en-US", options)
      .toUpperCase();
    return formattedDate;
  }
  return (
    <div className="flex flex-wrap py-[8px] ">
      <span
        className={`inline-flex items-center w-[8px] h-[8px] ${statusIndicatorColor} rounded-full relative top-[8px]`}
      ></span>
      <div className="flex-1 px-2">
        <h4 className={`text-moon-14 font-bold ${textColorTitle}`}>
          {leaveType}
        </h4>
        {/* <p className="text-moon-14 text-gray-600 line-clamp-1">{description}</p> */}
        <p className="text-moon-10 uppercase font-bold">
          {formatDateWithDay(from?.date) + " " + formatDateWithDay(to?.date)}
        </p>
      </div>
      <div>
        <Chip
          className={`px-3 h-8 py-1 gap-2 text-moon-12 rounded-moon-i-sm relative z-0 flex justify-center items-center font-medium no-underline overflow-hidden whitespace-nowrap select-none transition duration-200 ${statusColor} ${textColor} ${bgIndicatorColor}`}
        >
          {status}
        </Chip>
      </div>
    </div>
  );
};

export default ListingLeaves;
