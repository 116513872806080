import { VIEWS } from "../../enums/branch-admin/views.enums";
import {
  EMPLOYEES_FILTER,
  PATIENTS_FILTER,
  LEAVES_FILTER,
  REQUEST_TIMEOFF_FILTER,
} from "../../enums/branch-admin/viewsFilter.enums";

export const handleViewSelect = (option: string | null) => {
  switch (option) {
    case VIEWS.PATIENTS:
      return [
        { name: PATIENTS_FILTER.ID },
        // { name: PATIENTS_FILTER.PATIENT_NAME },
        { name: PATIENTS_FILTER.ADMISSION_DATE },
        { name: PATIENTS_FILTER.DISCHARGE_DATE },
        { name: PATIENTS_FILTER.ALLOCATED_SECTION },
        { name: PATIENTS_FILTER.ASSIGNED_ROOM },
        { name: PATIENTS_FILTER.NEEDY_PROFILE },
      ];
    case VIEWS.EMPLOYEES:
      return [
        { name: EMPLOYEES_FILTER.ID },
        // { name: EMPLOYEES_FILTER.EMPLOYEE_NAME },
        { name: EMPLOYEES_FILTER.DESIGNATION },
        { name: EMPLOYEES_FILTER.JOINING_DATE },
        { name: EMPLOYEES_FILTER.APPOINTED_SECTION },
        { name: EMPLOYEES_FILTER.SENIORITY },
      ];
    case VIEWS.LEAVES:
      return [
        { name: LEAVES_FILTER.ID },
        // { name: LEAVES_FILTER.EMPLOYEE_NAME },
        { name: LEAVES_FILTER.EMPLOYEE_SECTION },
        { name: LEAVES_FILTER.LEAVE_TYPE },
        { name: LEAVES_FILTER.STATUS },
        { name: LEAVES_FILTER.LEAVES_FROM_TO_DATE },
        // { name: LEAVES_FILTER.LEAVES_TO },
      ];
    case VIEWS.REQUEST_TIME_OFF:
      return [
        { name: REQUEST_TIMEOFF_FILTER.ID },
        // { name: REQUEST_TIMEOFF_FILTER.EMPLOYEE_NAME },
        // { name: REQUEST_TIMEOFF_FILTER.LEAVE_TYPE },
        // { name: REQUEST_TIMEOFF_FILTER.STATUS },
        { name: REQUEST_TIMEOFF_FILTER.SECTION },
        // { name: REQUEST_TIMEOFF_FILTER.MONTH }, //// just un-comment these 2 if want to add filter for month and year in RTO
        // { name: REQUEST_TIMEOFF_FILTER.YEAR },
        { name: REQUEST_TIMEOFF_FILTER.DATE },
        // { name: REQUEST_TIMEOFF_FILTER.LEAVES_TO },
      ];
    default:
      return [{ name: "" }];
  }
};
