import React, { useEffect, useState } from "react";
import { Button, Drawer, IconButton, Input } from "@heathmont/moon-core-tw";
import {
  ControlsCloseSmall,
  ControlsPlus,
  GenericDelete,
} from "@heathmont/moon-icons";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import DropDownFilter from "./DropDownFilter";
import FilterFields from "./FilterFields";
import { FILTER_SELECT } from "../../../enums/branch-admin/filters.enums";
import { EMPLOYEES_FILTER } from "../../../enums/branch-admin/viewsFilter.enums";
interface Options {
  name: string;
}
interface FilterElements {
  id: number;
}

interface DisableChecks {
  isForAllThreeWithInput: boolean;
  isForAllThreeWithDate: boolean;
  isForInputOnly: boolean;
  isForValueDropownOnly: boolean;
  isForLeavesDate: boolean;
}
interface Props {
  isFilterOpen: boolean;
  setIsFilterOpen: (filterCheck: boolean) => void;
  handleClose: () => void;
  filtersElements: FilterElements[];
  handleAddFilter: (elementsArray: FilterElements[]) => void;
  newFilter: IFilterParameters;
  handleRemoveFilter: (indexId: number) => void;
  filterOptions: Options[];
  options: Options[];
  match: Options[];
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersDataArray: IFilterParameters[]) => void;
  // selectedFilterOptions:string;
  // setSelectedFilterOptions: (filterOptions: string) => void;
  handleFilterValue: (
    id: number,
    value: string | Date | string[],
    isDate?: boolean,
    isRangeDate?: boolean
  ) => void;
  clearAllParams: () => void;
  handleApplyFilter: () => void;
}

const FilterDrawer: React.FC<Props> = ({
  isFilterOpen,
  setIsFilterOpen,
  handleClose,
  filtersElements,
  handleAddFilter,
  newFilter,
  handleRemoveFilter,
  filterOptions,
  options,
  match,
  filtersArray,
  setFiltersArray,
  handleFilterValue,
  clearAllParams,
  handleApplyFilter,
}) => {
  const [filterValue, setFilterValue] = useState<string | Date>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [idPatternErrMsg, setIdPatternErrMsg] = useState<string>("");

  const filter = "filter";
  const option = "option";
  const value = "value";
  let isEmpty =
    // filtersArray.length === 0 ||
    filtersArray?.some(
      (obj) => obj[option] === "" || obj[value] === "" //|| obj[filter] === ""
    ) || idPatternErrMsg !== "";

  // let isFalsePattern =

  //"^EMP\\d{3}$"

  // if (!value.match(regex)) {
  //   setIdPatternErrMsg("Please enter a valid Id Pattern");
  // }
  // ||filtersArray?.some(
  //   (obj) =>
  //     obj[option] === EMPLOYEES_FILTER.ID &&
  //     obj[filter] !== "Contains" &&
  //     (obj[value] as string)?.match("^EMP\\d{3}$") //|| obj[filter] === ""
  // )
  console.log("is empty: ", isEmpty);
  console.log("filters array: ", filtersArray);
  return (
    <div>
      <Drawer open={isFilterOpen} setOpen={setIsFilterOpen}>
        <Drawer.Panel className="flex flex-col">
          <div className="p-4 flex justify-between items-center border-b border-beerus">
            <h3 className="text-moon-20 font-bold transition-colors">
              Filters <span>(Total Filters {filtersElements?.length})</span>
            </h3>
            <IconButton variant="ghost" onClick={handleClose}>
              <ControlsCloseSmall />
            </IconButton>
          </div>
          <div className="p-4 flex-1 overflow-y-auto">
            {filtersElements?.map((filter, index) => (
              <div key={index}>
                <div className="flex justify-between items-center mb-3">
                  <span>Filter {index + 1}</span>
                  <IconButton
                    variant="ghost"
                    icon={<GenericDelete />}
                    onClick={() => {
                      handleRemoveFilter(filter?.id);
                      // setFilterValue("");
                    }}
                  />
                </div>
                <div key={index} className="flex flex-col gap-2 mb-2">
                  <div className="w-full">
                    <DropDownFilter
                      id={index}
                      type="option"
                      data={options}
                      filtersArray={filtersArray as IFilterParameters[]}
                      setFiltersArray={setFiltersArray}
                      setSelectedFilter={setSelectedFilter}
                      setSelectedOption={setSelectedOption}
                      handleFilterValue={handleFilterValue}
                    />
                  </div>

                  {filtersArray[index]?.option && (
                    <FilterFields
                      index={index}
                      filter={filter}
                      // disableCheck={disableCheck}
                      // setDisableCheck={setDisableCheck}
                      options={options}
                      match={match}
                      filtersArray={filtersArray}
                      setFiltersArray={setFiltersArray}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      handleFilterValue={handleFilterValue}
                      setFilterValue={setFilterValue}
                      idPatternErrMsg={idPatternErrMsg}
                      setIdPatternErrMsg={setIdPatternErrMsg}
                    />
                  )}
                </div>
              </div>
            ))}

            {filterOptions?.length !== filtersElements?.length && (
              <Button
                variant="ghost"
                iconLeft={<ControlsPlus />}
                onClick={() => {
                  handleAddFilter(filtersElements);
                  setFiltersArray([...filtersArray, newFilter]);
                  // setFilterValue("");
                }}
              >
                Filter
              </Button>
            )}
          </div>
          <div className="p-4 flex gap-4 justify-end items-center border-t border-beerus">
            <Button
              variant="ghost"
              onClick={() => {
                if (filtersArray?.length > 0) clearAllParams();
              }}
              disabled={filtersArray?.length === 0}
            >
              Clear
            </Button>
            <Button
              variant="tertiary"
              onClick={() => {
                // if (
                //   filtersArray?.length > 0
                //   // && !isEmpty
                // )
                // const deepCopyFiltersArray = JSON.parse(
                //   JSON.stringify(filtersArray)
                // );
                // setFiltersChipsArray(deepCopyFiltersArray);
                handleApplyFilter();
              }}
              disabled={isEmpty}
            >
              Apply
            </Button>
          </div>
        </Drawer.Panel>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
