import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Section {
  _id: string;
  sectionName: string;
}

const initialState: Section = {
  _id: "",
  sectionName: "",
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setSection: (state, action: PayloadAction<Section>) => {
      console.log("action.payload:", action.payload);
      return action.payload;
    },
  },
});

export const { setSection } = sectionSlice.actions;

export default sectionSlice.reducer;
