import { ProfileData } from "../../Components/create-profile";
import { api } from "../../utils/axios";

export const canCompleteProfile = async (email: string, uuid: string) => {
  try {
    const { data, ok } = await api.get(
      `/employee/canCompleteProfile/${email}/${uuid}`
    );

    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const createBranchAdmin = async (uuid: string, reqBody: ProfileData) => {
  try {
    const { data, ok } = await api.post(
      `/employee/createBranchAdmin/${uuid}`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

interface IChangePassword {
  newPassword: string;
  currentPassword: string;
}
export const changePassword = async (reqBody: IChangePassword) => {
  try {
    const { data, ok } = await api.post(
      `/employee/changePassword/`,
      reqBody
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
