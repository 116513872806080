import { Chip, IconButton } from "@heathmont/moon-core-tw";
import { ControlsClose, ControlsCloseSmall } from "@heathmont/moon-icons";
import React from "react";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
interface Props {
  filterParams: IFilterParameters;
  handleRemoveFilterChips: (indexNum: number) => void;
}
const FilterChips: React.FC<Props> = ({
  filterParams,
  handleRemoveFilterChips,
}) => {
  return (
    <>
      <Chip
        variant="ghost"
        className="text-[#242533] inline-flex gap-2"
        iconRight={
          <IconButton
            size="xs"
            variant="ghost"
            onClick={() => handleRemoveFilterChips(filterParams?.id as number)}
            icon={<ControlsClose className="text-moon-24" />}
          />
        }
      >
        {filterParams?.option} :
        <span className="font-semibold">
          {Array.isArray(filterParams?.value)
            ? filterParams?.value[0] + " - " + filterParams?.value[1]
            : filterParams?.value}
        </span>
      </Chip>
    </>
  );
};

export default FilterChips;
