import React, { useEffect } from "react";
import { Avatar, Button } from "@heathmont/moon-core-tw";
import { getBorderColor } from "../Colors/colorSetting";
import { Notification, NotificationsList } from "./Notification";

// interface Notification {
//   avatarUrl: string;
//   name: string;
//   reason: string;
//   startDate: string;
//   endDate: string;
//   time: string;
//   roles: string[];
//   availability: boolean;
// }

// interface Roles {
//   roles: string;
// }

// interface Availability {
//   availability: string;
// }

interface Props {
  notifications: Notification[];
  // fetchNotifications: () => void;
}
// const NotificationList = ({ notifications, roles, availability }:props) => {
const NotificationList: React.FC<Props> = ({
  notifications,
  // fetchNotifications,
}) => {
  const getUserAvailabilityClass = (availability: string) => {
    return availability ? "bg-primary" : "";
  };
  // useEffect(() => {
  //   fetchNotifications();
  // }, []);
  return (
    <>
      {notifications.length !== 0 ? (
        notifications?.map((notification: Notification, index: number) => (
          <div
            key={index}
            className="p-4 hover:bg-gray-100 relative rounded-md transition-all"
          >
            {/* {notification?.name} */}

            <span
              className={`inline-block w-[8px] h-[8px] rounded-full  absolute left-[8px] top-[8px]`}
            ></span>
            <div className="flex items-start">
              <Avatar
                className={`rounded-full border-2 
              
              overflow-hidden`}
                imageUrl={""}
                size="sm"
              />
              <div className="flex-1 pl-[16px]">
                <h4 className="mb-[12px] text-moon-14 font-normal">
                  {/* <strong>{notification.name}</strong> has requested{" "}
                <strong
                  className={
                    notification.reason === "Sick leave"
                      ? "text-dodoria"
                      : notification.reason === "Annual leave"
                      ? "text-primary"
                      : ""
                  }
                >
                  {notification.reason}
                </strong>{" "}
                from{" "}
                <strong>
                  {notification.startDate} - {notification.endDate}
                </strong> */}
                  {notification?.message}
                </h4>
                {/* <div className="flex gap-2 mb-[12px]">
                <Button
                  size="sm"
                  className="border-goku border shadow-none hover:shadow-none"
                  variant="secondary"
                >
                  Reject
                </Button>
                <Button size="sm" variant="tertiary">
                  Approve
                </Button>
              </div>
              <p className="text-moon-14 text-gray-400">{notification.time}</p> */}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center text-center">
          <img
            className="w-20 h-24 py-3"
            src="images/no-notifications.svg"
            alt="No notification available"
          />
        </div>
      )}
    </>
  );
};

export default NotificationList;
