import { Avatar, Input } from "@heathmont/moon-core-tw";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import { getBorderColor } from "../../../../../Components/Colors/colorSetting";
import MonthCalender from "../../../../../Components/nurse-manager/Calendar/full-month-view/MonthCalender/MonthCalender";
import WeekDaysTab from "../../../../../Components/nurse-manager/Calendar/full-month-view/WeekDays";
import TopbarMonth from "../../../../../Components/nurse-manager/Calendar/full-month-view/header-month/TopbarMonth";
import EmployeesDropDown from "../../../../../Components/nurse-manager/Drawers/employee-detail/EmployeesDropDown";
import { RequiredEmployees } from "../../../../../Components/nurse-manager/UI/RequiredEmployees";
import ScheduleStatus from "../../../../../Components/nurse-manager/layouts/header/ScheduleStatus";
import { setNotifications } from "../../../../../features/Notification Slice/notificationSlice";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../../features/ToastSlice/toastSlice";
import { setSelectedShift } from "../../../../../features/nurse-manager/Time Slice/timeSlice";
import {
  getEmployeesForManualScheduling,
  getSchedule,
  submitManualSchedule,
  submitSchedule,
} from "../../../../../services/nurse-manager-services/nursemanager.service";
import { RootState, store } from "../../../../../store/store";
import {
  EmployeeCardData,
  IManualSchedulingRes,
} from "../../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import { IManualSchedulingSubmit } from "../../../../../types/nurse-manager-interfaces/IManualSchedulingSubmit.interface";
import {
  ISchedule,
  ScheduleDetails,
} from "../../../../../types/nurse-manager-interfaces/IMonthCalendar.interface";
import {
  MonthSelected,
  YearSelected,
} from "../../../../../types/nurse-manager-interfaces/IMonthYear.interface";

export default function MonthCalenderView() {
  const [checkedItems, setCheckedItems] = useState<string[]>([""]);
  const [checkedItemsBackup, setCheckedItemsBackup] = useState<string[]>([""]);
  const [employeesData, setEmployeesData] = useState<IManualSchedulingRes>();
  const [dateSelected, setDateSelected] = useState<string>();
  const [modalToExpand, setModalToExpand] = useState<string[]>([]);
  const [scheduleData, setScheduleData] = useState<ISchedule>();
  const [draftData, setDraftData] = useState<ISchedule>();
  const [scheduleSubmitClick, setScheduleSubmitClick] =
    useState<boolean>(false);
  const [prevSelectedDate, setPrevSelectedDate] = useState("");
  const [prevSelectedShift, setPrevSelectedShift] = useState("");
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const [loadEditDraft, setLoadEditDraft] = useState<boolean>(false);
  const [isEditingDraft, setIsEditingDraft] = useState<boolean>(false);
  const [openAllocationId, setOpenAllocationId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [shouldGetSchedule, setShouldGetSchedule] = useState<boolean>(false);
  const [month, setMonth] = useState<MonthSelected>({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: +moment().format("MM"),
  });
  const [year, setYear] = useState<YearSelected>({
    name: moment().format("YYYY"),
    year: moment().format("YYYY"),
    label: "year",
  });
  const updateData = useSelector(
    (state: RootState) => state?.notification?.updateData
  );
  const respondedScheduleId = useSelector(
    (state: RootState) => state?.notification?.actedScheduleId
  );
  const handleCheckboxChange = (employeeId: string, event: any) => {
    const { checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, employeeId]);
    } else {
      setCheckedItems(checkedItems.filter((id: any) => id !== employeeId));
    }
  };

  const areAvatarsVisible = Object.values(checkedItems)?.some(Boolean);

  const getSchedules = async (scheduleId?: string) => {
    const data: any = await getSchedule(+month?.label, +year?.year, scheduleId);
    if (data?.res) {
      setScheduleData(data?.res as ISchedule);
      console.log("data: ", data?.res?.schedule?._id);
      fetchEmployeesForManualScheduling(data?.res?.schedule?._id);
    }
  };

  const fetchEmployeesForManualScheduling = async (
    scheduleId?: string,
    day?: string,
    shift?: string
  ) => {
    setPrevSelectedDate(day as string);
    setPrevSelectedShift(shift as string);
    let res: any = await getEmployeesForManualScheduling(
      scheduleId,
      day,
      shift
    );
    console.log("check for emp: ", res?.res);
    if (res?.res) {
      let sortedEmployees = res.res.employees.sort((a:EmployeeCardData, b:EmployeeCardData) => {
        if (a.designation < b.designation) return 1;
        if (a.designation > b.designation) return -1;
        return 0;
    });
    res.res.employees = sortedEmployees    
      setEmployeesData(res?.res as IManualSchedulingRes);
      const checked = res?.res?.employees
        ?.map((data: any) => {
          if (data.isAligned === true) return data._id;
          return null; // or return undefined, or simply omit the return statement
        })
        .filter(Boolean);

      if (checked) {
        setCheckedItemsBackup(checked);
        setCheckedItems(checked);
      }
    } else {
      setEmployeesData(undefined);
    }
  };
  const submitDraftSchedule = async () => {
    const res = await submitSchedule(undefined, draftData?.schedule?._id);
    if (res?.res) {
      setScheduleSubmitClick(!scheduleSubmitClick); //may be not needed here
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Schedule submitted successfully"));
    }
  };
  const submitManSchedule = async () => {
    const date = moment(dateSelected);

    const args: IManualSchedulingSubmit = {
      params: {
        date: date?.format("DD"),
        month: date?.format("MM"),
        year: date?.format("YYYY"),
        shift: selectedShift === "sun" ? "day" : "night",
        scheduleId:
          isEditingDraft && draftData?.schedule?._id
            ? draftData?.schedule?._id
            : scheduleData?.schedule?._id,
      },
      reqBody: { employees: [...checkedItems] },
    };
    const res = await submitManualSchedule(args);
    if (res?.res) {
      await fetchEmployeesForManualScheduling(
        args?.params?.scheduleId, //scheduleData?.schedule?._id,
        args?.params?.date,
        args?.params?.shift
        // args?.params?.scheduleId
      );
      if (isEditingDraft) {
        setLoadEditDraft(!loadEditDraft);
        setModalToExpand(["item-2"]);
      } else getSchedules();
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Schedule updated successfully"));
      setSelectedDate(null);
      setOpenAllocationId(null);
    }
  };
  useEffect(() => {
    // if (updateData) {
    store.dispatch(setSelectedShift("sun"));
    getSchedules();
    setIsEditingDraft(false);
    // if (!updateData) return;
    // store.dispatch(setNotifications(false));
    // setModalToExpand(["item-2"]);
    // }
  }, [scheduleSubmitClick, shouldGetSchedule]);

  useEffect(() => {
    if (respondedScheduleId !== scheduleData?.schedule?._id) {
      store.dispatch(setNotifications(false));

      return;
    }
    if (updateData) {
      console.log("now in effect");
      store.dispatch(setSelectedShift("sun"));
      getSchedules();
      setIsEditingDraft(false);
      store.dispatch(setNotifications(false));
      // setModalToExpand(["item-2"]);
    }
  }, [updateData]);

  useEffect(() => {
    if (!dateSelected) {
      setModalToExpand(["item-2"]);
      setSelectedDate(null);
      return;
    }
    const dateFormat = moment(dateSelected);
    const day = dateFormat.format("DD");
    const shift = selectedShift === "sun" ? "day" : "night";
    const scheduleId =
      isEditingDraft && draftData?.schedule?._id
        ? draftData?.schedule?._id
        : scheduleData?.schedule?._id;

    fetchEmployeesForManualScheduling(
      scheduleId, //scheduleData?.schedule?._id,
      day,
      shift
      // scheduleId
    );
    // const openFirstModal = ["item-1"]
    setModalToExpand(["item-1"]);
  }, [dateSelected, selectedShift]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredEmployees = useMemo(() => {
    // Use the searchTerm to filter employees
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return employeesData?.employees?.filter((employee: any) =>
      employee?.employeeName.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [employeesData, searchTerm]);

  return (
    <>
      {/* <ScheduleEmployDay /> */}
      <ScheduleStatus />

      {employeesData?.employees.length !== 0 ? (
        <div className="flex flex-wrap bg-goku  flex-1">
          <div className="w-1/4">
            <SimpleBar className="flex flex-col h-[calc(100dvh-232px)] scheduler">
              <div className="pt-[25px] px-[25px] flex flex-col relative -z-[0]">
                <Input
                  className="text-moon-14 text-trunks"
                  type="text"
                  placeholder="Search by employee name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e?.target?.value)}
                />{" "}
              </div>
              {areAvatarsVisible && (
                <>
                  <div className="px-[25px] py-3 ">
                    <div className="flex space-x-[3px] overflow-x-auto">
                      {filteredEmployees?.map(
                        (listing: EmployeeCardData, index: number) =>
                          checkedItems.includes(listing._id) && (
                            <span
                              key={index}
                              className="inline-flex rounded-full shadow-md"
                            >
                              <Avatar
                                size="xs"
                                className={`rounded-full border-2 
                          ${getBorderColor(listing?.designation)}
                          overflow-hidden`}
                                imageUrl={""} // image url add when added
                              ></Avatar>
                            </span>
                          )
                      )}
                    </div>
                  </div>
                  <RequiredEmployees
                    employeesData={employeesData as IManualSchedulingRes}
                    filteredEmployees={filteredEmployees as EmployeeCardData[]}
                    checkedItems={checkedItems}
                  />
                </>
              )}

              <div className="flex-1 flex">
                <EmployeesDropDown
                  modalToExpand={modalToExpand}
                  setModalToExpand={setModalToExpand}
                  areAvatarsVisible={areAvatarsVisible}
                  filteredEmployees={filteredEmployees}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  checkedItemsBackup={checkedItemsBackup}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedDate={selectedDate}
                  month={month}
                  year={year}
                  submitManSchedule={submitManSchedule}
                />
              </div>
            </SimpleBar>
          </div>
          <div className="w-3/4">
            <TopbarMonth
              scheduleSubmitClick={scheduleSubmitClick}
              setScheduleSubmitClick={setScheduleSubmitClick}
              setDraftData={setDraftData}
              isEditingDraft={isEditingDraft}
              setIsEditingDraft={setIsEditingDraft}
              loadEditDraft={loadEditDraft}
              setLoadEditDraft={setLoadEditDraft}
              submitDraftSchedule={submitDraftSchedule}
              setModalToExpand={setModalToExpand}
              month={month}
              year={year}
              setMonth={setMonth}
              setYear={setYear}
              fetchEmployeesForManualScheduling={
                fetchEmployeesForManualScheduling
              }
              scheduleData={scheduleData as ISchedule}
              getSchedules={getSchedules}
              shouldGetSchedule={shouldGetSchedule}
              setShouldGetSchedule={setShouldGetSchedule}
            />
            <div>
              <WeekDaysTab />
            </div>
            <div>
              <MonthCalender
                scheduleData={scheduleData?.schedule as ScheduleDetails}
                setDateSelected={setDateSelected}
                isEditingDraft={isEditingDraft}
                draftData={draftData?.schedule as ScheduleDetails}
                openAllocationId={openAllocationId}
                setOpenAllocationId={setOpenAllocationId}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                month={month}
                year={year}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="sectionsvg flex items-center justify-center text-center">
          <img
            src={"images/no-schedule-for-month.svg"}
            alt="No schedule for month"
          />
          {/* <p className="font-bold mt-5">
            {date?.month + " " + date?.year}{" "}
            <span className="font-semibold text-trunks">
              schedule pending for approval
            </span>
          </p> */}
        </div>
      )}
    </>
  );
}
