import React, { useState } from "react";
import {
  Chip,
  Input,
  Label,
  Modal,
  Radio,
  Select,
  Dropdown,
  MenuItem,
  PrevButton,
} from "@heathmont/moon-core-tw";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // CSS for ReactDatePicker
import Button from "@heathmont/moon-core-tw/lib/es/button/Button";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";
interface ProfileData {
  patientId: string;
  status: string | undefined;
  critical: boolean;
  patientName: string;
  admissionDate: Date | null;
  dischargeDate: Date | null;
  sectionId: string;
  allocatedSection: string;
  assignedRoom: string;
  referredDoctor: string;
  needyProfile: string | undefined;
  _id?: string;
}
interface Props {
  isOpen: boolean;
  closeModal: () => void;
  profileData: ProfileData;
  setProfileData: Function;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  sections: any[]; // Adjust the type according to your data structure for sections
  edit: boolean;
}

const PatientEditCreateModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  profileData,
  setProfileData,
  handleSubmit,
  sections,
  edit,
}) => {
  const [touched, setTouched] = useState<{
    [K in keyof ProfileData]?: boolean;
  }>({});
  const [errors, setErrors] = useState<{ [K in keyof ProfileData]?: string }>(
    {}
  );

  const handleBlur = (field: keyof ProfileData) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const handleFocus = (field: keyof ProfileData) => {
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  const validateField = (field: keyof ProfileData) => {
    let error: any = null;
    if (!profileData[field]) {
      error = "This field is required";
    }
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const isFormValid = () => {
    console.log("profil", profileData);
    return (
      profileData.patientId !== "" &&
      profileData.status !== "" &&
      profileData.patientName !== "" &&
      profileData.assignedRoom !== "" &&
      profileData.referredDoctor !== "" &&
      profileData.needyProfile !== "" &&
      profileData.dischargeDate !== null &&
      profileData.sectionId !== "" &&
      profileData.admissionDate !== null
    );
  };
  console.log("profile data", profileData);
  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[50rem]">
        <div className="">
          <div className="flex text-center flex-1 flex-col justify-center ">
            <div className="mx-auto w-full pt-4">
              <div className="flex justify-end px-2">
                <Button className="bg-transparent" onClick={closeModal}>
                  <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
                </Button>
              </div>
              <h3 className="text-3xl mb-5">
                {edit ? "Update Patient" : "Add Patient"}
              </h3>
              <p className="text-base text-[#8697A2] mb-10 max-w-[546px] mx-auto">
                Fill the following fields to{" "}
                {edit ? "update patient" : "add patient"}
              </p>

              <div className="mx-auto  pb-9 px-10 bg-white rounded-xl shadow-3xl w-full">
                <div className="flex flex-wrap -mx-1">
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="patientId" className="text-start">
                      Patient ID
                    </Label>
                    <Input
                      type="text"
                      id="patientId"
                      name="patientId"
                      value={profileData.patientId}
                      onChange={(e) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          patientId: e.target.value,
                        }))
                      }
                      placeholder="Patient ID"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        touched.patientId && errors.patientId
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("patientId")}
                      onFocus={() => handleFocus("patientId")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="status" className="text-start">
                      Status
                    </Label>
                    <Dropdown
                      value={profileData.status}
                      onChange={(value) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          status: value as string,
                        }))
                      }
                      onBlur={() => handleBlur("status")}
                      onFocus={() => handleFocus("status")}
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open} className="mt-1">
                            {profileData.status}
                          </Dropdown.Select>
                          <Dropdown.Options>
                            <Dropdown.Option value="Active">
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  Active
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                            <Dropdown.Option value="Inactive">
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  Inactive
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="critical" className="text-start">
                      Critical
                    </Label>
                    <Dropdown
                      value={profileData.critical}
                      onChange={(value) =>
                        setProfileData((prev: ProfileData) => {
                          return { ...prev, critical: value as boolean };
                        })
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open} className="mt-1">
                            {profileData.critical ? "True" : "False"}
                          </Dropdown.Select>
                          <Dropdown.Options>
                            <Dropdown.Option value={true}>
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  True
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                            <Dropdown.Option value={false}>
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  False
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="patientName" className="text-start">
                      Patient Name
                    </Label>
                    <Input
                      type="text"
                      id="patientName"
                      name="patientName"
                      value={profileData.patientName}
                      onChange={(e) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          patientName: e.target.value,
                        }))
                      }
                      placeholder="Patient Name"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        touched.patientName && errors.patientName
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("patientName")}
                      onFocus={() => handleFocus("patientName")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="admissionDate" className="text-start">
                      Admission Date
                    </Label>
                    <div>
                      <ReactDatePicker
                        className="mt-1 block w-full z-50 relative px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        selected={profileData.admissionDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date: Date | null) =>
                          setProfileData((prevProfileData: ProfileData) => ({
                            ...prevProfileData,
                            admissionDate: date,
                          }))
                        }
                        placeholderText="Select Date"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="dischargeDate" className="text-start">
                      Discharge Date
                    </Label>
                    <div className="">
                      <ReactDatePicker
                        className="mt-1 block w-full z-50 relative px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        dateFormat="dd/MM/yyyy"
                        selected={profileData.dischargeDate}
                        onChange={(date: Date | null) =>
                          setProfileData((prevProfileData: ProfileData) => ({
                            ...prevProfileData,
                            dischargeDate: date,
                          }))
                        }
                        placeholderText="Select Date"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="allocatedSection" className="text-start">
                      Allocated Section
                    </Label>
                    <Dropdown
                      value={sections}
                      onChange={(value: any) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          sectionId: value?._id as string,
                          allocatedSection: value?.sectionName as string,
                        }))
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open} className="mt-1">
                            {profileData.allocatedSection
                              ? profileData.allocatedSection
                              : "Select Section"}
                          </Dropdown.Select>
                          <Dropdown.Options>
                            {sections?.map((section: any) => (
                              <Dropdown.Option
                                key={section._id}
                                value={section}
                              >
                                {({ selected, active }) => (
                                  <MenuItem
                                    isActive={active}
                                    isSelected={selected}
                                  >
                                    {section.sectionName}
                                  </MenuItem>
                                )}
                              </Dropdown.Option>
                            ))}
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="assignedRoom" className="text-start">
                      Assigned Room
                    </Label>
                    <Input
                      type="text"
                      id="assignedRoom"
                      name="assignedRoom"
                      value={profileData.assignedRoom}
                      onChange={(e) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          assignedRoom: e.target.value,
                        }))
                      }
                      placeholder="Assigned Room"
                      className={`mt-1  relative block w-full px-3 py-2 border ${
                        touched.assignedRoom && errors.assignedRoom
                          ? "border-red-500"
                          : "border-gray-300"
                      } z-0 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("assignedRoom")}
                      onFocus={() => handleFocus("assignedRoom")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="referredDoctor" className="text-start">
                      Referred Doctor
                    </Label>
                    <Input
                      type="text"
                      id="referredDoctor"
                      name="referredDoctor"
                      value={profileData.referredDoctor}
                      onChange={(e) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          referredDoctor: e.target.value,
                        }))
                      }
                      placeholder="Referred Doctor"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        touched.referredDoctor && errors.referredDoctor
                          ? "border-red-500"
                          : "border-gray-300"
                      } z-0 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      onBlur={() => handleBlur("referredDoctor")}
                      onFocus={() => handleFocus("referredDoctor")}
                    />
                  </div>
                  <div className="w-1/2 px-1 mb-3">
                    <Label htmlFor="needyProfile" className="text-start">
                      Needy Profile
                    </Label>
                    <Dropdown
                      value={profileData.needyProfile}
                      onChange={(value) =>
                        setProfileData((prevProfileData: ProfileData) => ({
                          ...prevProfileData,
                          needyProfile: value as string,
                        }))
                      }
                    >
                      {({ open }) => (
                        <>
                          <Dropdown.Select open={open} className="mt-1">
                            {profileData.needyProfile}
                          </Dropdown.Select>
                          <Dropdown.Options>
                            <Dropdown.Option value="High">
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  High
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                            <Dropdown.Option value="Medium">
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  Medium
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                            <Dropdown.Option value="Low">
                              {({ selected, active }) => (
                                <MenuItem
                                  isActive={active}
                                  isSelected={selected}
                                >
                                  Low
                                </MenuItem>
                              )}
                            </Dropdown.Option>
                          </Dropdown.Options>
                        </>
                      )}
                    </Dropdown>
                  </div>
                </div>

                {/* <div className="w-full px-1 mb-3">
                  <Button
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={isFormValid() ? false : true}
                    className="w-full h-10 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    {edit ? "Update" : "Create"}
                  </Button>
                </div> */}
                <div className="modal-footer py-2  flex items-center justify-end gap-[8px]">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={isFormValid() ? false : true}
                    className=" text-white rounded-md shadow-md focus:outline-none focus:ring-2  focus:ring-opacity-50"
                  >
                    {edit ? "Update" : "Create"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default PatientEditCreateModal;
