import React, { useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { ControlsChevronDown } from "@heathmont/moon-icons";
import { VIEWS } from "../../../enums/branch-admin/views.enums";

interface Option {
  name: string;
}

const options: Option[] = [
  { name: VIEWS.EMPLOYEES },
  { name: VIEWS.PATIENTS },
  { name: VIEWS.REQUEST_TIME_OFF },
  { name: VIEWS.SWAP_REQUEST },
  { name: VIEWS.LEAVES },
  { name: VIEWS.SECTIONS },
];
interface Props {
  viewOption: string | null;
  setViewOption: (viewType: string | null) => void;
}

const SectionMain: React.FC<Props> = ({ viewOption, setViewOption }) => {
  // const [option, setOption] = useState<string | null>(null);

  const handleOptionChange = (selectedItem: Option | null) => {
    if (selectedItem) {
      // setOption(selectedItem.name);
      setViewOption(selectedItem.name);
    } else {
      // setOption(null);
      setViewOption(null);
    }
  };

  return (
    <div className="relative  max-w-[300px]">
      {viewOption === "Preferences & Rules" ? (
        <h3 className="text-moon-32 font-semibold text-white">{viewOption}</h3>
      ) : (
        <Dropdown value={viewOption} onChange={handleOptionChange}>
          <Dropdown.Trigger className="max-w-[300px]">
            <div className="max-w-[300px] rounded-lg gap-[15px] font-normal text-moon-18 text-white justify-start flex items-center  cursor-pointer">
              <h3 className="text-moon-32 font-semibold text-white">
                {viewOption ? viewOption : "Select Views"}
              </h3>
              <ControlsChevronDown className="text-white text-moon-32" />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Options className="dropMenu min-w-[215px] max-w-[215px]">
            {/* <h4 className="text-moon-14 text-grey mb-[5px] pt-2 px-2">
              Section
            </h4> */}
            {options.map((menuItem, index) => (
              <Dropdown.Option value={menuItem} key={index}>
                {({ selected, active }) => (
                  <MenuItem
                    isActive={active}
                    isSelected={selected}
                    onClick={() => handleOptionChange(menuItem)}
                    className={`${selected || active
                        ? "bg-goku text-cyan-500"
                        : "text-gray-900"
                      } text-moon-14 group flex w-full items-center rounded-md px-2 py-2 focus:bg-goku focus:text-hit hover:bg-goku hover:text-hit`}
                  >
                    {menuItem.name}
                  </MenuItem>
                )}
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </Dropdown>
      )}
    </div>
  );
};

export default SectionMain;
