import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import React, { useState } from "react";
interface Props {
  options: any;
  label: string;
}
const DataSelect: React.FC<Props> = ({ options, label }) => {
  const [option, setOption] = useState<{ name?: string } | null>(null);
  return (
    <Dropdown value={option} onChange={setOption}>
      {({ open }) => (
        <>
          <Dropdown.Select open={open} label={label}>
            {option?.name}
          </Dropdown.Select>
          <Dropdown.Options>
            {options.map((opt: any) => (
              <Dropdown.Option value={opt} key={opt}>
                {({ selected, active }) => (
                  <MenuItem isActive={active} isSelected={selected}>
                    {opt.name}
                  </MenuItem>
                )}
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </>
      )}
    </Dropdown>
  );
};

export default DataSelect;
