import React, { useEffect, useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { ControlsChevronDown } from "@heathmont/moon-icons-tw";

type Month = {
  name: string;
};
interface OptionItem {
  name: string; // Replace with actual type
}
interface Props {
  id: number;
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersArray: IFilterParameters[]) => void;
  setIsMonthChanged: (month: string) => void;
}
const month = [
  { name: "January" },
  { name: "Feburary" },
  { name: "March" },
  { name: "April" },
  { name: "May" },
  { name: "June" },
  { name: "July" },
  { name: "August" },
  { name: "September" },
  { name: "October" },
  { name: "November" },
  { name: "December" },
];

const FilterMonth: React.FC<Props> = ({
  id,
  filtersArray,
  setFiltersArray,
  setIsMonthChanged,
}) => {
  const [option, setOption] = useState<Month>();

  const handleOptionChange = (index: number, selectedItem: OptionItem) => {
    const tempArray = [...filtersArray];
    if (tempArray[id]) {
      setIsMonthChanged(selectedItem?.name);
      tempArray[id].value = index < 9 ? `0${index + 1}/` : `${index + 1}/`;
      setFiltersArray(tempArray);
    }
  };
  useEffect(() => {
    setOption(undefined);
    // const tempArray = [...filtersArray];
    // tempArray[id].value = undefined;
    // setFiltersArray(tempArray);

    const tempVal = filtersArray[id]?.value as string;
    if (
      filtersArray[id]?.value &&
      filtersArray[id]?.filter === "By Month And Year"
    ) {
      setOption(month[Number(tempVal.split("/")[0]) - 1]);
    }
  }, [filtersArray[id]?.value]);
  return (
    <Dropdown value={option} onChange={setOption}>
      <Dropdown.Trigger className=" w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]">
        {option?.name || "Select Month..."}
        <i>
          <ControlsChevronDown className="text-moon-18" />
        </i>
      </Dropdown.Trigger>

      <Dropdown.Options className="h-[200px] overflow-y-auto">
        {month.map((month, index) => (
          <Dropdown.Option value={month} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                onClick={() => handleOptionChange(index, month)}
              >
                {month.name}
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default FilterMonth;
