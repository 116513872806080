import React, { useState } from "react";
import { Dropdown, MenuItem } from "@heathmont/moon-core-tw";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { FILTER_SELECT } from "../../../enums/branch-admin/filters.enums";
import { ControlsChevronDown } from "@heathmont/moon-icons";
interface OptionItem {
  name: string; // Replace with actual type
}

interface Props {
  id: number;
  type: string;
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersArray: IFilterParameters[]) => void;
}
const ValueDropDown: React.FC<Props> = ({
  id,
  type,
  filtersArray,
  setFiltersArray,
}) => {
  const initialOption = filtersArray[id]?.value
    ? (filtersArray[id]?.value as string)
    : "Select an option";
  const [option, setOption] = useState<string>(initialOption);

  const valueOptions =
    type === "seniority_level"
      ? FILTER_SELECT?.EMP_SENIORITY_LEVEL
      : type === "needy_profile"
      ? FILTER_SELECT?.PAT_NEEDY_PROFILE
      : type === "RTO_status" || type === "leave_status"
      ? FILTER_SELECT?.EMP_RTO_LEAVE_STATUS
      : type === "leave_type"
      ? FILTER_SELECT?.EMP_LEAVE_TYPE
      : [];
  const handleOptionChange = (selectedItem: OptionItem) => {
    const tempArray = [...filtersArray];
    setOption(selectedItem?.name);
    if (tempArray[id]) {
      tempArray[id].value = selectedItem?.name;
      setFiltersArray(tempArray);
    }
  };
  return (
    <Dropdown
      value={option}
      onChange={(item: OptionItem) => setOption(item?.name)}
    >
      <Dropdown.Trigger className=" w-full border-beerus border px-3 py-2 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]">
        {option}
        <i>
          <ControlsChevronDown className="text-moon-18" />
        </i>
      </Dropdown.Trigger>

      <Dropdown.Options className="h-[150px] overflow-y-auto">
        {valueOptions?.map((val, index) => (
          <Dropdown.Option value={val} key={index}>
            {({ selected, active }) => (
              <MenuItem
                isActive={active}
                isSelected={selected}
                onClick={() => handleOptionChange(val)}
              >
                {val?.name}
              </MenuItem>
            )}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  );
};

export default ValueDropDown;
