import { api } from "../../utils/axios";

// {{healthcareLocal}}/nurseDirector/getSchedulesNurseDirector

interface INurseDirector {
  Page: number;
  sectionId?: string;
}
export const getSchedulesNurseDirector = async (params: INurseDirector) => {
  const { Page, sectionId } = params;
  try {
    const { data, ok } = await api.get(
      `/nurseDirector/getSchedulesNurseDirector?&Page=${Page}/${
        sectionId !== null ? "&sectionId=" + sectionId : ""
      }`,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

// {{healthcareLocal}}/nurseDirector//respondScheduleRequest/6538bcc4038454491fae8a2a/accepted

interface IRequest {
  sectionId: string;
  status: string;
}
export const respondScheduleRequest = async (params: IRequest) => {
  try {
    const { sectionId, status } = params;
    const { data, ok } = await api.patch(
      `/nurseDirector/respondScheduleRequest/${sectionId}/${status}`,
    );
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
