import { useMemo, useState } from "react";
import HeaderSetting from "../../../../Components/super-admin/ui/HeaderSetting";
import SearchField from "../../../../Components/super-admin/ui/SearchField";
import UserListing from "./widgets/UserListing";
import ModalAddHospital from "../hospital/widgets/ModalAddHospital";
import AddBranch from "../hospital/widgets/AddBranch";
import { Input } from "@heathmont/moon-core-tw";
const UserSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const closeModal = () => setIsOpen(false);
  const handleModal = () => {
    setIsOpen(true);
  };
  const closeModalBranch = () => setIsOpenModal(false);

  const roles = [
    {
      role: "branch admin",
      order: 1,
      permissions: [
        "Manage sections of his/her branch",
        "View employee data",
        "View patient data",
      ],
    },
    {
      role: "nurse director",
      order: 1,
      permissions: [
        "View schedule of different sections of his/her branch",
        "Approve or Decline schedule of different sections of his/her branch",
      ],
    },
    {
      role: "nurse manager",
      order: 2,
      permissions: [
        "Create schedule of his/her section",
        "View RO leaves of his/her section",
        "Edit schedule",
      ],
    },
    {
      role: "HCA",
      order: 3,
      permissions: ["Read schedule", "Apply time off"],
    },
    {
      role: "senior nurse",
      order: 3,
      permissions: ["Read schedule", "Apply time off"],
    },
    {
      role: "nurse",
      order: 3,
      permissions: ["Read schedule", "Apply time off"],
    },
  ];
  const filteredRoles = useMemo(() => {
    // Use the searchTerm to filter employees
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return roles?.filter((employee: any) =>
      employee?.role?.toLowerCase().includes(lowerCaseSearchTerm),
    );
  }, [roles, searchTerm]);
  return (
    <>
      <div className="bg-slate-100 min-h-full relative z-[0] w-4/5">
        <HeaderSetting
          title="Manage Users"
          leadText="Manage user roles and their access permissions respectively"
          buttonText="Add User"
          handleModal={handleModal}
        />
        <div className="px-8 pb-4">
          {/* <SearchField />
           */}
          <Input
            className="text-moon-14 text-trunks"
            type="text"
            placeholder="Search by user role"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e?.target?.value)}
          />{" "}
        </div>
        <div className="px-8">
          <UserListing roles={filteredRoles} />
        </div>
      </div>
      <ModalAddHospital isOpen={isOpen} closeModal={closeModal} />
      <AddBranch
        isOpenModal={isOpenModal}
        closeModalBranch={closeModalBranch}
      />
    </>
  );
};

export default UserSettings;
