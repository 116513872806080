import { api } from "../../utils/axios";
export const postPatient = async (patient: any) => {
  try {
    const { data, ok } = await api.post(`/patient/addNewPatient`, patient);
    if (ok) {
      return data;
    }
    return {err:data}
  } catch (error) {
    return { err: error };
  }
};
export const updatePatient = async (patient: any) => {
  try {
    const { data, ok } = await api.put(`/patient/updatePatientInfo/${patient?._id}`, patient);
    if (ok) {
      return data;
    }
    return {err:data}
  } catch (error) {
    return { err: error };
  }
};