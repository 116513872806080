import React, { useState } from "react";

import { Checkbox, IconButton, Button } from "@heathmont/moon-core-tw";

import {
  ControlsPlus,
  GenericAlarmRound,
  GenericBlock,
  GenericDelete,
  GenericEdit,
} from "@heathmont/moon-icons";
import { Link } from "react-router-dom";

import ConfirmationModal from "./ConfirmationModal";
import { IHospital } from "../../../../../types/super-admin-interfaces/IHospital.interface";

type Column = {
  Header: () => JSX.Element;

  accessor: keyof DataRow; // Using DataRow type here
};

type DataRow = {
  check: JSX.Element;

  role: string;

  branch: JSX.Element;

  action: JSX.Element;

  // ... other properties
};

const HospitalListing = ({
  openBranch,

  handleClick,

  hospitalData,

  getHospitalId,
  handleModal,
  deActivateHospitalData,
  activateHospitalData,

  getHospitalID,
}: any) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const closeConfirmModal = () => setIsOpenConfirm(false);
  const [hospitalDeactivate, setHospitalDeactivate] = useState<IHospital>();
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const openConfirmationModal = (data: any) => {
    setConfirmationData(data);
    setIsOpenConfirm(true);
  };

  const columnsInitial: Column[] = [
    {
      Header: () => <div className="font-bold">Hospital Name</div>,

      accessor: "role",
    },

    {
      Header: () => <div className="font-bold">Branches</div>,

      accessor: "branch",
    },

    {
      Header: () => <div></div>,

      accessor: "action",
    },

    // ... other columns
  ];

  const data: DataRow[] = hospitalData
    ? hospitalData?.hospitals.map((hospital: IHospital) => ({
        role: hospital.name,
        status: hospital.isActive,
        branch: (
          <div>
            <div className="flex items-center gap-3">
              <span>{hospital.isActive}</span>
              {hospital?.numberOfBranches === 0 && hospital?.isActive ? (
                <Link
                  to="#"
                  className="text-primary inline-flex gap-[5px] items-center"
                  onClick={() => {
                    openBranch();
                    getHospitalId(hospital);
                  }}
                >
                  <ControlsPlus /> Add Branch
                </Link>
              ) : hospital?.isActive ? (
                <>
                  <span className="text-gray-400">
                    {" "}
                    {hospital?.numberOfBranches}
                  </span>

                  <Link
                    to="#"
                    onClick={() => {
                      handleClick();
                      getHospitalId(hospital);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M8.625 12C8.625 12.2071 8.45711 12.375 8.25 12.375C8.04289 12.375 7.875 12.2071 7.875 12C7.875 11.7929 8.04289 11.625 8.25 11.625C8.45711 11.625 8.625 11.7929 8.625 12ZM8.625 12H8.25M12.375 12C12.375 12.2071 12.2071 12.375 12 12.375C11.7929 12.375 11.625 12.2071 11.625 12C11.625 11.7929 11.7929 11.625 12 11.625C12.2071 11.625 12.375 11.7929 12.375 12ZM12.375 12H12M16.125 12C16.125 12.2071 15.9571 12.375 15.75 12.375C15.5429 12.375 15.375 12.2071 15.375 12C15.375 11.7929 15.5429 11.625 15.75 11.625C15.9571 11.625 16.125 11.7929 16.125 12ZM16.125 12H15.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                        stroke="#272D36"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </>
              ) : (
                <>
                  <GenericAlarmRound />
                  <span>Activate to Manage Branch</span>
                </>
              )}
            </div>
          </div>
        ),

        action: (
          <div className="flex gap-1 justify-end">
            {hospital?.isActive ? (
              <>
                <IconButton
                  variant="ghost"
                  icon={<GenericEdit />}
                  onClick={() => {
                    handleModal(hospital);

                    getHospitalID(hospital._id);
                  }}
                />

                <IconButton
                  variant="ghost"
                  icon={<GenericBlock />}
                  onClick={() => {
                    openConfirmationModal({
                      title: "Confirm Hospital De-Activate",
                      actionFor: "Hospital",
                      message: (
                        <p className="text-moon-18 mb-[28px]">
                          {" "}
                          The Hospital{" "}
                          <strong className="font-semibold ">
                            {hospital?.name}
                          </strong>
                          <br /> and all its related services will be
                          de-activated
                        </p>
                      ),
                      confirmAction: "De-Activate",
                      id: {
                        hospitalId: hospital?._id,
                      },
                    });

                    // setIsOpenConfirm(true);
                    // setHospitalDeactivate(hospital);
                  }}
                />
              </>
            ) : (
              <Button
                variant="primary"
                className="bg-hit"
                onClick={() => {
                  activateHospitalData(hospital?._id);
                }}
              >
                Activate
              </Button>
            )}
          </div>
        ),
      }))
    : [];
  console.log("hosp data", data[0]);
  return (
    <div className="tableResponsive table-hospital bg-[#F5F5F5] p-[8px] rounded-[8px]">
      <table className="w-full">
        <thead>
          <tr>
            {columnsInitial.map((column, columnIndex) => (
              <th
                className="text-left text-moon-16 text-[#000] font-normal"
                key={columnIndex}
              >
                {column.Header()}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((rowData, rowIndex) => {
            console.log("Row Data", rowData);
            return (
              <React.Fragment key={rowIndex}>
                <tr>
                  {columnsInitial.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`${
                        rowData?.branch?.props?.children?.props?.children[0]
                          ?.props?.children
                          ? ""
                          : "bg-[#EDEDED!important]"
                      }
                  `}
                    >
                      {(rowData as any)[column.accessor]}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={closeConfirmModal}
        confirmationData={confirmationData}
        onConfirm={() =>
          deActivateHospitalData(confirmationData?.id?.hospitalId)
        }
        // deActivateHospitalData={deActivateHospitalData}
        // hospitalData={hospitalDeactivate as IHospital}
      />
    </div>
  );
};

export default HospitalListing;
