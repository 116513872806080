import React from "react";
import DropDown from "../UI/DropDown";
import SearchBox from "../UI/Search";
import CustomTabs from "../UI/CustomTabs";
import { useDispatch } from "react-redux";
import { setReqState } from "../../../features/nurse-manager/Request Slice/requestSlice";
import { Input } from "@heathmont/moon-core-tw";
import { GenericSearch } from "@heathmont/moon-icons-tw";

interface Tab {
  id: number;
  label: string;
}

interface BranchSection {
  name: string;
}
interface Props {
  setSearchQuery: (data: string) => void;
}

const FilterRequests: React.FC<Props> = ({ setSearchQuery }) => {
  const dispatch = useDispatch();
  const dataTabs: Tab[] = [
    { id: 1, label: "All" },
    { id: 2, label: "Accepted" },
    { id: 3, label: "Pending" },
    { id: 4, label: "Rejected" },
  ];
  const handleButtonClick = (button: string) => {
    dispatch(setReqState(button));
  };

  const buttons: string[] = dataTabs.map((item) => item.label);

  const branch: BranchSection[] = [{ name: "Branch" }];
  const section: BranchSection[] = [{ name: "Branch" }];
  return (
    <div className="flex flex-wrap mx-[12px]">
      {/* <div className="px-[12px]"> */}
      <div className="flex flex-wrap -mx-[6px] w-full">
        <div className="relative  px-[6px]">
          <Input
            type="text"
            className="text-moon-14 text-trunks min-h-[42px] ps-8 -z-0"
            placeholder="Search Employee"
            // value={searchTerm}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <div className="emloyeserch">
            <GenericSearch className="w-6 h-6" />
          </div>
          {/* <DropDown option="Branch" data={branch} /> */}
        </div>
        {/* <div className="w-1/4 px-[6px]">
            <DropDown option="Section" data={section} />
          </div> */}
        {/* <div className="w-2/4 px-[6px]">
            <SearchBox />
            <Input
              type="text"
              className="text-moon-14 text-trunks min-h-[42px] -z-0"
              placeholder="Search by employee name"
              // value={searchTerm}
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
          </div> */}
      </div>
      {/* </div> */}
      {/* <div className="w-1/2 px-[12px]">
        <CustomTabs buttons={buttons} onButtonClick={handleButtonClick} />
      </div> */}
    </div>
  );
};

export default FilterRequests;
