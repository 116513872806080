import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import ScheduleDrawer from "../../Drawers/view-schedule/ScheduleDrawer";
import { RootState } from "../../../../store/store";
import { getScheduleByDay } from "../../../../services/nurse-manager-services/nursemanager.service";
import { useDispatch, useSelector } from "react-redux";
import { setEmployees } from "../../../../features/nurse-manager/Week Filter/weekSlice";
import {
  IEmployee,
  IEmployeeRecord,
} from "../../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";
interface Props {
  scheduleId: string;
  weekDates?: moment.Moment[];
}

const WeekDate: React.FC<Props> = ({ scheduleId, weekDates }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<IEmployeeRecord>();
  const [allEmployees, setAllEmployees] = useState<IEmployee[]>();
  const [hca, setHca] = useState<IEmployee[]>();
  const [nurse, setNurse] = useState<IEmployee[]>();
  const [seniorNurse, setSeniorNurse] = useState<IEmployee[]>();
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const [dateCopy, setDateCopy] = useState<Moment>();
  const dispatch = useDispatch();
  const { selectedFilter } = useSelector(
    (state: RootState) => state.weekFilter
  );

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const fetchScheduleByDay = async (params: Moment) => {
    setDateCopy(params);
    const data = {
      day: params.format("DD"),
      month: params.format("MM"),
      year: params.format("YYYY"),
      scheduleId: scheduleId,
      dayShift: selectedShift === "sun" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
    };
    const res: any = await getScheduleByDay(data);
    console.log("sch by day: ", res?.res?.specificDayRecord.employees);
    setData(res?.res);
    setAllEmployees(res?.res?.specificDayRecord.employees);
    dispatch(setEmployees(res?.res?.specificDayRecord.employees));
    // setFilterEmployees(res?.res?.specificDayRecord.employees);
    setNurse([] as IEmployee[]);
    setHca([] as IEmployee[]);
    setSeniorNurse([] as IEmployee[]);
  };

  const fetchScheduleByDayForSeniorNurse = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      Senior_Nurse: true,
    };
    if (seniorNurse?.length === 0) {
      const res: any = await getScheduleByDay(date);
      // setFilterEmployees(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
      setSeniorNurse(res?.res?.specificDayRecord.employees);
    } else {
      dispatch(setEmployees(seniorNurse));
      // setFilterEmployees(seniorNurse);
    }
  };

  const fetchScheduleByDayForHCA = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      HCA: true,
    };

    if (hca?.length === 0) {
      const res: any = await getScheduleByDay(date);
      setHca(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
    } else {
      dispatch(setEmployees(hca));
    }
    // const res = await getScheduleByDay()
  };

  const fetchScheduleByDayForNurse = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      Nurse: true,
    };
    if (nurse?.length === 0) {
      const res: any = await getScheduleByDay(date);
      console.log("RES", res);
      setNurse(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
      // setFilterEmployees(res?.res?.specificDayRecord.employees);
    } else {
      // setFilterEmployees(nurse);
      dispatch(setEmployees(nurse));
    }
  };
  const allEmployeesFilter = () => {
    // const { Employees } = useSelector((state: RootState) => state.weekFilter);
    dispatch(setEmployees(allEmployees));
  };

  const currentDate = moment();

  useEffect(() => {
    if (selectedFilter === "Nurse") {
      fetchScheduleByDayForNurse();
    } else if (selectedFilter === "HCA") {
      fetchScheduleByDayForHCA();
    } else if (selectedFilter === "Senior Nurse") {
      fetchScheduleByDayForSeniorNurse();
    } else {
      allEmployeesFilter();
    }
  }, [selectedFilter]);

  const User = useSelector((state: RootState) => state.auth.User?.designation);
  const openDrawer = (date: Moment) => {
    if (selectedShift === "all") return;
    if (User === "nurse manager") {
      handleClick();
      fetchScheduleByDay(date);
    }
  };
  return (
    <div
      className={`flex flex-wrap w-full ${
        selectedShift === "all" ? "cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <>
        {weekDates?.map((date, index) => {
          const isCurrentDate = date.date() === currentDate.date();
          const isCurrentMonth = date.month() === currentDate.month();
          return (
            <div
              key={index}
              onClick={() => {
                openDrawer(date);
                // handleClick();
                // fetchScheduleByDay(date);
              }}
              className={`basis-1/7 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex items-center ${
                isCurrentDate && isCurrentMonth ? "bg-black" : "" // Apply a different background color for the current date
              } ${!isCurrentMonth ? "text-gray-500" : ""}`} // Apply grey color for dates not in the current month
            >
              <div
                className={`text-moon-24 font-semibold transition-colors w-full `}
                style={{
                  color: isCurrentMonth ? "inherit" : "#999CA0", // Apply grey color to dates not in the current month
                }}
              >
                {isCurrentDate && isCurrentMonth ? (
                  <>
                    <div className="text-white text-moon-12">
                      {date?.format("DD")}
                    </div>
                    <div className="text-white">TODAY</div>
                  </>
                ) : (
                  <span>{date?.format("DD")}</span>
                )}
              </div>
            </div>
          );
        })}
        <ScheduleDrawer
          data={data as IEmployeeRecord}
          isOpen={isOpen}
          setIsOpen={setIsOpen as any}
          handleClose={handleClose}
        />
      </>
    </div>
  );
};

export default WeekDate;
