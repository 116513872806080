import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Preferences from "./preferences";
import ScheduleHeader from "../../Components/branch-admin/layout/ScheduleHeader";
import AdminViews from "./admin-views";
import Settings from "./settings";
import Profile from "../profile";
import useIdleTime from "../../hooks/branch-admin/useIdleTime";

const Branch_Admin_Routes = () => {

  useIdleTime()

  return (
    <>
      <Router>
        <header className="relative">
          <ScheduleHeader />
        </header>
        <Routes>
          {/* <Route path="/" element={<AdminViews />} /> */}
          <Route path="/home" element={<AdminViews />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/preferences" element={<Preferences />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
    </>
  );
};

export default Branch_Admin_Routes;
